<script lang="ts">
    import { onMount } from "svelte"
    import Context from "../../lib/Context.svelte"
    import Loader from "../../lib/Loader.svelte"
    import { dateTimeFormat } from "../../lib/Utils"
    import { SearchQuery, SearchRequest } from "../../lib/golang-models/cdr"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import Search from "./calls-extract/Search.svelte"
    import type { ISearch } from "./calls-extract/interfaces"

    var searches: ISearch[] = [],
        config = {
            seq: 1,
            selectedId: "",
        },
        deps

    function newSearch() {
        let search: ISearch = {
            id: config.seq,
            querySeq: 1,
            dateMode: "today",
            selectedQueryId: "0",
            request: new SearchRequest({
                Queries: [],
                SearchName: "Search " + config.seq,
                StartDate: dateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 1)),
                EndDate: dateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)),
            }),
            update() {
                searches = [...searches]
            },
            close: () => {
                searches = [...searches.filter((it) => it != search)]
                if (searches.length == 0) newSearch()
            },
            addQuery() {
                let q = new SearchQuery({ Name: "Query " + search.querySeq })
                q["id"] = search.querySeq.toString()
                search.querySeq++
                search.request.Queries = [...search.request.Queries, q]
                search.selectedQueryId = q["id"]
            },
            removeQuery(id: string) {
                if (search.request.Queries.length <= 1) return
                search.request.Queries = search.request.Queries.filter((it) => it["id"] != id)
            },
        }
        search.addQuery()
        config = {
            ...config,
            seq: config.seq + 1,
            selectedId: config.seq.toString(),
        }
        searches = [...searches, search]
    }

    onMount(function () {
        newSearch()
    })
</script>

<Page>
    <Loader endpoint={["phone-number-format/list", "carrier/custom/list"]} let:data={deps}>
        <Context name="phone-formats" value={deps[0]}>
            <Context name="custom-carriers" value={deps[1]}>
                <column class="expanded">
                    <Tabs
                        bind:selected={config.selectedId}
                        add={() => {
                            newSearch()
                        }}
                        close={(id) => {
                            searches = [...searches.filter((it) => it.id.toString() != id)]
                            if (searches.length == 0) newSearch()
                        }}
                    >
                        <svelte:fragment slot="tabs">
                            {#each searches as search (search.id)}
                                <a href="#{search.id}">{search.request.SearchName}</a>
                            {/each}
                        </svelte:fragment>
                        {#each searches as search (search.id)}
                            <TabContent name={search.id.toString()} mode="lazy-keep">
                                <Search {search} />
                            </TabContent>
                        {/each}
                    </Tabs>
                </column>
            </Context>
        </Context>
    </Loader>
</Page>
