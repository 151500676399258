<script lang="ts">
    import { onMount, setContext } from "svelte"

    var translation = null
    function translate(key: string, vars: Map<string, any> = null) {
        if (!key) {
            console.error("no key provided to $t()")
            return ""
        }
        let text = translation[key]
        if (!text) {
            console.error(`no translation found for ${key}`)
            return ""
        }
        if (vars) {
            Object.keys(vars).map((k) => {
                const regex = new RegExp(`{{${k}}}`, "g")
                text = text.replace(regex, vars[k])
            })
        }
        return text
    }
    setContext("i18n", {
        translate,
        dict: () => translation,
    })

    onMount(function () {
        function rq(isRetry: boolean) {
            const urlParams = new URLSearchParams(window.location.search)
            fetch("translations/" + ((!isRetry && urlParams.get("lang")) || "en") + ".json")
                .then((r) => r.json())
                .then((r) => (translation = r))
                .catch(() => !isRetry && rq(true))
        }
        rq(false)
    })
</script>

{#if translation}
    <slot {translate} />
{/if}
