<script>
    // https://github.com/catamphetamine/country-flag-icons/tree/master/flags/3x2
    let lang = new URLSearchParams(window.location.search).get("lang")
</script>

<row>
    <expander />
    <!-- US -->
    <a href="?lang=en" class:selected={lang == "en" || !lang}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 513 342">
            <rect y="0" fill="#FFFFFF" width="513" height="342" />
            <g fill="#D80027">
                <rect y="0" width="513" height="26.3" />
                <rect y="52.6" width="513" height="26.3" />
                <rect y="105.2" width="513" height="26.3" />
                <rect y="157.8" width="513" height="26.3" />
                <rect y="210.5" width="513" height="26.3" />
                <rect y="263.1" width="513" height="26.3" />
                <rect y="315.7" width="513" height="26.3" />
            </g>
            <rect fill="#2E52B2" width="256.5" height="184.1" />
            <g fill="#FFFFFF">
                <polygon
                    points="47.8,138.9 43.8,126.1 39.4,138.9 26.2,138.9 36.9,146.6 32.9,159.4 43.8,151.5 54.4,159.4
            50.3,146.6 61.2,138.9 	"
                />
                <polygon
                    points="104.1,138.9 100,126.1 95.8,138.9 82.6,138.9 93.3,146.6 89.3,159.4 100,151.5 110.8,159.4
            106.8,146.6 117.5,138.9 	"
                />
                <polygon
                    points="160.6,138.9 156.3,126.1 152.3,138.9 138.8,138.9 149.8,146.6 145.6,159.4 156.3,151.5 167.3,159.4
            163.1,146.6 173.8,138.9 	"
                />
                <polygon
                    points="216.8,138.9 212.8,126.1 208.6,138.9 195.3,138.9 206.1,146.6 202.1,159.4 212.8,151.5 223.6,159.4
            219.3,146.6 230.3,138.9 	"
                />
                <polygon
                    points="100,75.3 95.8,88.1 82.6,88.1 93.3,96 89.3,108.6 100,100.8 110.8,108.6 106.8,96 117.5,88.1
            104.1,88.1 	"
                />
                <polygon
                    points="43.8,75.3 39.4,88.1 26.2,88.1 36.9,96 32.9,108.6 43.8,100.8 54.4,108.6 50.3,96 61.2,88.1
            47.8,88.1 	"
                />
                <polygon
                    points="156.3,75.3 152.3,88.1 138.8,88.1 149.8,96 145.6,108.6 156.3,100.8 167.3,108.6 163.1,96 173.8,88.1
            160.6,88.1 	"
                />
                <polygon
                    points="212.8,75.3 208.6,88.1 195.3,88.1 206.1,96 202.1,108.6 212.8,100.8 223.6,108.6 219.3,96 230.3,88.1
            216.8,88.1 	"
                />
                <polygon
                    points="43.8,24.7 39.4,37.3 26.2,37.3 36.9,45.2 32.9,57.9 43.8,50 54.4,57.9 50.3,45.2 61.2,37.3 47.8,37.3
                "
                />
                <polygon
                    points="100,24.7 95.8,37.3 82.6,37.3 93.3,45.2 89.3,57.9 100,50 110.8,57.9 106.8,45.2 117.5,37.3
            104.1,37.3 	"
                />
                <polygon
                    points="156.3,24.7 152.3,37.3 138.8,37.3 149.8,45.2 145.6,57.9 156.3,50 167.3,57.9 163.1,45.2 173.8,37.3
            160.6,37.3 	"
                />
                <polygon
                    points="212.8,24.7 208.6,37.3 195.3,37.3 206.1,45.2 202.1,57.9 212.8,50 223.6,57.9 219.3,45.2 230.3,37.3
            216.8,37.3 	"
                />
            </g>
        </svg>
    </a>

    <!-- BR -->
    <a href="?lang=pt-BR" class:selected={lang == "pt-BR"}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 513 342">
            <rect y="0" fill="#009b3a" width="513" height="342" />
            <polygon fill="#fedf00" points="256.5,19.3 461.4,170.7 256.5,322 50.6,170.7   " />
            <circle fill="#FFFFFF" cx="256.5" cy="171" r="80.4" />
            <path
                fill="#002776"
                d="M215.9,165.7c-13.9,0-27.4,2.1-40.1,6c0.6,43.9,36.3,79.3,80.3,79.3c27.2,0,51.3-13.6,65.8-34.3
    C297,185.7,258.7,165.7,215.9,165.7z"
            />
            <path
                fill="#002776"
                d="M334.9,186c0.9-5,1.5-10.1,1.5-15.4c0-44.4-36-80.4-80.4-80.4c-33.1,0-61.5,20.1-73.9,48.6
    c10.9-2.2,22.1-3.4,33.6-3.4C262.5,135.5,304.7,154.9,334.9,186z"
            />
        </svg>
    </a>
    <expander />
</row>

<style>
    a {
        width: 24px;
        cursor: pointer;
        opacity: 0.2;
        margin: 5px;
    }
    a:hover {
        opacity: 0.8;
    }
    a.selected {
        opacity: 1;
    }
</style>
