<script>
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import LogFiles from "./debug/LogFiles.svelte"
    import Softswitch from "./debug/Softswitch.svelte"
</script>

<Page>
    <Tabs selected="log-files">
        <svelte:fragment slot="tabs">
            <a href="#log-files">Log Files</a>
            <a href="#softswitch">Softswitch</a>
        </svelte:fragment>

        <TabContent name="log-files">
            <LogFiles />
        </TabContent>
        <TabContent name="softswitch">
            <Softswitch />
        </TabContent>
    </Tabs>
</Page>
