<script lang="ts">
    export var value: string,
        name: string,
        autocomplete: boolean = false,
        required: boolean = false
    var viewPw = false
</script>

<label>
    {name}
    <row>
        {#if viewPw}
            <input bind:value type="text" autocomplete={autocomplete ? "on" : "new-password"} {required} />
        {:else}
            <input bind:value type="password" autocomplete={autocomplete ? "on" : "new-password"} {required} />
        {/if}
        <button type="button" on:click={() => (viewPw = !viewPw)}><i class="fa-solid fa-eye{viewPw ? '' : '-slash'}" /></button>
    </row>
</label>
