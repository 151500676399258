<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { NullInt64, Trunk, type Client, type Provider } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Password from "../../../widgets/inputs/Password.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    export var context: "Client" | "Provider", owner: Client | Provider

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: Trunk = null
</script>

<Loader endpoint="trunk/list/{context.toLowerCase()}/{owner.Id}" let:data let:reload>
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>{context == "Provider" ? "IP/Domain" : "IP"} <TableSorter /></th>
                {#if context == "Provider"}
                    <th>{i18n.translate("dict.port")} <TableSorter /></th>
                {:else}
                    <th>{i18n.translate("dict.username")} <TableSorter /></th>
                {/if}
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each data as record}
                <tr>
                    <td>{record.Id}</td>
                    <td
                        ><a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}>{record.Name}</a
                        ></td
                    >
                    <td>{context == "Provider" || !record.Auth ? record.Ip : ""}</td>
                    {#if context == "Provider"}
                        <td>{record.Port}</td>
                    {:else}
                        <td>{record.Auth ? record.Username : ""}</td>
                    {/if}
                    <td>
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("trunk/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new Trunk()
                    formRecord[context + "Id"] = new NullInt64({ Int64: owner.Id, Valid: true })
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("dict.trunk")}</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={() =>
                modal.post(`trunk/save`, formRecord).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)} {i18n.translate("dict.trunk")}</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}
            <label>
                {i18n.translate("dict.name")}*
                <input autocomplete="off" bind:value={formRecord.Name} required />
            </label>

            {#if context == "Client"}
                <label class="checkbox">
                    <input type="checkbox" autocomplete="off" bind:checked={formRecord.Auth} />
                    {i18n.translate("field.use-auth")}
                </label>
            {/if}

            {#if context == "Provider" || !formRecord.Auth}
                <label>
                    {context == "Provider" ? "IP/Domain" : "IP/Subnet"}*
                    <input type="text" pattern={context == "Provider" ? `(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})|([\\w\\-\\.]+)` : `(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})|(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}/\\d+)`} bind:value={formRecord.Ip} required />
                    {#if context == "Provider"}
                        <small>{i18n.translate("tip.domain-lookup")}</small>
                    {/if}
                </label>
            {/if}

            {#if context == "Provider"}
                <label>
                    {i18n.translate("dict.port")}*
                    <input type="number" min="10" max="65535" bind:value={formRecord.Port} required />
                </label>
            {/if}

            {#if formRecord.Auth}
                <label>
                    {i18n.translate("dict.username")}*
                    <input autocomplete="off" bind:value={formRecord.Username} required />
                </label>
                <Password name={i18n.translate("field.password")} bind:value={formRecord.Password} />
            {/if}

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
