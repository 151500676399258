<script lang="ts" context="module">
    var acByRegion = writable(null)
</script>

<script lang="ts">
    import { onMount } from "svelte"

    import { writable } from "svelte/store"
    import { prettyNumber } from "../../../lib/Utils"
    import Spinner from "../../../widgets/Spinner.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Dialog from "../../../widgets/modals/Dialog.svelte"

    export var onClose: () => void, stat: any, name: string, period: number

    var viewMode: "raw" | "abr-regions" = "raw"
    onMount(function () {
        if (!$acByRegion) {
            fetch("telephony/ac-by-region")
                .then((r) => r.json())
                .then((r) => acByRegion.set(r))
        }
    })
</script>

<Dialog bodyPadding="0px" onClose={() => onClose()}>
    <svelte:fragment slot="title">CC/AC</svelte:fragment>
    {#if $acByRegion}
        {#if stat}
            {@const _stat = Array.isArray(stat.Calls) ? Object.fromEntries(Object.keys(stat).map((k) => [k, Array.isArray(stat[k]) ? stat[k][period] : stat[k]])) : stat}
            <b style="margin: 10px; text-align:center;">{name}</b>
            <select bind:value={viewMode} style="margin: 0px 10px 10px 10px;">
                <option value="raw">All area codes</option>
                <option value="abr-regions">ABR Telecom Regions</option>
            </select>

            {#if viewMode == "raw"}
                <Table margin="0px 10px 10px 10px">
                    <tr slot="head">
                        <th>CC <TableSorter /></th>
                        <th>AC <TableSorter /></th>
                        <th>Amount <TableSorter /></th>
                        <th>Ratio <TableSorter /></th>
                    </tr>
                    {#each Object.keys(_stat.CcAc) as k}
                        {@const a = k.split("_")}
                        <tr>
                            <td>{a[0]}</td>
                            <td>{a[1]}</td>
                            <td data-sort={_stat.CcAc[k]}>{prettyNumber(_stat.CcAc[k])}</td>
                            <td data-sort={_stat.Calls > 0 ? (_stat.CcAc[k] / _stat.Calls) * 100 : 0}>{(_stat.Calls > 0 ? (_stat.CcAc[k] / _stat.Calls) * 100 : 0).toFixed(1)}%</td>
                        </tr>
                    {/each}
                </Table>
            {:else if viewMode == "abr-regions"}
                <Table margin="0px 10px 10px 10px">
                    <tr slot="head">
                        <th>Region <TableSorter /></th>
                        <th>Amount <TableSorter /></th>
                        <th>Ratio <TableSorter /></th>
                    </tr>
                    {#each Object.keys($acByRegion["abr"]) as r}
                        {@const ddds = Object.keys(_stat.CcAc)
                            .filter((it) => it.match(/^(0|55)_/))
                            .map((it) => it.split("_")[1])}
                        {@const amt = $acByRegion["abr"][r].reduce((p, ddd) => p + (_stat.CcAc["55_" + ddd] || _stat.CcAc["0_" + ddd] || 0), 0)}
                        <tr>
                            <td>{r}</td>
                            <td data-sort={amt}>{prettyNumber(amt)}</td>
                            <td data-sort={_stat.Calls > 0 ? (amt / _stat.Calls) * 100 : 0}>{(_stat.Calls > 0 ? (amt / _stat.Calls) * 100 : 0).toFixed(1)}%</td>
                        </tr>
                    {/each}
                </Table>
            {/if}
        {/if}
    {:else}
        <Spinner />
    {/if}
</Dialog>
