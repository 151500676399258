<script lang="ts">
    import type { Cadence } from "../../lib/golang-models/maindb"

    export var value: Cadence, name: string
</script>

<label>
    {name}
    <input
        autocomplete="off"
        on:change={(e) => {
            let a = e.currentTarget.value.split("/")
            value.Discard = parseInt(a[0])
            value.Minimum = parseInt(a[1])
            value.Increment = parseInt(a[2])
        }}
        value="{value.Discard}/{value.Minimum}/{value.Increment}"
        pattern="\d+\/\d+\/\d+"
        required
    />
</label>
