<script>
    import TabContent from "../../../widgets/tab/TabContent.svelte"

    import Tabs from "../../../widgets/tab/Tabs.svelte"
    import Abr from "./telephony-systems/Abr.svelte"
</script>

<Tabs selected="abr">
    <svelte:fragment slot="tabs">
        <a href="#abr">ABR Telecom</a>
    </svelte:fragment>
    <TabContent name="abr">
        <Abr />
    </TabContent>
</Tabs>
