<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../lib/Loader.svelte"
    import { abbreviateBytes } from "../../lib/Utils"
    import Form from "../../widgets/Form.svelte"
    import Table from "../../widgets/Table.svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import PageFooter from "../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")
    var month = new Date().getMonth() + 1,
        year = new Date().getFullYear(),
        selected: any = [],
        selectedTab: string = "month-files",
        bundlesVersion = 0

    function onGroupingCheckbox() {
        selected = document.querySelectorAll(".cdr-grouping-checkbox:checked")
    }
</script>

<Page>
    <Tabs bind:selected={selectedTab}>
        <svelte:fragment slot="tabs">
            <a href="#month-files">{i18n.translate("dict.month-files")}</a>
            <a href="#bundles">{i18n.translate("dict.bundles")}</a>
        </svelte:fragment>

        <TabContent name="month-files">
            <Form expanded box={false}>
                <label>
                    Month
                    <row>
                        <input type="number" bind:value={year} min="2020" placeholder="Year" on:change={() => (selected = [])} />
                        <input type="number" bind:value={month} min="1" max="12" placeholder="Month" on:change={() => (selected = [])} />
                    </row>
                </label>

                {#key year + "-" + month}
                    <Loader endpoint="client/cdr/files/{year}/{month}" let:data>
                        <Table margin="10px 0px 0px 0px">
                            <tr slot="head">
                                <th style="width: 50px;" />
                                <th>{i18n.translate("dict.date")}</th>
                                <th>{i18n.translate("dict.size")}</th>
                                <th>{i18n.translate("dict.actions")}</th>
                            </tr>

                            {#each data as it}
                                <tr>
                                    <td><input class="cdr-grouping-checkbox" data-date={it.Date} type="checkbox" on:click={() => onGroupingCheckbox()} /></td>
                                    <td>{it.Date}</td>
                                    <td>{abbreviateBytes(it.Size)}</td>
                                    <td>
                                        <a target="_blank" title="Download" href="client/cdr/download/{it.Date}.csv">
                                            <i class="fa-solid fa-download" />
                                        </a>
                                    </td>
                                </tr>
                            {/each}
                        </Table>
                    </Loader>
                {/key}
            </Form>
            {#if selected.length > 1}
                <PageFooter>
                    <expander />
                    <button
                        type="submit"
                        class="primary"
                        on:click={() => {
                            let files = []
                            for (let i = 0; i < selected.length; i++) {
                                files.push(selected[i].getAttribute("data-date"))
                            }
                            modal.post("client/cdr/bundle/make", { files }).then((res) => {
                                if (res.status != 200) return
                                selectedTab = "bundles"
                                bundlesVersion++
                            })
                        }}><i class="fa-solid fa-download" /> {i18n.translate("action.bundle")}</button
                    >
                </PageFooter>
            {/if}
        </TabContent>

        <TabContent name="bundles">
            {#key bundlesVersion}
                <Loader endpoint="client/cdr/bundle/list" let:data let:reload>
                    <column class="expanded">
                        <Table>
                            <tr slot="head">
                                <th>{i18n.translate("dict.file")}</th>
                                <th>{i18n.translate("dict.actions")}</th>
                            </tr>
                            {#each data as bundle}
                                <tr>
                                    <td>{bundle}</td>
                                    <td><a href="client/cdr/bundle/download?filename={bundle}"><i class="fa-solid fa-download" /></a></td>
                                </tr>
                            {/each}
                        </Table>
                    </column>

                    <PageFooter>
                        <expander />
                        <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
                    </PageFooter>
                </Loader>
            {/key}
        </TabContent>
    </Tabs>
</Page>
