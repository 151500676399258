<script lang="ts">
    import { dateTimeFormat } from "../../lib/Utils"

    export var value: string = dateTimeFormat(new Date()),
        timeInput: boolean = true

    var time: string, date: string

    function inputDate(x: string) {
        let d = new Date(x)
        date = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + d.getDate().toString().padStart(2, "0")
    }
    function inputTime(x: string) {
        let d = new Date(x)
        time = d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0")
    }
    function output(x: string, y: string) {
        if (y.includes("NaN")) y = "00:00"
        value = dateTimeFormat(new Date(x + " " + y + ":01"))
    }

    inputDate(value)
    inputTime(value)

    $: output(date, time)
    $: inputDate(value)
    $: inputTime(value)
</script>

<row>
    <input type="date" bind:value={date} />
    {#if timeInput}
        <input type="time" bind:value={time} />
    {/if}
</row>
