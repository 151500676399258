
export const isMobile = ('ontouchstart' in document.documentElement)

/***********************
 * 
 * TELEPHONY
 * 
 * ********************/
export function stringifyCadence(c: any) {
    return c.Discard + "/" + c.Minimum + "/" + c.Increment
}


/***********************
 * 
 * TIME
 * 
 * ********************/
export function millisFormat(ts, format = "h:m:s") {
    if (!ts) ts = 0
    return format.replace(/./g, (s) => {
        switch (s) {
            case "h": return Math.floor((ts / 3600000)).toString().padStart(2, "0")
            case "m": return Math.floor((ts / 60000) % 60).toString().padStart(2, "0")
            case "M": return Math.floor((ts / 60000)).toString().padStart(2, "0")
            case "s": return Math.floor((ts / 1000) % 60).toString().padStart(2, "0")
            case "S": return Math.floor((ts / 1000)).toString()
            case "l": return Math.floor(ts % 1000).toString().padStart(3, "0")
            default: return s
        }
    })
}

export function dateTimeFormat(d: Date) {
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")} ${dateFormatHMS(d)}`
}

export function dateFormatDMY(d: Date) {
    return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`
}

export function dateFormatHMS(d: Date) {
    return `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d.getSeconds().toString().padStart(2, "0")}`
}

export function dateFormatDMYTime(d: Date) {
    return `${dateFormatDMY(d)} ${dateFormatHMS(d)}`
}

/***********************
 * 
 * POST
 * 
 * ********************/
export async function post(path: string, data: any): Promise<any | boolean> {
    const res = await fetch(path, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    try {
        return {
            status: res.status,
            data: (await res.json())
        }
    } catch (err) {
        return {
            status: res.status,
            data: null
        }
    }
}

/***********************
 * 
 * Number
 * 
 * ********************/
export function calcProfit(sale, cost) {
    return (sale > 0 ? (sale - cost) / sale : cost ? -1 : 0) * 100
}

export function moneyFormat(v, pretty = true) {
    return pretty ? prettyNumber(v, 2, true) : v.toFixed(2).replace(".", ",")
}

export function safeStringPercent(numerator, denominator, decimals) {
    return denominator != 0 && denominator != null ? (numerator / denominator * 100).toFixed(decimals) + "%" : "0%"
}

export function safeDivideFloat(numerator, denominator) {
    return denominator != 0 ? (numerator / denominator) : 0
}

export function safeDivideInt(numerator, denominator) {
    return denominator != 0 ? Math.floor(numerator / denominator) : 0
}

export function prettyNumber(v, padEnd = 0, forcePad = false) {
    let z = v.toString()
    let minus = z.charAt(0).replace(/[^\-]/g, "")
    let a = minus ? z.substring(1).split(".") : z.split(".")
    if (forcePad && a.length == 1) a.push("0")
    let d = (a.length > 1 ? (forcePad || padEnd ? "," + a[1].padEnd(padEnd, "0").substring(0, padEnd) : "," + a[1]) : "")
    let n = a[0]
    if (n.length > 3) {
        let len = n.length + (3 - (n.length % 3)) % 3
        return minus + n.padStart(len).split("").map((it, i) => i != (len - 1) && i % 3 == 2 ? it + "." : it).join("").trim() + d
    } else {
        return minus + a[0] + d
    }
}

export function abbreviateBytes(v) {
    let av = ""
    if (v < 1024) {
        av = v + " B"
    } else if (v < 1048576) {
        av = (v / 1024).toFixed(1) + " KB"
    } else if (v < 1073741824) {
        av = (v / 1024 / 1024).toFixed(1) + " MB"
    } else {
        av = (v / 1024 / 1024 / 1024).toFixed(1) + " GB"
    }
    return av
}

export function abbreviateNumber(v) {
    v = v.toString()
    let n = v.toString().replace(/\,/g, ".").replace(/[^0-9.]/g, "").split(".")[0]
    let sign = v.charAt(0) === "-" ? "-" : ""
    let dots = Math.ceil(n.toString().length / 3)
    switch (dots) {
        case 0:
        case 1:
            return v.replace(".", ",")
        case 2:
            return sign + parseFloat((n * 0.001).toFixed(1)).toString().replace(".", ",") + "K"
        case 3:
            return sign + parseFloat((n * 0.000001).toFixed(1)).toString().replace(".", ",") + "M"
        default:
            return sign + parseFloat((n * 0.000000001).toFixed(1)).toString().replace(".", ",") + "G"
    }
}


/***********************
 * 
 * Array
 * 
 * ********************/
export function arrayRemoveItem(array, item) {
    let i = array.findIndex(it => it == item)
    if (i >= 0)
        array.splice(i, 1)
    return array
}

/***********************
 * 
 * Svelte
 * 
 * ********************/
export function componentLoop(onMount, onDestroy, time, callback) {
    let interval
    onMount(function () {
        interval = setInterval(() => callback(), time)
    })
    onDestroy(function () {
        clearInterval(interval)
    })
    callback()
}
