<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../lib/Loader.svelte"
    import { dateFormatDMYTime } from "../../lib/Utils"
    import { User } from "../../lib/golang-models/maindb"
    import Form from "../../widgets/Form.svelte"
    import Table from "../../widgets/Table.svelte"
    import TableSorter from "../../widgets/TableSorter.svelte"
    import Thumb from "../../widgets/Thumb.svelte"
    import Enum from "../../widgets/inputs/Enum.svelte"
    import Password from "../../widgets/inputs/Password.svelte"
    import Page from "../elements/Page.svelte"
    import PageFooter from "../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal"),
        login: any = getContext("login")

    var formMode: "none" | "edit" | "add" = "none"
    var formRecord: User = null
</script>

<Page>
    <Loader endpoint="user/list" let:data let:reload>
        {#if formMode == "none"}
            <Table>
                <tr slot="head">
                    <th>ID <TableSorter /></th>
                    <th>{i18n.translate("dict.name")} <TableSorter /></th>
                    <th>{i18n.translate("dict.username")} <TableSorter /></th>
                    <th>{i18n.translate("dict.role-code")} <TableSorter /></th>
                    <th>{i18n.translate("field.allow-login")} </th>
                    <th>{i18n.translate("dict.access")}</th>
                    <th>{i18n.translate("dict.actions")}</th>
                </tr>

                {#each data as record}
                    <tr>
                        <td>{record.Id}</td>
                        <td>{record.Name}</td>
                        <td>
                            {#if login.isMaster() && record.Id != login.Data.Id}
                                <a href="login/redirect/user/{record.Id}" target="_self"><i class="fa-solid fa-right-to-bracket" /> {record.Username}</a>
                            {:else}
                                {record.Username}
                            {/if}
                        </td>
                        <td>{record.RoleMask || ""}</td>
                        <td><Thumb value={record.AllowLogin} /></td>
                        <td>{(record.Accessed && dateFormatDMYTime(new Date(record.Accessed * 1000))) || ""}</td>
                        <td>
                            <a
                                href="#edit"
                                on:click|preventDefault={() => {
                                    formRecord = { ...record, Password: "" }
                                    formMode = "edit"
                                }}><i class="fa-solid fa-pencil" /></a
                            >
                            {#if record.Id > 1 && record.Id != login.Data.Id}
                                <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("user/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                            {/if}
                        </td>
                    </tr>
                {/each}
            </Table>

            <PageFooter>
                <expander />
                <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
                <button
                    on:click={() => {
                        formRecord = new User()
                        formMode = "add"
                    }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("dict.user")}</button
                >
            </PageFooter>
        {:else}
            <Form
                submit={() =>
                    modal.post("user/save", formRecord).then((res) => {
                        if (res.status != 200) return
                        formMode = "none"
                        reload()
                    })}
            >
                <svelte:fragment slot="title">{i18n.translate("action." + formMode)} {i18n.translate("dict.user")}</svelte:fragment>
                {#if formMode == "edit"}
                    <label>
                        ID
                        <input disabled value={formRecord.Id} />
                    </label>
                {/if}
                <label>
                    {i18n.translate("dict.name")}*
                    <input autocomplete="off" bind:value={formRecord.Name} disabled={formRecord.Id == 1} required />
                </label>
                <label>
                    {i18n.translate("dict.username")}*
                    <input autocomplete="off" bind:value={formRecord.Username} disabled={formRecord.Id == 1} required />
                </label>
                <Password name={formMode == "add" ? i18n.translate("field.password") + "*" : i18n.translate("field.new-password")} bind:value={formRecord.Password} required={formMode == "add"} />

                {#if formRecord.Id != 1}
                    <Enum name={`${i18n.translate("dict.roles")} (Cod: ${formRecord.RoleMask})`} labels={["Administration", "Softswitch Support", "Financial Support", "Seller"]} values={[0, 1, 2, 3]} bind:value={formRecord.RoleMask} />

                    <label class="checkbox">
                        <input type="checkbox" autocomplete="off" bind:checked={formRecord.AllowLogin} disabled={formRecord.Id == 1} />
                        {i18n.translate("field.allow-login")}
                    </label>
                {/if}

                <svelte:fragment slot="footer">
                    <PageFooter>
                        <expander />
                        <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                        <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                    </PageFooter>
                </svelte:fragment>
            </Form>
        {/if}
    </Loader>
</Page>
