<script lang="ts">
    export var name: string,
        labels: string[],
        values: number[],
        disabled: number[] = [],
        value: number
</script>

<column>
    {name}
    <column style="margin-left: 10px;">
        {#each labels as label, i}
            <label class="checkbox">
                <input
                    type="checkbox"
                    disabled={disabled.includes(values[i])}
                    checked={((1 << values[i]) & value) != 0}
                    on:change={(e) => {
                        if (e.currentTarget.checked) value |= 1 << values[i]
                        else value &= ~(1 << values[i])
                    }}
                />
                {label}
            </label>
        {/each}
    </column>
</column>
