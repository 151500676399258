<script lang="ts">
    import { prettyNumber } from "../../../lib/Utils"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Dialog from "../../../widgets/modals/Dialog.svelte"

    export var onClose: () => void, stat: any, name: string, period: number
</script>

<Dialog bodyPadding="0px" onClose={() => onClose()}>
    <svelte:fragment slot="title">SIP Status</svelte:fragment>
    {#if stat}
        {@const _stat = Array.isArray(stat.Calls) ? Object.fromEntries(Object.keys(stat).map((k) => [k, Array.isArray(stat[k]) ? stat[k][period] : stat[k]])) : stat}
        <b style="margin: 10px; text-align:center;">{name}</b>
        <Table margin="0px 10px 10px 10px">
            <tr slot="head">
                <th>Status <TableSorter /></th>
                <th>Amount <TableSorter /></th>
                <th>Ratio <TableSorter /></th>
            </tr>
            {#each Object.keys(_stat.Status)
                .map((it) => parseInt(it))
                .sort() as k}
                <tr>
                    <td>{k}</td>
                    <td data-sort={_stat.Status[k]}>{prettyNumber(_stat.Status[k])}</td>
                    <td data-sort={_stat.Calls > 0 ? (_stat.Status[k] / _stat.Calls) * 100 : 0}>{(_stat.Calls > 0 ? (_stat.Status[k] / _stat.Calls) * 100 : 0).toFixed(1)}%</td>
                </tr>
            {/each}
        </Table>
    {/if}
</Dialog>
