<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte"
    import Loader from "../../../../lib/Loader.svelte"
    import { componentLoop } from "../../../../lib/Utils"
    import Spinner from "../../../../widgets/Spinner.svelte"
    import CallStatsTable from "../../../elements/call-stats/CallStatsTable.svelte"
    import HangupCauseModal from "../../../elements/call-stats/HangupCauseModal.svelte"
    import StatusModal from "../../../elements/call-stats/StatusModal.svelte"
    import Charts from "../commons/Charts.svelte"

    export var clientsById,
        providersById,
        showFinancial: boolean,
        selectedClientId = null,
        selectedProviderId = null,
        viewStatusClientId = null,
        viewHangupCauseClientId = null,
        period: number

    var stats,
        statFocus: null | string = null,
        statFocusGroup: null | "client" | "provider",
        statFilter = { by: "none", techId: "none", legId: "none" }

    const i18n: any = getContext("i18n"),
        pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance")

    function load(keep = true) {
        if (!keep) stats = null

        let params = []
        if (statFocusGroup != null) params.push(`${statFocusGroup}-focus=` + statFocus)
        if (statFilter != null) params.push(`filter-by=${statFilter.by}&filter-leg=${statFilter.legId}&filter-tech=${statFilter.techId}`)
        fetch("softswitch/dashboard/main/general" + (params.length > 0 ? "?" + params.join("&") : ""))
            .then((r) => r.json())
            .then((r) => (stats = r))
    }

    componentLoop(onMount, onDestroy, 6000, function () {
        if ($pageManager.current == pageInstance) {
            load()
        }
    })
</script>

{#if stats}
    {@const clientsStats = Object.keys(stats.Clients).map((k) => ({ key: k.split("_")[0], id: k.split("_")[0], ...stats.Clients[k] }))}
    {@const providersStats = Object.keys(stats.Providers).map((k) => ({ key: k.split("_").pop(), id: k.split("_").pop(), ...stats.Providers[k] }))}

    <!-- Modals -->
    {#if viewStatusClientId}
        <StatusModal {period} stat={stats.Clients[viewStatusClientId]} name={viewStatusClientId in clientsById ? clientsById[viewStatusClientId].Name : "ID:" + viewStatusClientId} onClose={() => (viewStatusClientId = null)} />
    {/if}
    {#if viewHangupCauseClientId}
        <HangupCauseModal {period} stat={stats.Clients[viewHangupCauseClientId]} name={viewHangupCauseClientId in clientsById ? clientsById[viewHangupCauseClientId].Name : "ID:" + viewHangupCauseClientId} onClose={() => (viewHangupCauseClientId = null)} />
    {/if}

    <column class="expanded" style="padding: 10px;">
        <Charts {showFinancial} {period} showHangupCause stats={selectedClientId ? stats.Clients[selectedClientId] : selectedProviderId ? stats.Providers[selectedProviderId] : stats.Global} />

        <!-- Filter -->
        <space />
        <div>
            <space />
            <row style="gap:5px">
                <select
                    bind:value={statFilter.by}
                    on:change={() => {
                        statFilter.legId = "none"
                        statFilter.techId = "all"
                        load()
                    }}
                >
                    <option value="none">Without filter</option>
                    <option value="provider">Filter by provider</option>
                    <option value="client">Filter by client</option>
                </select>
                {#if statFilter.by == "provider"}
                    <Loader endpoint={"provider/list"} let:data>
                        <select
                            bind:value={statFilter.legId}
                            on:change={() => {
                                statFilter.techId = "all"
                                load()
                            }}
                        >
                            <option disabled value="none">Select a provider</option>
                            {#each data as it}
                                <option value={it.Id}>{it.Name}</option>
                            {/each}
                        </select>
                    </Loader>
                {/if}
                {#if statFilter.by == "provider" && statFilter.legId != "none"}
                    {#key statFilter.legId}
                        <Loader endpoint={"provider/techprefix/list/" + statFilter.legId} let:data>
                            <select
                                value={statFilter.techId}
                                on:change={(e) => {
                                    statFilter.techId = e.currentTarget.value
                                    load()
                                }}
                            >
                                <option value="all">All techprefixes</option>
                                {#each data as it}
                                    <option value={it.Id.toString()}>{it.Name}</option>
                                {/each}
                            </select>
                        </Loader>
                    {/key}
                {/if}

                {#if statFilter.by == "client"}
                    <Loader endpoint={"client/list"} let:data>
                        <select
                            bind:value={statFilter.legId}
                            on:change={() => {
                                statFilter.techId = "all"
                                load()
                            }}
                        >
                            <option disabled value="none">Select a client</option>
                            {#each data as it}
                                <option value={it.Id}>{it.Name}</option>
                            {/each}
                        </select>
                    </Loader>
                {/if}
                {#if statFilter.by == "client" && statFilter.legId != "none"}
                    {#key statFilter.legId}
                        <Loader endpoint={"client/techprefix/list/" + statFilter.legId} let:data>
                            <select
                                value={statFilter.techId}
                                on:change={(e) => {
                                    statFilter.techId = e.currentTarget.value
                                    load()
                                }}
                            >
                                <option value="all">All techprefixes</option>
                                {#each data as it}
                                    <option value={it.Id.toString()}>{it.Name}</option>
                                {/each}
                            </select>
                        </Loader>
                    {/key}
                {/if}
            </row>
        </div>

        {#if !statFilter || statFilter.by != "client"}
            <CallStatsTable
                onStatFocus={(k) => {
                    if (k) {
                        statFocusGroup = "client"
                        statFocus = k
                        load()
                    } else {
                        statFocusGroup = null
                    }
                }}
                {period}
                header="Client"
                data={clientsStats}
                dict={clientsById}
                {showFinancial}
                showHangupCause={true}
                onClick={(id) => {
                    selectedClientId = id
                    load(false)
                }}
            />
        {/if}

        {#if !statFilter || statFilter.by != "provider"}
            <CallStatsTable
                onStatFocus={(k) => {
                    if (k) {
                        statFocusGroup = "provider"
                        statFocus = k
                        load()
                    } else {
                        statFocusGroup = null
                    }
                }}
                {period}
                header="Provider"
                data={providersStats}
                dict={providersById}
                {showFinancial}
                showHangupCause={true}
                onClick={(id) => {
                    selectedProviderId = id
                    load(false)
                }}
            />
        {/if}
    </column>
{:else}
    <Spinner />
{/if}
