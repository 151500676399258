/* Do not change, this code is generated from Golang structs */


export enum UserRole {
    USER_ROLE_ADMIN = 0,
    USER_ROLE_SOFTSWITCH_SUPPORT = 1,
    USER_ROLE_FINANCIAL_SUPPORT = 2,
    USER_ROLE_SELLER = 3,
}
export class User {
    Id: number;
    Created: number;
    Modified: number;
    Accessed: number;
    AllowLogin: boolean;
    Username: string;
    Password: string;
    Name: string;
    RoleMask: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Accessed = source["Accessed"];
        this.AllowLogin = true;
        this.Username = "";
        this.Password = "";
        this.Name = "";
        this.RoleMask = 0;
    }
}
export class PhoneNumberGate {
    Mode: string;
    Matches: string[];
    Carriers: number[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Mode = "ac-prefix";
        this.Matches = source["Matches"];
        this.Carriers = source["Carriers"];
    }
}
export class TechprefixRule {
    Gate: PhoneNumberGate;
    Behavior: string;
    Id: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Gate = this.convertValues(source["Gate"], PhoneNumberGate);
        this.Behavior = "accept";
        this.Id = source["Id"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class Client {
    Id: number;
    Created: number;
    Modified: number;
    Accessed: number;
    AllowLogin: boolean;
    Username: string;
    Password: string;
    Name: string;
    Description: string;
    AllowCalls: boolean;
    CdrStore503: boolean;
    ChannelLimit: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Accessed = source["Accessed"];
        this.AllowLogin = true;
        this.Username = "";
        this.Password = "";
        this.Name = "";
        this.Description = "";
        this.AllowCalls = true;
        this.CdrStore503 = true;
        this.ChannelLimit = 0;
    }
}
export class ClientWallet {
    Id: number;
    Created: number;
    Modified: number;
    IsEnabled: boolean;
    Description: string;
    ClientId: number;
    Balance: number;
    Mode: string;
    PreMaxDebt: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.IsEnabled = true;
        this.Description = "";
        this.ClientId = 0;
        this.Balance = 0;
        this.Mode = "pre";
        this.PreMaxDebt = 0;
    }
}
export class AbrDynamicCidOptions {
    Mode: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Mode = "mixed";
    }
}
export class DynamicCidOptions {
    Abr: AbrDynamicCidOptions;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Abr = this.convertValues(source["Abr"], AbrDynamicCidOptions);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class NullInt64 {
    Int64: number;
    Valid: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Int64 = source["Int64"];
        this.Valid = source["Valid"];
    }
}
export class ClientTechprefix {
    Id: number;
    Created: number;
    Modified: number;
    ClientId: number;
    WalletId: number;
    ClassifierId: NullInt64;
    GlobalLcrId: NullInt64;
    TariffId: number;
    Name: string;
    Techprefix: string;
    IsEnabled: boolean;
    ChannelLimit: number;
    UsePlayback: boolean;
    UseOptions: boolean;
    RtpMode: string;
    CidMode: string;
    CustomCid: string;
    CidListId: NullInt64;
    DynamicCidOptions: DynamicCidOptions;
    Rules: TechprefixRule[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.ClientId = source["ClientId"];
        this.WalletId = source["WalletId"];
        this.ClassifierId = this.convertValues(source["ClassifierId"], NullInt64);
        this.GlobalLcrId = this.convertValues(source["GlobalLcrId"], NullInt64);
        this.TariffId = source["TariffId"];
        this.Name = "";
        this.Techprefix = "";
        this.IsEnabled = true;
        this.ChannelLimit = 0;
        this.UsePlayback = false;
        this.UseOptions = true;
        this.RtpMode = "p2p";
        this.CidMode = "open";
        this.CustomCid = "";
        this.CidListId = this.convertValues(source["CidListId"], NullInt64);
        this.DynamicCidOptions = this.convertValues(source["DynamicCidOptions"], DynamicCidOptions);
        this.Rules = this.convertValues(source["Rules"], TechprefixRule);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class Provider {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    Description: string;
    AllowCalls: boolean;
    ChannelLimit: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.Description = "";
        this.AllowCalls = true;
        this.ChannelLimit = 0;
    }
}
export class Cadence {
    Discard: number;
    Minimum: number;
    Increment: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Discard = 0;
        this.Minimum = 1;
        this.Increment = 1;
    }
}
export class TariffCustomPrice {
    Gate: PhoneNumberGate;
    Price: number;
    Id: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Gate = this.convertValues(source["Gate"], PhoneNumberGate);
        this.Price = 0;
        this.Id = source["Id"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class Tariff {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    Price: number;
    Cadence: Cadence;
    ClientId: NullInt64;
    ProviderId: NullInt64;
    CalcMethod: string;
    CustomPrices: TariffCustomPrice[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.Price = 0;
        this.Cadence = this.convertValues(source["Cadence"], Cadence);
        this.ClientId = this.convertValues(source["ClientId"], NullInt64);
        this.ProviderId = this.convertValues(source["ProviderId"], NullInt64);
        this.CalcMethod = source["CalcMethod"];
        this.CustomPrices = this.convertValues(source["CustomPrices"], TariffCustomPrice);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class Trunk {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    Username: string;
    Password: string;
    Auth: boolean;
    Ip: string;
    ClientId: NullInt64;
    Port: number;
    ProviderId: NullInt64;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.Username = "";
        this.Password = "";
        this.Auth = false;
        this.Ip = "";
        this.ClientId = this.convertValues(source["ClientId"], NullInt64);
        this.Port = 5060;
        this.ProviderId = this.convertValues(source["ProviderId"], NullInt64);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class PhoneNumberFormat {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    Regex: string;
    Keys: string[];
    TelephonySystem: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.Regex = source["Regex"];
        this.Keys = source["Keys"];
        this.TelephonySystem = "";
    }
}
export class PhoneNumberHandling {
    Id: number;
    Created: number;
    Modified: number;
    Behavior: string;
    RejectStatus: number;
    RejectReason: string;
    MatchTo: string;
    MatchFrom: string;
    ReplacementTo: string;
    ReplacementFrom: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Behavior = "whitelist";
        this.RejectStatus = 503;
        this.RejectReason = "";
        this.MatchTo = "";
        this.MatchFrom = "";
        this.ReplacementTo = "";
        this.ReplacementFrom = "";
    }
}
export class Lcr {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    ClientTechprefixId: NullInt64;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.ClientTechprefixId = this.convertValues(source["ClientTechprefixId"], NullInt64);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class LcrPriority {
    Priority: number;
    ProviderTechprefixId: number;
    LcrId: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Priority = 0;
        this.ProviderTechprefixId = source["ProviderTechprefixId"];
        this.LcrId = source["LcrId"];
    }
}
export class CidList {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    Info: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.Info = "";
    }
}
export class ProviderTechprefix {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    Techprefix: string;
    ProviderId: number;
    TrunkId: number;
    DefaultTariffId: number;
    PhoneNumberFormatId: number;
    IsEnabled: boolean;
    ChannelLimit: number;
    PhoneNumberOutputFormat: string;
    CidMode: string;
    CustomCid: string;
    CidListId: NullInt64;
    DynamicCidOptions: DynamicCidOptions;
    Rules: TechprefixRule[];
    Ldn: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.Techprefix = "";
        this.ProviderId = source["ProviderId"];
        this.TrunkId = source["TrunkId"];
        this.DefaultTariffId = source["DefaultTariffId"];
        this.PhoneNumberFormatId = source["PhoneNumberFormatId"];
        this.IsEnabled = true;
        this.ChannelLimit = 0;
        this.PhoneNumberOutputFormat = "tech+cc+ac+number";
        this.CidMode = "open";
        this.CustomCid = "";
        this.CidListId = this.convertValues(source["CidListId"], NullInt64);
        this.DynamicCidOptions = this.convertValues(source["DynamicCidOptions"], DynamicCidOptions);
        this.Rules = this.convertValues(source["Rules"], TechprefixRule);
        this.Ldn = false;
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class RemoteRtpProxy {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    Ip: string;
    Port: number;
    IsEnabled: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.Ip = "";
        this.Port = 5009;
        this.IsEnabled = true;
    }
}
export class Classifier {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    IsEnabled: boolean;
    SpeechMarginTime: number;
    ConfirmSpeechTime: number;
    HangupStatus: number;
    CheckBeforeAnswer: boolean;
    PreMessageHangup: boolean;
    CheckAfterAnswer: boolean;
    NoVoiceDetectedHangup: boolean;
    GreetingDuration: number;
    GreetingTimeout: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.IsEnabled = true;
        this.SpeechMarginTime = 100;
        this.ConfirmSpeechTime = 500;
        this.HangupStatus = 487;
        this.CheckBeforeAnswer = true;
        this.PreMessageHangup = false;
        this.CheckAfterAnswer = true;
        this.NoVoiceDetectedHangup = false;
        this.GreetingDuration = 50;
        this.GreetingTimeout = 2000;
    }
}
export class Carrier {
    Id: number;
    Created: number;
    Modified: number;
    Name: string;
    AllowCalls: boolean;
    TelephonySystemNamespace: string;
    TelephonySystemReference: {[key: string]: any};

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Id = source["Id"];
        this.Created = source["Created"];
        this.Modified = source["Modified"];
        this.Name = "";
        this.AllowCalls = true;
        this.TelephonySystemNamespace = "abr";
        this.TelephonySystemReference = source["TelephonySystemReference"];
    }
}


