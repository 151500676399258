<script lang="ts">
    export var icon: string,
        color: string = "grey"
</script>

<column style="text-align: center; margin: auto; color: {color};">
    <i class={icon} style="font-size: 3rem;" />
    <div style="font-weight: bold; font-size: 2rem;">
        <slot />
    </div>
</column>
