<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte"
    import { componentLoop } from "../../../lib/Utils"
    import Spinner from "../../../widgets/Spinner.svelte"
    import Table from "../../../widgets/Table.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    var vars = null

    function load() {
        fetch("softswitch/debug/vars")
            .then((r) => r.json())
            .then((r) => (vars = r))
    }

    const pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance"),
        modal: any = getContext("modal")

    componentLoop(onMount, onDestroy, 5000, function () {
        if ($pageManager.current == pageInstance) {
            load()
        }
    })
</script>

{#if vars}
    <Table>
        <tr slot="head">
            <th>Variable</th>
            <th>Data</th>
        </tr>

        {#each Object.keys(vars.Router) as k}
            <tr>
                <td>{k}</td>
                <td>{vars.Router[k]}</td>
            </tr>
        {/each}
    </Table>
    <PageFooter>
        <expander />
        <button
            class="cancel"
            on:click={() => {
                if (confirm("Do you really want to terminate all calls?")) modal.post("softswitch/debug/shutdown-calls")
            }}>Shutdown Calls</button
        >
    </PageFooter>
{:else}
    <Spinner />
{/if}
