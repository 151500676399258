<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { UserRole } from "../../../lib/golang-models/maindb"
    import NonIdealState from "../../../widgets/NonIdealState.svelte"
    import Title from "../../../widgets/Title.svelte"
    import Login from "../../elements/Login.svelte"
    import Client from "./user/Client.svelte"
    import General from "./user/General.svelte"
    import Provider from "./user/Provider.svelte"

    const login: Login = getContext("login"),
        i18n: any = getContext("i18n")

    var selectedClientId = null,
        selectedProviderId = null,
        showFinancial = login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT),
        period = 0
</script>

<column class="expanded">
    <Loader endpoint={["client/list", "provider/list"]} let:data={deps}>
        {@const clientsById = Object.fromEntries(deps[0].map((v) => [v.Id, v]))}
        {@const providersById = Object.fromEntries(deps[1].map((v) => [v.Id, v]))}
        <Title title={selectedClientId ? (selectedClientId in clientsById ? clientsById[selectedClientId].Name : "") : selectedProviderId ? (selectedProviderId in providersById ? providersById[selectedProviderId].Name : "") : i18n.translate("dict.platform")}>
            <svelte:fragment slot="left">
                {#if selectedClientId || selectedProviderId}
                    <a
                        href="#back"
                        on:click|preventDefault={() => {
                            selectedClientId = null
                            selectedProviderId = null
                        }}><i class="fa-solid fa-angles-left" /> {i18n.translate("action.back")}</a
                    >
                {/if}
            </svelte:fragment>
            <svelte:fragment slot="right">
                <select bind:value={period}>
                    <option value={0}>{i18n.translate("dict.today")}</option>
                    <option value={1}>{i18n.translate("dict.week")}</option>
                    <option value={2}>{i18n.translate("dict.month")}</option>
                </select>
            </svelte:fragment>
        </Title>

        <!-- Client -->
        {#if selectedClientId}
            {@const clientData = clientsById[selectedClientId]}
            {#if clientData}
                <Client {period} {showFinancial} client={clientData} />
            {:else}
                <NonIdealState icon="fa-solid fa-triangle-exclamation">Client Not Found</NonIdealState>
            {/if}
        {:else if selectedProviderId}
            {@const providerData = providersById[selectedProviderId]}
            {#if providerData}
                <Provider {period} {showFinancial} provider={providerData} />
            {:else}
                <NonIdealState icon="fa-solid fa-triangle-exclamation">Provider Not Found</NonIdealState>
            {/if}

            <!-- General -->
        {:else}
            <General {period} {clientsById} {providersById} {showFinancial} bind:selectedClientId bind:selectedProviderId />
        {/if}
    </Loader>
</column>
