<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { createToast } from "../../../lib/Toast"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var mode: "view" | "edit" = "view",
        copy = null
</script>

{#if mode == "view"}
    <Loader endpoint="configs/softswitch/rtp-proxy" let:data>
        <Form readonly>
            <label class="checkbox readonly">
                <input type="checkbox" checked={data.IsEnabled} readonly />
                {i18n.translate("field.allow-calls")}
            </label>

            <label>
                {i18n.translate("field.rtp-proxy-whitelist")}
                <textarea style="max-width: 500px;" value={data.Whitelist.join(", ")} readonly />
            </label>

            <double-space />
            <b>Workers: </b>
            <Table margin="10px 0px" scroll={false}>
                <tr slot="head">
                    <th>#</th>
                    <th>Request Port</th>
                    <th>Sessions</th>
                    <th>Start Audio Port</th>
                    <th>Audio Port Range</th>
                </tr>
                {#if data.ServerWorkers}
                    {#each data.ServerWorkers as w, i}
                        <tr>
                            <td>{i + 1}°</td>
                            <td>{w.RequestPort}</td>
                            <td>{w.StartAudioPort}</td>
                            <td>{w.Sessions}</td>
                            <td>
                                {w.StartAudioPort} <i class="fas fa-arrow-right" />
                                {w.StartAudioPort + w.Sessions * 2}
                            </td>
                        </tr>
                    {/each}
                {/if}
            </Table>

            <row>
                <expander />
                <button
                    type="button"
                    on:click={() => {
                        mode = "edit"
                        copy = JSON.parse(JSON.stringify(data))
                        if (copy.ServerWorkers == null) copy.ServerWorkers = [{ RequestPort: 5500, StartAudioPort: 15000, Sessions: 17500 }]
                    }}><i class="fa-solid fa-pencil" /> {i18n.translate("action.edit")}</button
                >
            </row>
        </Form>
    </Loader>
{:else}
    <Form
        box={false}
        submit={() => {
            if (document.querySelector(".rtp-server-worker-row .fa-triangle-exclamation")) {
                createToast("error", "Invalid fields.") // TODO: traduzir
                return
            }
            modal.post("configs/softswitch/rtp-proxy/save", copy).then((res) => {
                if (res.status != 200) return
                mode = "view"
            })
        }}
    >
        <label class="checkbox">
            <input type="checkbox" bind:checked={copy.IsEnabled} />
            {i18n.translate("field.allow-calls")}
        </label>

        <label>
            {i18n.translate("field.rtp-proxy-whitelist")}
            <textarea style="max-width: 500px;" value={copy.Whitelist.join(", ")} on:change={(e) => (copy.Whitelist = e.currentTarget.value.split(",").map((it) => it.trim()))} />
        </label>

        <double-space />
        <b>Workers (<i class="fas fa-triangle-exclamation" /> Needs reboot): </b>
        <Table margin="10px 0px" scroll={false}>
            <tr slot="head">
                <th>#</th>
                <th>Request Port</th>
                <th>Sessions</th>
                <th>Start Audio Port</th>
                <th>Audio Port Range</th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>
            {#each copy.ServerWorkers as w, i}
                <tr class="rtp-server-worker-row">
                    <td
                        >{i + 1}°
                        {#if w.StartAudioPort + w.Sessions * 2 >= 65535 || w.StartAudioPort <= 10 || copy.ServerWorkers.find((it) => it != w && it.StartAudioPort >= w.StartAudioPort && it.StartAudioPort < w.StartAudioPort + w.Sessions * 2)}
                            <i class="fas fa-triangle-exclamation error-color" />
                        {:else}
                            <i class="fas fa-check success-color" />
                        {/if}
                    </td>
                    <td>
                        <input type="number" min="10" max="65535" bind:value={w.RequestPort} />
                    </td>
                    <td>
                        <input type="number" min="10" bind:value={w.Sessions} />
                    </td>
                    <td>
                        <input type="number" min="10" max="65535" bind:value={w.StartAudioPort} />
                    </td>
                    <td>
                        {w.StartAudioPort} <i class="fas fa-arrow-right" />
                        {w.StartAudioPort + w.Sessions * 2}
                    </td>
                    <td>
                        <a href="#remove" on:click|preventDefault={() => (copy.ServerWorkers = [...copy.ServerWorkers.filter((it) => it != w)])}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>
        <row>
            <a
                href="#add"
                on:click|preventDefault={() => {
                    copy.ServerWorkers = [...copy.ServerWorkers, { RequestPort: 5500 + copy.ServerWorkers.length, StartAudioPort: 15000, Sessions: 17500 }]
                }}><i class="fas fa-plus" /> {i18n.translate("action.add")} Worker</a
            >
        </row>

        <row>
            <expander />
            <button
                class="cancel"
                on:click={() => {
                    mode = "view"
                }}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button
            >
            <double-space />
            <button class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
        </row>
    </Form>
{/if}
