<script lang="ts">
    import { getContext } from "svelte"
    import type { Login } from "../../lib/models"

    const login: Login = getContext("login"),
        pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance"),
        i18n: any = getContext("i18n")
</script>

<column class="expanded" style="background: #ddd;">
    <!-- {#if $pageManager.current != pageInstance || pageInstance.setup.allows.includes(login.Type)} -->
    <row class="page-topbar">
        {pageInstance.setup.path.split("/").join(" / ")}
        <expander />
        <a href="#refresh" on:click|preventDefault={() => $pageManager.reload(pageInstance)}><i class="fa-solid fa-refresh" /></a>
        <space />
        <a href="#close" on:click|preventDefault={() => $pageManager.close(pageInstance)}><i class="fa-solid fa-times" /></a>
    </row>
    <slot />
    <!-- {:else} -->
    <!--TODO: restrict area-->
    <!-- {/if} -->
</column>

<style>
    .page-topbar {
        min-height: auto;
        border-bottom: var(--standard-border);
        padding: 5px;
        /* margin-bottom: 10px; */
        font-family: MainLight;
        background-image: linear-gradient(45deg, #5499db, #245c91);
        color: whitesmoke;
    }
    .page-topbar a {
        color: white;
        text-decoration: none;
    }
</style>
