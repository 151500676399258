<script lang="ts">
    export var options: { value: any; label: string }[] = [],
        value: any[] = [],
        placeholder: string = ""

    var edit: boolean = false,
        backup = null

    if (!value) value = []
</script>

<!-- Translate -->
<column>
    {#if edit}
        <column style="margin: 10px;">
            <small>Hold the shift key to select*</small>
            <select multiple bind:value>
                {#each options as opt}
                    <option value={opt.value}>{opt.label}</option>
                {/each}
            </select>
            <row>
                <button
                    type="button"
                    on:click={() => {
                        value = backup
                        edit = false
                    }}>Cancel</button
                >
                <button
                    type="button"
                    on:click={() => {
                        value = []
                        edit = false
                    }}>{placeholder}</button
                >
                <button type="button" class="primary" on:click={() => (edit = false)}>Apply</button>
            </row>
        </column>
    {:else}
        <a
            href="#select"
            on:click|preventDefault={() => {
                backup = JSON.parse(JSON.stringify(value))
                edit = true
            }}
        >
            {#if value.length == 0}
                {placeholder}
            {/if}
            {value.map((v) => (options.find((opt) => opt.value == v) || { label: "Undefined" }).label).join(", ")}
        </a>
    {/if}
</column>

<style>
    select[multiple] {
        border: var(--standard-border);
        padding: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        width: 100%;
    }
    button {
        padding: 4px;
        font-size: 0.75rem;
        flex: 1;
        border-top: none;
        border-radius: 0px;
    }
    button:not(:first-child) {
        border-left: none;
    }
    button:first-child {
        border-bottom-left-radius: 5px;
    }
    button:last-child {
        border-bottom-right-radius: 5px;
    }
</style>
