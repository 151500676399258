/* Do not change, this code is generated from Golang structs */


export enum StatGroup {
    Global = 0x0,
    Client = 0x1,
    TechA = 0x2,
    Provider = 0x3,
    TechB = 0x4,
    TechATechB = 0x5,
    ClientProvider = 0x6,
    ClientTechB = 0x7,
    TechAProvider = 0x8,
}
export class Record {
    StartTime: string;
    StatusA: string;
    StatusB: string;
    DestinationFormatId: string;
    Flags: string;
    FromUser: string;
    Destination: string;
    Cid: string;
    Cc: string;
    Ac: string;
    IpA: string;
    TechA: string;
    IpB: string;
    TechB: string;
    ClientId: string;
    TrunkIdA: string;
    TechIdA: string;
    ProviderId: string;
    TrunkIdB: string;
    TechIdB: string;
    CarrierId: string;
    HangupCause: string;
    HangupAgent: string;
    TotalDuration: string;
    AnswerDurationA: string;
    AnswerDurationB: string;
    BillsecA: string;
    BillsecB: string;
    BillingA: string;
    BillingB: string;
    PriceA: string;
    PriceB: string;
    PddA: string;
    PddB: string;
    CadenceA: string;
    CadenceB: string;
    OthersB: string;
    CallId: string;
    SiptraceOffset: string;
    CarrierRef: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.StartTime = source["StartTime"];
        this.StatusA = source["StatusA"];
        this.StatusB = source["StatusB"];
        this.DestinationFormatId = source["DestinationFormatId"];
        this.Flags = source["Flags"];
        this.FromUser = source["FromUser"];
        this.Destination = source["Destination"];
        this.Cid = source["Cid"];
        this.Cc = source["Cc"];
        this.Ac = source["Ac"];
        this.IpA = source["IpA"];
        this.TechA = source["TechA"];
        this.IpB = source["IpB"];
        this.TechB = source["TechB"];
        this.ClientId = source["ClientId"];
        this.TrunkIdA = source["TrunkIdA"];
        this.TechIdA = source["TechIdA"];
        this.ProviderId = source["ProviderId"];
        this.TrunkIdB = source["TrunkIdB"];
        this.TechIdB = source["TechIdB"];
        this.CarrierId = source["CarrierId"];
        this.HangupCause = source["HangupCause"];
        this.HangupAgent = source["HangupAgent"];
        this.TotalDuration = source["TotalDuration"];
        this.AnswerDurationA = source["AnswerDurationA"];
        this.AnswerDurationB = source["AnswerDurationB"];
        this.BillsecA = source["BillsecA"];
        this.BillsecB = source["BillsecB"];
        this.BillingA = source["BillingA"];
        this.BillingB = source["BillingB"];
        this.PriceA = source["PriceA"];
        this.PriceB = source["PriceB"];
        this.PddA = source["PddA"];
        this.PddB = source["PddB"];
        this.CadenceA = source["CadenceA"];
        this.CadenceB = source["CadenceB"];
        this.OthersB = source["OthersB"];
        this.CallId = source["CallId"];
        this.SiptraceOffset = source["SiptraceOffset"];
        this.CarrierRef = source["CarrierRef"];
    }
}
export class RecordExt {
    StartTime: string;
    StatusA: string;
    StatusB: string;
    DestinationFormatId: string;
    Flags: string;
    FromUser: string;
    Destination: string;
    Cid: string;
    Cc: string;
    Ac: string;
    IpA: string;
    TechA: string;
    IpB: string;
    TechB: string;
    ClientId: string;
    TrunkIdA: string;
    TechIdA: string;
    ProviderId: string;
    TrunkIdB: string;
    TechIdB: string;
    CarrierId: string;
    HangupCause: string;
    HangupAgent: string;
    TotalDuration: string;
    AnswerDurationA: string;
    AnswerDurationB: string;
    BillsecA: string;
    BillsecB: string;
    BillingA: string;
    BillingB: string;
    PriceA: string;
    PriceB: string;
    PddA: string;
    PddB: string;
    CadenceA: string;
    CadenceB: string;
    OthersB: string;
    CallId: string;
    SiptraceOffset: string;
    CarrierRef: string;
    ProviderName: string;
    ClientName: string;
    TrunkNameA: string;
    TrunkNameB: string;
    TechNameA: string;
    TechNameB: string;
    LegsB: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.StartTime = source["StartTime"];
        this.StatusA = source["StatusA"];
        this.StatusB = source["StatusB"];
        this.DestinationFormatId = source["DestinationFormatId"];
        this.Flags = source["Flags"];
        this.FromUser = source["FromUser"];
        this.Destination = source["Destination"];
        this.Cid = source["Cid"];
        this.Cc = source["Cc"];
        this.Ac = source["Ac"];
        this.IpA = source["IpA"];
        this.TechA = source["TechA"];
        this.IpB = source["IpB"];
        this.TechB = source["TechB"];
        this.ClientId = source["ClientId"];
        this.TrunkIdA = source["TrunkIdA"];
        this.TechIdA = source["TechIdA"];
        this.ProviderId = source["ProviderId"];
        this.TrunkIdB = source["TrunkIdB"];
        this.TechIdB = source["TechIdB"];
        this.CarrierId = source["CarrierId"];
        this.HangupCause = source["HangupCause"];
        this.HangupAgent = source["HangupAgent"];
        this.TotalDuration = source["TotalDuration"];
        this.AnswerDurationA = source["AnswerDurationA"];
        this.AnswerDurationB = source["AnswerDurationB"];
        this.BillsecA = source["BillsecA"];
        this.BillsecB = source["BillsecB"];
        this.BillingA = source["BillingA"];
        this.BillingB = source["BillingB"];
        this.PriceA = source["PriceA"];
        this.PriceB = source["PriceB"];
        this.PddA = source["PddA"];
        this.PddB = source["PddB"];
        this.CadenceA = source["CadenceA"];
        this.CadenceB = source["CadenceB"];
        this.OthersB = source["OthersB"];
        this.CallId = source["CallId"];
        this.SiptraceOffset = source["SiptraceOffset"];
        this.CarrierRef = source["CarrierRef"];
        this.ProviderName = source["ProviderName"];
        this.ClientName = source["ClientName"];
        this.TrunkNameA = source["TrunkNameA"];
        this.TrunkNameB = source["TrunkNameB"];
        this.TechNameA = source["TechNameA"];
        this.TechNameB = source["TechNameB"];
        this.LegsB = source["LegsB"];
    }
}
export class StatisticalSearchData {
    Calls: number;
    Errors: number;
    DiscardB: number;
    AnswerDurationA: number;
    AnswerDurationB: number;
    PositiveVoice: number;
    OffensivePdd: number;
    BillingA: number;
    BillingB: number;
    BillsecA: number;
    BillsecB: number;
    HangupCause: {[key: HangupCause]: number};
    StatusA: {[key: int]: number};
    StatusB: {[key: int]: number};
    PhoneFormats: {[key: int64]: number};
    CcAc: {[key: string]: number};
    Carriers: {[key: int64]: number};

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Calls = source["Calls"];
        this.Errors = source["Errors"];
        this.DiscardB = source["DiscardB"];
        this.AnswerDurationA = source["AnswerDurationA"];
        this.AnswerDurationB = source["AnswerDurationB"];
        this.PositiveVoice = source["PositiveVoice"];
        this.OffensivePdd = source["OffensivePdd"];
        this.BillingA = source["BillingA"];
        this.BillingB = source["BillingB"];
        this.BillsecA = source["BillsecA"];
        this.BillsecB = source["BillsecB"];
        this.HangupCause = source["HangupCause"];
        this.StatusA = source["StatusA"];
        this.StatusB = source["StatusB"];
        this.PhoneFormats = source["PhoneFormats"];
        this.CcAc = source["CcAc"];
        this.Carriers = source["Carriers"];
    }
}
export class SearchResult {
    Time: number;
    Timeouted: boolean;
    Finished: boolean;
    Records: RecordExt[];
    ChartData: number[][];
    StatisticalData: StatisticalSearchData;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Time = source["Time"];
        this.Timeouted = source["Timeouted"];
        this.Finished = source["Finished"];
        this.Records = this.convertValues(source["Records"], RecordExt);
        this.ChartData = source["ChartData"];
        this.StatisticalData = this.convertValues(source["StatisticalData"], StatisticalSearchData);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class SearchQuery {
    Name: string;
    CallId: string;
    Destination: string;
    DestinationFormatId: string;
    CallerId: string;
    From: string;
    Carrier: string;
    ClientId: string;
    StatusA: string;
    TechIdA: string;
    IpA: string;
    OnlyProviderErrors: boolean;
    ProviderId: string;
    StatusB: string;
    TechIdB: string;
    IpB: string;
    HangupCause: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.Name = source["Name"];
        this.CallId = "";
        this.Destination = "";
        this.DestinationFormatId = "";
        this.CallerId = "";
        this.From = "";
        this.Carrier = "";
        this.ClientId = "";
        this.StatusA = "";
        this.TechIdA = "";
        this.IpA = "";
        this.OnlyProviderErrors = false;
        this.ProviderId = "";
        this.StatusB = "";
        this.TechIdB = "";
        this.IpB = "";
        this.HangupCause = "";
    }
}
export class SearchRequest {
    SearchMode: string;
    SearchName: string;
    SearchLimit: number;
    SearchTimeout: number;
    StartDate: string;
    EndDate: string;
    Queries: SearchQuery[];
    IncludeCcAc: boolean;
    IncludeCarriers: boolean;
    IncludeHangupCause: boolean;
    UserId: number;
    ExportFields: string[];
    ExportFinancial: boolean;
    ExportNames: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.SearchMode = "records";
        this.SearchName = source["SearchName"];
        this.SearchLimit = 100;
        this.SearchTimeout = 30;
        this.StartDate = source["StartDate"];
        this.EndDate = source["EndDate"];
        this.Queries = this.convertValues(source["Queries"], SearchQuery);
        this.IncludeCcAc = false;
        this.IncludeCarriers = false;
        this.IncludeHangupCause = false;
        this.UserId = 0;
        this.ExportFields = source["ExportFields"];
        this.ExportFinancial = source["ExportFinancial"];
        this.ExportNames = source["ExportNames"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
