<script lang="ts">
    import { getContext } from "svelte"
    import { millisFormat } from "../../../lib/Utils"
    import type { SearchResult } from "../../../lib/golang-models/cdr"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"

    const phoneFormats = getContext("phone-formats"),
        customCarriers = getContext("custom-carriers")
    const phoneFormatsById = Object.fromEntries(Object.values(phoneFormats).map((it) => [it.Id, it])),
        carriersById = Object.fromEntries(Object.values(customCarriers).map((v) => [v.Id, v]))
    const i18n: any = getContext("i18n"),
        login: any = getContext("login")

    export var result: SearchResult
    var viewRecord = null

    function formatNumber(n) {
        return n
            .split("")
            .reverse()
            .join("")
            .match(/\d{1,4}/g)
            .map((it) => it.split("").reverse().join(""))
            .reverse()
            .join("-")
    }

    function formatDestination(f, d) {
        if (!f) return formatNumber(d)
        let m = d.match(new RegExp(f.Regex))
        if (!m) return formatNumber(d)
        m = m.filter((it) => it)
        m[m.length - 1] = formatNumber(m[m.length - 1])
        return "(" + m.slice(1).join(")(") + ")"
    }
</script>

<row style="flex:1;">
    <column class="expanded">
        <row style="padding: 0px 20px; margin-top: 10px;">
            <span title="Results/Limit">
                <i class="fa-solid fa-table-list" style="font-size: 0.8rem;" />
                {result.Records.length}/{result["request"].SearchLimit}
            </span>
            <space />
            <span title="Search duration time">
                <i class="fa-solid fa-clock" style="font-size: 0.8rem;" />
                {millisFormat(result.Time, "h:m:s.l")}
            </span>
        </row>
        <Table clickable>
            <tr slot="head">
                <th style="width: 50px;">#</th>
                <th>Start</th>
                <th>Caller-ID</th>
                <th>Destination</th>
                <th>Type</th>
                {#if login.Type == "user"}
                    <th>Party A</th>
                    <th>Techprefix A</th>
                    <th>Status A <TableSorter /></th>
                    <th>Party B</th>
                    <th>Techprefix B</th>
                    <th>Status B <TableSorter /></th>
                {:else}
                    <th>Techprefix</th>
                    <th>Status <TableSorter /></th>
                {/if}
                <th style="width: 50px;">Hangup Agent</th>
                <th>Total Duration <TableSorter /></th>
                <th>Answer Duration <TableSorter /></th>
            </tr>
            {#each result.Records as record, i (record.CallId + "-" + i)}
                {@const phoneFormat = phoneFormatsById[record.DestinationFormatId]}
                <tr
                    on:click={() => {
                        viewRecord = record
                    }}
                    class:selected={viewRecord == record}
                >
                    <td>{i + 1}</td>
                    <td>{record.StartTime}</td>
                    <td>{record.Cid}</td>
                    <td>{formatDestination(phoneFormat, record.Destination)}</td>
                    <td>{(phoneFormat && phoneFormat.Name) || record.DestinationFormatId}</td>
                    {#if login.Type == "user"} <td>{record.ClientName}</td>{/if}
                    <td>{record.TechNameA}</td>
                    <td class:success-color={record.StatusA == "200"}>{record.StatusA}</td>
                    {#if login.Type == "user"}
                        <td>{record.ProviderName}</td>
                        <td>{record.TechNameB}</td>
                        <td class:success-color={record.StatusB == "200"}>{record.StatusB}</td>
                    {/if}
                    <td>{i18n.translate("telephony.hangup-agent." + record.HangupAgent)}</td>
                    <td>{millisFormat(record.TotalDuration)}</td>
                    <td>{(record.AnswerDurationA && millisFormat(record.AnswerDurationA)) || "-"}</td>
                </tr>
            {/each}
        </Table>
    </column>

    {#if viewRecord}
        {@const phoneFormat = phoneFormatsById[viewRecord.DestinationFormatId]}
        {@const carrierRefArray = viewRecord.CarrierRef.split(":")}
        <column class="expanded" style="border-left: var(--standard-border); position: relative;">
            <row style="position: absolute; padding: 10px;">
                <a href="#btn" on:click|preventDefault={() => (viewRecord = null)}><i class="fa-solid fa-times" /></a>
            </row>
            <Form readonly>
                <column class="form-grid">
                    <!-- TODO: carrierId -->
                    <row>
                        <label>
                            Start
                            <input type="text" value={viewRecord.StartTime} readonly />
                        </label>
                        <label>
                            End
                            <input type="text" value={new Date(new Date(viewRecord.StartTime.split(" ")[0].split("/").reverse().join("/") + " " + viewRecord.StartTime.split(" ")[1]).getTime() + parseInt(viewRecord.TotalDuration)).toLocaleString()} readonly />
                        </label>
                    </row>
                    <row>
                        <label>
                            Total Duration
                            <input type="text" value={millisFormat(viewRecord.TotalDuration)} readonly />
                        </label>
                        <label>
                            Call-ID
                            <input type="text" value={viewRecord.CallId} readonly />
                        </label>
                    </row>
                    <row>
                        <label>
                            Type
                            <input type="text" value={(phoneFormat && phoneFormat.Name) || viewRecord.DestinationFormatId} readonly />
                        </label>
                        <label>
                            Destination
                            <input type="text" value={formatDestination(phoneFormat, viewRecord.Destination)} readonly />
                        </label>
                    </row>
                    {#if carrierRefArray.length > 1}
                        <row>
                            <label>
                                Carrier Name
                                <input type="text" value={viewRecord.CarrierId in carriersById ? carriersById[viewRecord.CarrierId].Name : viewRecord.CarrierId ? "ID: " + viewRecord.CarrierId : ""} readonly />
                            </label>

                            {#if carrierRefArray[0] == "abr"}
                                <label>
                                    Carrier CNPJ Base
                                    <input type="text" value={carrierRefArray[1]} readonly />
                                </label>
                            {/if}
                        </row>
                    {/if}
                    <row>
                        <label>
                            From
                            <input type="text" value={viewRecord.FromUser} readonly />
                        </label>
                        <label>
                            Caller-ID
                            <input type="text" value={viewRecord.Cid} readonly />
                        </label>
                    </row>
                    <row>
                        <label>
                            Hangup Agent
                            <input type="text" value={i18n.translate("telephony.hangup-agent." + viewRecord.HangupAgent)} readonly />
                        </label>
                        <label>
                            Hangup Cause
                            <input type="text" value={i18n.translate("telephony.hangup-cause." + viewRecord.HangupCause)} readonly />
                        </label>
                    </row>

                    <hr />
                    {#if login.Type == "user"}<b>Party A</b>{/if}
                    <row>
                        <label>
                            Client
                            <input type="text" value={viewRecord.ClientName} readonly />
                        </label>
                        <label>
                            Trunk
                            <input type="text" value={viewRecord.TrunkNameA} readonly />
                        </label>
                    </row>
                    <row>
                        <label>
                            Techprefix
                            <input type="text" value={viewRecord.TechNameA} readonly />
                        </label>
                        <label>
                            IP
                            <input type="text" value={viewRecord.IpA || "-"} readonly />
                        </label>
                    </row>
                    <row>
                        <label>
                            Status
                            <input type="text" value={viewRecord.StatusA} readonly />
                        </label>
                        <label>
                            PDD
                            <input type="text" value={(viewRecord.PddA && millisFormat(viewRecord.PddA, "h:m:s.l")) || "-"} readonly />
                        </label>
                    </row>
                    {#if viewRecord.StatusA == 200}
                        <row>
                            <label>
                                Answer Duration
                                <input type="text" value={(viewRecord.AnswerDurationA && millisFormat(viewRecord.AnswerDurationA, "h:m:s.l")) || "-"} readonly />
                            </label>
                            <label>
                                Billsec
                                <input type="text" value={(viewRecord.BillsecA && millisFormat(viewRecord.BillsecA * 1000)) || "-"} readonly />
                            </label>
                        </row>
                        <row>
                            <label>
                                Cadence
                                {#if viewRecord.PriceA != ""}
                                    <input type="text" value="{viewRecord.CadenceA} (${parseFloat(viewRecord.PriceA)})" readonly />
                                {:else}
                                    <input type="text" value={viewRecord.CadenceA} readonly />
                                {/if}
                            </label>
                            <label>
                                Charged Amount
                                <input type="text" value={viewRecord.BillingA != "" ? parseFloat(viewRecord.BillingA) : "-"} readonly />
                            </label>
                        </row>
                    {/if}

                    {#if login.Type == "user"}
                        {#if viewRecord.LegsB.length > 0}
                            {#if viewRecord.ProviderId}
                                <hr />
                                <b>Party B</b>
                                <row>
                                    <label>
                                        Provider
                                        <input type="text" value={viewRecord.ProviderName} readonly />
                                    </label>
                                    <label>
                                        Trunk
                                        <input type="text" value={viewRecord.TrunkNameB} readonly />
                                    </label>
                                </row>
                                <row>
                                    <label>
                                        Techprefix
                                        <!-- <input type="text" value={viewRecord.TechB + ((techsBById[viewRecord.TechIdB] && ` (${techsBById[viewRecord.TechIdB].Name})`) || "")} readonly /> -->
                                        <input type="text" value={viewRecord.TechNameB} readonly />
                                    </label>
                                    <label>
                                        IP
                                        <input type="text" value={viewRecord.IpB || "-"} readonly />
                                    </label>
                                </row>
                                <row>
                                    <label>
                                        Status
                                        <input type="text" value={viewRecord.StatusB || "-"} readonly />
                                    </label>
                                    <label>
                                        PDD
                                        <input type="text" value={(viewRecord.PddB && millisFormat(viewRecord.PddB, "h:m:s.l")) || "-"} readonly />
                                    </label>
                                </row>
                                {#if viewRecord.StatusB == 200}
                                    <row>
                                        <label>
                                            Answer Duration
                                            <input type="text" value={(viewRecord.AnswerDurationB && millisFormat(viewRecord.AnswerDurationB, "h:m:s.l")) || "-"} readonly />
                                        </label>
                                        <label>
                                            Billsec
                                            <input type="text" value={(viewRecord.BillsecB && millisFormat(viewRecord.BillsecB * 1000)) || "-"} readonly />
                                        </label>
                                    </row>

                                    <row>
                                        <label>
                                            Cadence
                                            {#if viewRecord.PriceB != ""}
                                                <input type="text" value="{viewRecord.CadenceB} (${parseFloat(viewRecord.PriceB)})" readonly />
                                            {:else}
                                                <input type="text" value={viewRecord.CadenceB} readonly />
                                            {/if}
                                        </label>
                                        <label>
                                            Charged Amount
                                            <input type="text" value={viewRecord.BillingB != "" ? parseFloat(viewRecord.BillingB) : "-"} readonly />
                                        </label>
                                    </row>
                                {/if}
                            {/if}

                            <hr />
                            <b>LCR</b>

                            {@const legs = viewRecord.LegsB.split("|")}
                            <Table scroll={false} margin="10px 0px 0px">
                                <tr slot="head">
                                    <th>#</th>
                                    <th>Provider</th>
                                    <th>Techprefix</th>
                                    <th>Status</th>
                                    <th>PDD</th>
                                </tr>

                                {#each legs as l, i}
                                    {@const a = l.split(":")}
                                    <tr>
                                        <td>{i + 1}°</td>
                                        <td>{a[0]}</td>
                                        <td>{a[1]}</td>
                                        <td>{a[2]}</td>
                                        <td>{a[3] != "0" ? millisFormat(parseInt(a[3]), "h:m:s.l") : "-"}</td>
                                    </tr>
                                {/each}
                            </Table>
                        {/if}
                    {/if}
                </column>
            </Form>
        </column>
    {/if}
</row>

<style>
    tr.selected {
        background-color: #d2e4f7;
    }
</style>
