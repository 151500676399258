<script lang="ts">
    import { afterUpdate } from "svelte"
    import { dateTimeFormat } from "../../lib/Utils"
    import DateTime from "./DateTime.svelte"

    export var start: string,
        end: string,
        mode: string = "today",
        timeInput: boolean = true

    afterUpdate(function () {
        if (mode == "today") {
            let d = new Date()
            d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 1)
            start = dateTimeFormat(d)
            end = dateTimeFormat(new Date(d.getTime() + (1440 * 60 - 2) * 1000))
        } else if (mode == "yesterday") {
            let d = new Date()
            d = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1, 0, 0, 1)
            start = dateTimeFormat(d)
            end = dateTimeFormat(new Date(d.getTime() + (1440 * 60 - 2) * 1000))
        } else if (mode == "custom-date") {
            let d = new Date(start)
            d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 1)
            start = dateTimeFormat(d)
            end = dateTimeFormat(new Date(d.getTime() + (1440 * 60 - 2) * 1000))
        } else if (mode == "current-week") {
            let d = new Date()
            start = dateTimeFormat(new Date(d.getFullYear(), d.getMonth(), d.getDay() == 0 ? d.getDate() - 6 : d.getDate() - (d.getDay() - 1), 0, 0, 1))
            end = dateTimeFormat(new Date(d.getFullYear(), d.getMonth(), d.getDate() + (6 - (d.getDay() == 0 ? 6 : d.getDay() - 1)), 23, 59, 59))
        } else if (mode == "last-week") {
            let d = new Date()
            let ld = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7)
            start = dateTimeFormat(new Date(ld.getFullYear(), ld.getMonth(), ld.getDay() == 0 ? ld.getDate() - 6 : ld.getDate() - (ld.getDay() - 1), 0, 0, 1))
            end = dateTimeFormat(new Date(ld.getFullYear(), ld.getMonth(), ld.getDate() + (6 - (ld.getDay() == 0 ? 6 : ld.getDay() - 1)), 23, 59, 59))
        } else if (mode == "current-month") {
            let d = new Date()
            start = dateTimeFormat(new Date(d.getFullYear(), d.getMonth(), 1, 0, 0, 1))
            end = dateTimeFormat(new Date(d.getFullYear(), d.getMonth() + 1, 0, 23, 59, 59))
        } else if (mode == "last-month") {
            let d = new Date()
            start = dateTimeFormat(new Date(d.getFullYear(), d.getMonth() - 1, 1, 0, 0, 1))
            end = dateTimeFormat(new Date(d.getFullYear(), d.getMonth(), 0, 23, 59, 59))
        }
    })
</script>

<row>
    <label>
        Date Mode
        <select bind:value={mode}>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="current-week">Current Week</option>
            <option value="current-month">Current Month</option>
            <option value="last-week">Last Week</option>
            <option value="last-month">Last Month</option>
            <option value="custom-date">Custom Date</option>
            <option value="custom-range">Custom Range</option>
        </select>
    </label>
    {#if mode == "custom-date"}
        <label for="">
            Date
            <DateTime bind:value={start} timeInput={false} />
        </label>
    {/if}
</row>

{#if mode == "custom-range"}
    <row>
        <label for="">
            Start Date
            <DateTime bind:value={start} {timeInput} />
        </label>
        <label for="">
            End Date
            <DateTime bind:value={end} {timeInput} />
        </label>
    </row>
{/if}
