<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { PhoneNumberHandling } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import NonIdealState from "../../../widgets/NonIdealState.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: PhoneNumberHandling = null
</script>

{#if import.meta.env.MODE == "prod"}
    <NonIdealState icon="fa-solid fa-tools" />
{:else}
    <Loader endpoint="phone-number-handling/list" let:data let:reload>
        {#if formMode == "none"}
            <!-- List -->
            <Table>
                <tr slot="head">
                    <th>ID <TableSorter /></th>
                    <th>{i18n.translate("dict.origin")} <TableSorter /></th>
                    <th>{i18n.translate("dict.destination")} <TableSorter /></th>
                    <th>{i18n.translate("dict.behavior")} <TableSorter /></th>
                    <th>{i18n.translate("dict.description")}</th>
                    <th>{i18n.translate("dict.actions")}</th>
                </tr>

                {#each data as record}
                    <tr>
                        <td>{record.Id}</td>
                        <td>{record.MatchFrom}</td>
                        <td>{record.MatchTo}</td>
                        <td>{i18n.translate("action." + record.Behavior)}</td>
                        <td>
                            {#if record.Behavior == "reject"}
                                {record.RejectStatus} {record.RejectReason}
                            {:else if record.Behavior == "replace"}
                                {[record.ReplacementFrom, record.ReplacementTo].filter((it) => it).join(", ")}
                            {/if}
                        </td>
                        <td>
                            <a
                                href="#edit"
                                on:click|preventDefault={() => {
                                    formRecord = { ...record }
                                    formMode = "edit"
                                }}><i class="fa-solid fa-pencil" /></a
                            >
                            <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("phone-number-handling/remove/" + record.Id).then((r) => r.status == 200 && reload())}
                                ><i class="fa-solid fa-trash" /></a
                            >
                        </td>
                    </tr>
                {/each}
            </Table>

            <PageFooter>
                <expander />
                <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
                <button
                    on:click={() => {
                        formRecord = new PhoneNumberHandling()
                        formMode = "add"
                    }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("dict.phone-number")}</button
                >
            </PageFooter>
        {:else}
            <Form
                submit={() =>
                    modal.post(`phone-number-handling/save`, formRecord).then((res) => {
                        if (res.status != 200) return
                        formMode = "none"
                        reload()
                    })}
            >
                <svelte:fragment slot="title">{i18n.translate("action." + formMode)}</svelte:fragment>

                {#if formMode == "edit"}
                    <label>
                        ID
                        <input disabled value={formRecord.Id} />
                    </label>
                {/if}

                <label>
                    {i18n.translate("dict.behavior")}
                    <select bind:value={formRecord.Behavior}>
                        <option value="whitelist">{i18n.translate("dict.whitelist")}</option>
                        <option value="replace">{i18n.translate("action.replace")}</option>
                        <option value="reject">{i18n.translate("action.reject")}</option>
                    </select>
                </label>

                <space />
                <b>{i18n.translate("dict.condition")}:</b>
                <label>
                    {i18n.translate("dict.origin")}
                    <input type="text" placeholder="Phone number or regex, example: ^55(\d+)1223$" bind:value={formRecord.MatchFrom} pattern="[#*\d]+" />
                </label>
                <label>
                    {i18n.translate("dict.destination")} (between ^ and $ for regex)
                    <input type="text" placeholder="Phone number or regex, example: ^55(\d+)1223$" bind:value={formRecord.MatchTo} pattern="[#*\d]+" />
                </label>

                <space />
                {#if formRecord.Behavior == "reject"}
                    <b>{i18n.translate("dict.reply")}:</b>
                    <label>
                        Status*
                        <input type="number" bind:value={formRecord.RejectStatus} required min="400" max="599" />
                    </label>
                    <label>
                        Reason
                        <input type="text" bind:value={formRecord.RejectReason} pattern="\w+" />
                    </label>
                {:else if formRecord.Behavior == "replace"}
                    <b>{i18n.translate("action.replace-by")}:</b>
                    <label>
                        {i18n.translate("dict.origin")}
                        <input type="text" bind:value={formRecord.ReplacementFrom} pattern="[#*\d]+" />
                    </label>
                    <label>
                        {i18n.translate("dict.destination")}
                        <input type="text" bind:value={formRecord.ReplacementTo} pattern="[#*\d]+" />
                    </label>
                {/if}

                <svelte:fragment slot="footer">
                    <PageFooter>
                        <expander />
                        <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                        <button type="submit" disabled={!(formRecord.MatchFrom || formRecord.MatchTo) || (formRecord.Behavior == "replace" && !formRecord.ReplacementFrom && !formRecord.ReplacementTo)} class="primary"
                            ><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button
                        >
                    </PageFooter>
                </svelte:fragment>
            </Form>
        {/if}
    </Loader>
{/if}
