<script lang="ts">
    import { getContext, onMount, setContext } from "svelte"
    import { createToast } from "../../lib/Toast"
    import { post } from "../../lib/Utils"
    import { User, type UserRole } from "../../lib/golang-models/maindb"
    import type { Login } from "../../lib/models"
    import CountryFlags from "../../widgets/CountryFlags.svelte"
    import Spinner from "../../widgets/Spinner.svelte"
    import Password from "../../widgets/inputs/Password.svelte"

    var login: Login = {
            Type: "",
            Data: null,
            isMaster(): boolean {
                if (this.Type != "user") return false
                const user: User = this.Data as User
                if (user.Id == 1) return true // master
                return false
            },
            containsAnyUserRoles(this: Login, ...roles: UserRole[]): boolean {
                if (this.Type != "user") return false
                const user: User = this.Data as User
                if (user.Id == 1) return true // master
                for (let r of roles) {
                    if ((user.RoleMask & (1 << r)) != 0) return true
                }
                return false
            },
        },
        status = "loading",
        isLoading = false,
        username = "",
        password = ""
    setContext("login", login)
    const i18n: any = getContext("i18n")

    onMount(function () {
        fetch("login/session")
            .then((r) => r.json())
            .then((r) => {
                Object.assign(login, r)
                status = "on"
            })
            .catch(() => (status = "off"))
    })
</script>

{#if status == "on"}
    <slot {login} />
{:else if status == "off"}
    <column style="margin:auto; width: 300px; border: var(--standard-border); border-radius: 5px; padding: 40px 20px; background: white; border-bottom: 5px solid var(--theme-color-a); border-top: 5px solid var(--theme-color-a);">
        <img src="custom-platform/logo-login.png" alt="onswitch-logo" style="margin-top: 10px;" />

        <form
            class="children-spacing"
            on:submit|preventDefault={(e) => {
                isLoading = true
                post("login/sign-in", {
                    username,
                    password,
                }).then((res) => {
                    if (res.status == 200) {
                        Object.assign(login, res.data)
                        status = "on"
                    } else {
                        createToast("error", i18n.translate("toast.invalid-credentials"))
                    }
                    isLoading = false
                })

                return false
            }}
        >
            <label>
                {i18n.translate("login.username-field")}
                <input bind:value={username} />
            </label>
            <Password name={i18n.translate("login.password-field")} bind:value={password} autocomplete />

            <button type="submit" disabled={isLoading} class="primary">{i18n.translate("dict.enter")}</button>
        </form>

        <CountryFlags />

        {#if isLoading}
            <Spinner />
        {/if}
    </column>
{:else}
    <Spinner />
{/if}
