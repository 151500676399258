<script lang="ts">
    import { getContext } from "svelte"
    import { calcProfit, millisFormat, moneyFormat, prettyNumber, safeDivideFloat } from "../../../lib/Utils"
    import type { Login } from "../../../lib/models"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import CcAcModal from "./CcAcModal.svelte"
    import CustomCarriersModal from "./CustomCarriersModal.svelte"
    import HangupCauseModal from "./HangupCauseModal.svelte"
    import PhoneFormatModal from "./PhoneFormatModal.svelte"
    import StatusModal from "./StatusModal.svelte"

    const i18n: any = getContext("i18n"),
        login: Login = getContext("login")

    export var header: string,
        period: number = -1,
        data,
        dict,
        showFinancial: boolean,
        showHangupCause: boolean = false,
        showRealtime: boolean = true,
        onStatFocus: (k: string) => void = null,
        onClick: (any) => void = null

    var viewStatusId = null,
        viewHangupCauseId = null,
        viewAreaCodeId = null,
        viewPhoneFormatId = null,
        viewCarrierId = null
</script>

<!-- Modals -->
{#if viewAreaCodeId !== null}
    {@const stat = data.find((it) => it.id === viewAreaCodeId)}
    {#if stat}
        <CcAcModal
            {period}
            {stat}
            name={viewAreaCodeId in dict ? dict[viewAreaCodeId].Name : viewAreaCodeId}
            onClose={() => {
                if (onStatFocus) onStatFocus(null)
                viewAreaCodeId = null
            }}
        />
    {/if}
{/if}
{#if viewCarrierId !== null}
    {@const stat = data.find((it) => it.id === viewCarrierId)}
    {#if stat}
        <CustomCarriersModal
            {period}
            {stat}
            name={viewCarrierId in dict ? dict[viewCarrierId].Name : viewCarrierId}
            onClose={() => {
                if (onStatFocus) onStatFocus(null)
                viewCarrierId = null
            }}
        />
    {/if}
{/if}
{#if viewPhoneFormatId !== null}
    {@const stat = data.find((it) => it.id === viewPhoneFormatId)}
    {#if stat}
        <PhoneFormatModal
            {period}
            {stat}
            name={viewPhoneFormatId in dict ? dict[viewPhoneFormatId].Name : viewPhoneFormatId}
            onClose={() => {
                if (onStatFocus) onStatFocus(null)
                viewPhoneFormatId = null
            }}
        />
    {/if}
{/if}
{#if viewStatusId !== null}
    {@const stat = data.find((it) => it.id === viewStatusId)}
    {#if stat}
        <StatusModal {period} {stat} name={viewStatusId in dict ? dict[viewStatusId].Name : viewStatusId} onClose={() => (viewStatusId = null)} />
    {/if}
{/if}
{#if viewHangupCauseId !== null}
    {@const stat = data.find((it) => it.id === viewHangupCauseId)}
    {#if stat}
        <HangupCauseModal
            {period}
            {stat}
            name={viewHangupCauseId in dict ? dict[viewHangupCauseId].Name : viewHangupCauseId}
            onClose={() => {
                if (onStatFocus) onStatFocus(null)
                viewHangupCauseId = null
            }}
        />
    {/if}
{/if}

<Table margin="10px 0px 0px 0px">
    <tr slot="head">
        {#if header}
            <th>{header}</th>
        {/if}
        {#if showRealtime}
            <th>CPS <TableSorter /></th>
            <th>Max CPS <TableSorter /></th>
            <th>Active Chan.<TableSorter /></th>
            <th>Max Chan.<TableSorter /></th>
            <th>Chan. Limit<TableSorter /></th>
        {/if}
        <th>Calls <TableSorter /></th>
        <th>Signaled</th>
        <th>ASR <TableSorter /></th>
        <th>Long Calls <TableSorter /></th>
        <th>ACD <TableSorter /></th>
        {#if login.Type == "user"}
            <th title="Offensive PDD">PDD <TableSorter /></th>
        {/if}
        {#if showFinancial}
            {#if login.Type == "user"}
                <th>{i18n.translate("dashboard.sold")} ($) <TableSorter /></th>
                <th>{i18n.translate("dashboard.minutes-sold")} <TableSorter /></th>
            {/if}
            <th>{i18n.translate("dashboard.cost")} ($) <TableSorter /></th>
            <th>{i18n.translate("dashboard.minutes-cost")} <TableSorter /></th>
            {#if login.Type == "user"}
                <th>{i18n.translate("dashboard.profit")} <TableSorter /></th>
            {/if}
        {/if}
        <!-- <th>Errors <TableSorter /></th> -->
        <th>Details</th>
    </tr>
    {#each data as _it}
        {@const it = Array.isArray(_it.Calls) ? Object.fromEntries(Object.keys(_it).map((k) => [k, Array.isArray(_it[k]) ? _it[k][period] : _it[k]])) : _it}
        {@const rec = dict[it.id]}
        <tr>
            {#if header}
                <td>{it.id in dict ? (rec.Techprefix ? `${rec.Name} (${rec.Techprefix})` : rec.Name) : it.id}</td>
            {/if}
            {#if showRealtime}
                <td>{prettyNumber(it.Cps.Value)}</td>
                <td>{prettyNumber(it.Cps.MaxValue)}</td>
                <td>{prettyNumber(it.Channels)}</td>
                <td>{it.MaxChannels ? prettyNumber(it.MaxChannels) : ""}</td>
                <td>{rec && rec.ChannelLimit ? prettyNumber(rec.ChannelLimit) : ""}</td>
            {/if}
            <td>{prettyNumber(it.Calls)}</td>
            <td>{(it.Calls ? ((it.Calls - it.Errors) / it.Calls) * 100 : 0).toFixed(1)}%</td>
            <td>{(it.Calls ? ((it.Status["200"] || 0) / it.Calls) * 100 : 0).toFixed(1) + "%"}</td>
            <td>{(it.Calls ? safeDivideFloat(it.PositiveVoice, it.Calls - it.Errors) * 100 : 0).toFixed(1) + "%"}</td>
            <td>{millisFormat(it.Status["200"] ? it.AnswerDuration / it.Status["200"] : 0)}</td>
            {#if login.Type == "user"}
                <td>{(it.Calls ? (it.OffensivePdd / it.Calls) * 100 : 0).toFixed(1)}%</td>
            {/if}
            {#if showFinancial}
                <td>{moneyFormat(it.BillingA)}</td>
                <td>{(it.BillsecA / 60).toFixed(1)}</td>
                {#if login.Type == "user"}
                    {@const profit = calcProfit(it.BillingA, it.BillingB)}
                    <td>{moneyFormat(it.BillingB)}</td>
                    <td>{(it.BillsecB / 60).toFixed(1)}</td>
                    <td class:error-color={profit < 0} class:primary-color={profit > 0}>{profit.toFixed(1)}%</td>
                {/if}
            {/if}
            <!-- <td>{it.Errors}</td> -->
            <td>
                {#if onClick}
                    <a href="#techs" title="See charts and more" on:click|preventDefault={() => onClick(it.id)}><i class="fa-solid fa-eye" /></a>
                {/if}
                <a
                    href="#ac"
                    title="Country and area codes"
                    on:click|preventDefault={() => {
                        if (onStatFocus) onStatFocus(it.key)
                        viewAreaCodeId = it.id
                    }}>AC</a
                >
                <a
                    href="#phone-number-formats"
                    title="Phone number formats"
                    on:click|preventDefault={() => {
                        if (onStatFocus) onStatFocus(it.key)
                        viewPhoneFormatId = it.id
                    }}><i class="fa-solid fa-mobile-screen" /></a
                >
                <a
                    href="#carriers"
                    title="Carriers"
                    on:click|preventDefault={() => {
                        if (onStatFocus) onStatFocus(it.key)
                        viewCarrierId = it.id
                    }}><i class="fa-solid fa-sim-card" /></a
                >
                <a
                    href="#status"
                    title="SIP Status"
                    on:click|preventDefault={() => {
                        viewStatusId = it.id
                    }}><i class="fa-solid fa-traffic-light" /></a
                >
                {#if showHangupCause}
                    <a
                        href="#hcause"
                        title="Hangup cause"
                        on:click|preventDefault={() => {
                            if (onStatFocus) onStatFocus(it.key)
                            viewHangupCauseId = it.id
                        }}><i class="fa-solid fa-phone-slash" /></a
                    >
                {/if}
            </td>
        </tr>
    {/each}
</Table>
