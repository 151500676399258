<script lang="ts">
    import { getContext, setContext } from "svelte"
    import { createToast } from "../../lib/Toast"
    import { post } from "../../lib/Utils"
    import Spinner from "../Spinner.svelte"

    var component = null,
        props = {},
        i18n: any = getContext("i18n")

    setContext("modal", {
        async post(path: string, data: any) {
            if (component) return
            component = Spinner
            const res = await post(path, data)
            if (res.status == 200) {
                createToast("success", i18n.translate("post.success"))
            } else {
                if (typeof res.data == "string") {
                    createToast("error", res.data)
                } else {
                    createToast("error", i18n.translate("post.error"))
                }
            }
            component = null

            return res
        },
        async upload(path: string, data: FormData) {
            if (component) return
            component = Spinner
            const res = await fetch(path, {
                method: "POST",
                body: data,
            })
            if (res.status == 200) {
                createToast("success", i18n.translate("post.success"))
            } else {
                var _data
                try {
                    _data = await res.json()
                } catch (err) {}
                if (_data && typeof _data == "string") {
                    createToast("error", _data)
                } else {
                    createToast("error", i18n.translate("post.error"))
                }
            }
            component = null
            return res
        },
        async get(path: string) {
            if (component) return
            component = Spinner
            return new Promise((resolver, rej) => {
                fetch(path)
                    .then((r) => r.json())
                    .catch((err) => {
                        createToast("error", i18n.translate("post.error"))
                        component = null
                        rej(err)
                    })
                    .then((r) => {
                        component = null
                        resolver(r)
                    })
            })
        },
    })
</script>

{#if component}
    <column style="width: 100%; height: 100%; z-index: 10; position:fixed; background-color:rgba(0,0,0,0.25);">
        <svelte:component this={component} />
    </column>
{/if}
<slot />
