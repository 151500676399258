<script>
    import { getContext } from "svelte"
    import Loader from "../../../../lib/Loader.svelte"
    import { abbreviateBytes } from "../../../../lib/Utils"

    const modal = getContext("modal")
    var loader, compilationInfo

    function compileNsapn() {
        modal.post("telephony-system/abr/nsapn/compile").then((r) => {
            compilationInfo = r.data
            loader.reload()
        })
    }

    function compileBdo() {
        modal.post("telephony-system/abr/bdo/compile").then((r) => {
            compilationInfo = r.data
            loader.reload()
        })
    }

    function formatFile(f) {
        return f.Name ? `${f.Name} (${abbreviateBytes(f.Size)}) <i class="fa-solid fa-check success-color"></i>` : `Not found <i class="fa-solid fa-xmark error-color"></i>`
    }
</script>

<column style="padding: 10px; max-width: 500px;">
    <Loader endpoint="telephony-system/abr/files" let:data bind:this={loader}>
        <h1>Source Files</h1>
        <hr />
        <ul>
            <li>CADUP SMP: {@html formatFile(data.Source.CadupSmp)}</li>
            <li>CADUP STFC: {@html formatFile(data.Source.CadupStfc)}</li>
            <li>CADUP ONLINE: {@html formatFile(data.Source.CadupExt)}</li>
            <li>RN1 SMP: {@html formatFile(data.Source.Rn1Smp)}</li>
            <li>RN1 STFC: {@html formatFile(data.Source.Rn1Stfc)}</li>
            <li>Anexo 5: {@html formatFile(data.Source.Anexo5)}</li>
            <li>BDO: {@html formatFile(data.Source.Bdo)}</li>
        </ul>
        <h1>Built Files</h1>
        <hr />
        <ul>
            <li>
                nSAPN: {@html formatFile(data.Built.Nsapn)}
                {#if data.Built.Nsapn.Name}
                    <a href="telephony-system/abr/carriers"><i class="fa-solid fa-download" /> Carrier List</a>
                {/if}
            </li>
            <li>BDO SMP: {@html formatFile(data.Built.BdoSmp)}</li>
            <li>BDO STFC: {@html formatFile(data.Built.BdoStfc)}</li>
        </ul>

        {#if compilationInfo}
            <pre>{compilationInfo}</pre>
        {/if}

        <row class="responsive">
            <expander />
            <button on:click={() => loader.reload()}>Refresh</button>

            <space />
            <button on:click={() => compileNsapn()} class="primary">{data.Built.Nsapn.Name ? "Recompile" : "Compile"} nSAPN</button>

            <space />
            <button on:click={() => compileBdo()} class="primary">{data.Built.BdoSmp.Name || data.Built.BdoStfc.Name ? "Recompile" : "Compile"} BDO</button>
        </row>
    </Loader>
</column>
