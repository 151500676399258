<script lang="ts">
    export var onClose: () => void,
        bodyPadding = "10px 20px"
</script>

<column
    on:click={(e) => {
        if (e.currentTarget == e.target && onClose) {
            onClose()
            e.preventDefault()
        }
    }}
    style="width: 100%; height: 100%; z-index: 9; left: 0px; top: 0px; position:fixed; background-color:rgba(0,0,0,0.25);"
>
    <column style="min-width: 400px; min-height: 50%; margin: auto;">
        <row style="background-image: linear-gradient(#f0f0f0, #d7d7d7); padding: 3px 10px; border-top-left-radius: 5px; border-top-right-radius: 5px; border: var(--standard-border);">
            <slot name="title" />
            <expander />
            <a href="#close" on:click|preventDefault={() => onClose && onClose()}>
                <i class="fa-solid fa-xmark" />
            </a>
        </row>

        <column class="expanded" style="padding: {bodyPadding}; background-color: whitesmoke; border: var(--standard-border); border-top: none; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
            <slot />
        </column>
    </column>
</column>
