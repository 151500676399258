<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../lib/Loader.svelte"
    import { Lcr, LcrPriority } from "../../lib/golang-models/maindb"
    import Form from "../../widgets/Form.svelte"
    import Table from "../../widgets/Table.svelte"
    import TableSorter from "../../widgets/TableSorter.svelte"
    import Page from "../elements/Page.svelte"
    import PageFooter from "../elements/PageFooter.svelte"
    import LcrPriorities from "./commons/LcrPriorities.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: Lcr = null,
        prioritiesByPhoneFormatId: Record<any, LcrPriority[][]> = {}
</script>

<Page>
    <Loader endpoint="lcr/list" let:data let:reload>
        {#if formMode == "none"}
            <!-- List -->
            <Table>
                <tr slot="head">
                    <th>ID <TableSorter /></th>
                    <th>{i18n.translate("dict.name")} <TableSorter /></th>
                    <th>{i18n.translate("dict.actions")}</th>
                </tr>

                {#each data as record}
                    <tr>
                        <td>{record.Id}</td>
                        <td
                            ><a
                                href="#edit"
                                on:click|preventDefault={() => {
                                    formRecord = { ...record }
                                    formMode = "edit"
                                    prioritiesByPhoneFormatId = null
                                }}>{record.Name}</a
                            ></td
                        >
                        <td>
                            <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("lcr/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                        </td>
                    </tr>
                {/each}
            </Table>

            <PageFooter>
                <expander />
                <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
                <button
                    on:click={() => {
                        formRecord = new Lcr()
                        formMode = "add"
                        prioritiesByPhoneFormatId = {}
                    }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} LCR</button
                >
            </PageFooter>
        {:else}
            <Form
                expanded
                submit={() =>
                    modal
                        .post(`lcr/save`, {
                            Lcr: formRecord,
                            Priorities: prioritiesByPhoneFormatId,
                        })
                        .then((res) => {
                            if (res.status != 200) return
                            formMode = "none"
                            reload()
                        })}
            >
                <svelte:fragment slot="title">{i18n.translate("action." + formMode)} LCR</svelte:fragment>

                {#if formMode == "edit"}
                    <label>
                        ID
                        <input disabled value={formRecord.Id} />
                    </label>
                {/if}
                <label>
                    {i18n.translate("dict.name")}*
                    <input bind:value={formRecord.Name} required />
                </label>

                <LcrPriorities bind:prioritiesByPhoneFormatId lcr={formRecord} />

                <svelte:fragment slot="footer">
                    <PageFooter>
                        <expander />
                        <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                        <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                    </PageFooter>
                </svelte:fragment>
            </Form>
        {/if}
    </Loader>
</Page>
