<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { ClientTechprefix, PhoneNumberGate, ProviderTechprefix, Tariff } from "../../../lib/golang-models/maindb"
    import Table from "../../../widgets/Table.svelte"
    import Multiselect from "../../../widgets/inputs/Multiselect.svelte"

    const i18n: any = getContext("i18n")

    export var record: Tariff | ClientTechprefix | ProviderTechprefix, label: string, target: "tech-a" | "tech-b" | "tariff", value: { Gate: PhoneNumberGate; Price?: number; Behavior?: string; Id: string }[]

    var seq = 0
    const forTech = target == "tech-a" || target == "tech-b"
    const required = forTech
    var newIds = new Set()

    function makeId() {
        return Date.now() + "-" + Math.floor(Math.random() * 1000) + "-" + seq++
    }
    function newRule(mode = "none") {
        let id = makeId()
        newIds.add(id)
        if (target == "tariff") return { Gate: { Mode: mode, Matches: [], Carriers: [] }, Price: 0, Id: id }
        else if (forTech) return { Gate: { Mode: mode, Matches: [], Carriers: [] }, Id: id }
        newIds = (() => newIds)()
    }

    if (required && (!value || value.length == 0)) value = [newRule()]
    else value.forEach((it) => (it.Id = it.Id || makeId()))
</script>

<!-- TODO: translate -->
{#if value && value.length > 0}
    <label for="" style="margin-bottom: -8px;">{label}</label>
{/if}
<Loader endpoint="carrier/custom/list" let:data={carriers}>
    {#if value && value.length > 0}
        <Table margin="10px 0px 0px 0px" scroll={false}>
            <tr slot="head">
                <th style="width: 20px;"><i class="fa-solid fa-arrow-down-wide-short" /> {i18n.translate("dict.priority")}</th>
                <th>Filter Type</th>
                <th>Filter Matches</th>
                <th>{i18n.translate("dict.carriers")}</th>
                {#if target == "tariff"}
                    <th>{i18n.translate("financial.custom-price")}</th>
                {:else if forTech}
                    <th>{i18n.translate("dict.behavior")}</th>
                {/if}
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each value as rule, i (rule.Id)}
                <tr>
                    <td>{i + 1}°</td>
                    <td>
                        <select
                            bind:value={rule.Gate.Mode}
                            on:change={() => {
                                rule.Gate.Matches = []
                                newIds.add(rule.Id)
                                newIds = (() => newIds)()
                            }}
                        >
                            <option value="none">{i18n.translate("dict.none")}</option>
                            <option value="ac-prefix">{i18n.translate("dict.ac-prefix")}</option>
                            <option value="destination">{i18n.translate("dict.destination-phone")}</option>
                            <optgroup label="ABR Telecom">
                                <option value="abr:region">{i18n.translate("dict.region")}</option>
                                <option value="abr:al">AL</option>
                                <option value="abr:cnl">CNL</option>
                                <option value="abr:rules-file">{i18n.translate("abr:rules-file")}</option>
                                <!-- <option value="abr:eot">EOT</option> -->
                            </optgroup>
                        </select>
                    </td>
                    <td>
                        {#if rule.Gate.Mode == "abr:rules-file"}
                            {#if newIds.has(rule.Id)}
                                <input required type="file" name={"abr:rules-" + rule.Id} class="rules-file" />
                            {:else}
                                <row>
                                    <a href="phone-number-rules/{target}/download/{record.Id}/{rule.Id}" target="_blank"><i class="fa-solid fa-download" /></a>
                                    <space />
                                    <a
                                        href="#change"
                                        on:click|preventDefault={() => {
                                            newIds.add(rule.Id)
                                            newIds = (() => newIds)()
                                        }}><i class="fa-regular fa-trash-can" /></a
                                    >
                                </row>
                            {/if}
                        {:else if rule.Gate.Mode == "abr:region"}
                            <select value={(rule.Gate.Matches && rule.Gate.Matches[0]) || "0"} on:change={(e) => (rule.Gate.Matches = [e.currentTarget.value])} required>
                                <option value="1">Região I</option>
                                <option value="2">Região II</option>
                                <option value="3">Região III</option>
                            </select>
                        {:else if rule.Gate.Mode != "none"}
                            <input type="text" value={(rule.Gate.Matches || []).join(", ")} on:change={(e) => (rule.Gate.Matches = e.currentTarget.value.split(",").map((it) => it.trim()))} maxlength="300" placeholder="Separate by comma" required />
                        {:else}
                            Any destination
                        {/if}
                    </td>
                    <td>
                        {#if rule.Gate.Mode != "abr:rules-file"}
                            <Multiselect placeholder="Any carrier" options={carriers.map((carrier) => ({ label: carrier.Name, value: carrier.Id }))} bind:value={rule.Gate.Carriers} />
                        {/if}
                    </td>
                    {#if target == "tariff"}
                        <td><input type="number" bind:value={rule.Price} min="0.0" step="0.0000001" required /></td>
                    {:else if forTech}
                        <td>
                            <select bind:value={rule.Behavior} required>
                                <option value="accept">{i18n.translate("action.accept")}</option>
                                <option value="deny">{i18n.translate("action.deny")}</option>
                            </select>
                        </td>
                    {/if}
                    <td>
                        {#if !required || value.length > 1}
                            <a
                                href="#remove-rule"
                                on:click|preventDefault={() => {
                                    value = value.filter((it) => it != rule)
                                }}><i class="fa-solid fa-trash" /></a
                            >
                        {/if}
                        {#if i > 0}
                            <a
                                href="#up"
                                on:click|preventDefault={() => {
                                    value[i] = value[i - 1]
                                    value[i - 1] = rule
                                }}><i class="fa-solid fa-caret-up" /></a
                            >
                        {/if}
                        {#if i < value.length - 1}
                            <a
                                href="#down"
                                on:click|preventDefault={() => {
                                    value[i] = value[i + 1]
                                    value[i + 1] = rule
                                }}><i class="fa-solid fa-caret-down" /></a
                            >
                        {/if}
                        <a
                            href="#add"
                            title="{i18n.translate('action.add')} {target == 'tariff' ? i18n.translate('financial.custom-price') : i18n.translate('dict.rule')}"
                            on:click|preventDefault={() => {
                                value = [...value.slice(0, i), newRule(), ...value.slice(i)]
                            }}><i class="fa-solid fa-plus" /></a
                        >
                    </td>
                </tr>
            {/each}
        </Table>
        <small>*In ascending order, the first rule that matches will be used.</small>
    {:else}
        <button
            type="button"
            on:click={() => {
                if (!value) value = []
                value = [newRule("ac-prefix")]
            }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {target == "tariff" ? i18n.translate("financial.custom-price") : i18n.translate("dict.rule")}</button
        >
        <space />
    {/if}
</Loader>
