<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { SearchQuery } from "../../../lib/golang-models/cdr"
    import type { Carrier, Client, ClientTechprefix, PhoneNumberFormat, Provider } from "../../../lib/golang-models/maindb"
    import type { ISearch } from "./interfaces"

    export var query: SearchQuery,
        search: ISearch = null

    const phoneFormats: PhoneNumberFormat[] = getContext("phone-formats"),
        carriers: Carrier[] = getContext("custom-carriers"),
        i18n: any = getContext("i18n"),
        login: any = getContext("login")

    var carrierMode = "all"

    var clients: Client[] = null,
        providers: Provider[] = null,
        techsA: ClientTechprefix[] = null,
        techsB: ClientTechprefix[] = null
</script>

<double-space />
<column class="form-grid box">
    {#if search && search.request.SearchMode == "chart"}
        <row>
            <label>
                Name
                <input type="text" bind:value={query.Name} />
            </label>
        </row>
        <space />
    {/if}

    {#if login.Type == "user"}<b>Phone Number</b>{/if}
    <row>
        <label>
            Destination
            <input type="text" placeholder="Phone number or regex, example: ^55(\d+)1223$" bind:value={query.Destination} />
        </label>
        <label>
            Destination Format
            <select bind:value={query.DestinationFormatId}>
                <option value="">All formats</option>
                {#each phoneFormats as it}
                    <option value={it.Id.toString()}>{it.Name}</option>
                {/each}
            </select>
        </label>
    </row>
    <row>
        <label>
            Caller-ID
            <input type="text" placeholder="Phone number or regex, example: ^55(\d+)1223$" bind:value={query.CallerId} />
        </label>
        <label>
            From
            <input type="text" placeholder="Phone number or regex, example: ^55(\d+)1223$" bind:value={query.From} />
        </label>
    </row>
</column>
{#if login.Type == "user"}
    <double-space />
    <column class="form-grid box">
        <b>Client</b>
        <Loader endpoint="client/list" let:data={clients}>
            <row>
                <label>
                    Client ID
                    <input type="text" bind:value={query.ClientId} list="client-datalist" on:change={() => (query.TechIdA = "")} />
                    <datalist id="client-datalist">
                        {#each clients as client}
                            <option value={client.Id.toString()}>{client.Name}</option>
                        {/each}
                    </datalist>
                </label>
                <label>
                    Status
                    <row>
                        <select
                            value={query.StatusA != "" && query.StatusA != "200" && query.StatusA != "^[^2]\\d+$" ? "custom" : query.StatusA}
                            on:change={(e) => {
                                if (e.currentTarget.value == "custom") query.StatusA = "503"
                                else query.StatusA = e.currentTarget.value
                            }}
                        >
                            <option value="">{i18n.translate("dict.all")}</option>
                            <option value="200">{i18n.translate("dashboard.answered")}</option>
                            <option value="^[^2]\d+$">{i18n.translate("dashboard.no-answered")}</option>
                            <option value="custom">{i18n.translate("dict.custom")}</option>
                        </select>
                        <input type="text" bind:value={query.StatusA} />
                    </row>
                </label>
            </row>
            <row>
                <label>
                    Techprefix ID
                    <input type="text" disabled={query.ClientId == ""} bind:value={query.TechIdA} list="tech-a-datalist" />
                    {#if query.ClientId}
                        {#key query.ClientId}
                            <Loader endpoint="client/techprefix/list/{query.ClientId}" let:data={techsA}>
                                <datalist id="tech-a-datalist">
                                    {#each techsA as tech}
                                        <option value={tech.Id.toString()}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                                    {/each}
                                </datalist>
                            </Loader>
                        {/key}
                    {/if}
                </label>
                <label>
                    IP
                    <input type="text" bind:value={query.IpA} />
                </label>
            </row>
        </Loader>
    </column>
    <double-space />
    <column class="form-grid box">
        <b>Provider</b>
        <Loader endpoint="provider/list" let:data={providers}>
            <label class="checkbox">
                <input type="checkbox" autocomplete="off" bind:checked={query.OnlyProviderErrors} />
                Unsignaled
            </label>
            <row>
                <label>
                    Provider ID
                    <input type="text" bind:value={query.ProviderId} list="provider-datalist" on:change={() => (query.TechIdB = "")} />
                    <datalist id="provider-datalist">
                        {#each providers as provider}
                            <option value={provider.Id.toString()}>{provider.Name}</option>
                        {/each}
                    </datalist>
                </label>
                <label>
                    Status
                    <row>
                        <select
                            value={query.StatusB != "" && query.StatusB != "200" && query.StatusB != "^[^2]\\d+$" ? "custom" : query.StatusB}
                            on:change={(e) => {
                                if (e.currentTarget.value == "custom") query.StatusB = "503"
                                else query.StatusB = e.currentTarget.value
                            }}
                        >
                            <option value="">{i18n.translate("dict.all")}</option>
                            {#if !query.OnlyProviderErrors}
                                <option value="200">{i18n.translate("dashboard.answered")}</option>
                                <option value="^[^2]\d+$">{i18n.translate("dashboard.no-answered")}</option>
                            {/if}
                            <option value="custom">{i18n.translate("dict.custom")}</option>
                        </select>
                        <input type="text" bind:value={query.StatusB} />
                    </row>
                </label>
            </row>
            <row>
                <label>
                    Techprefix ID
                    <input type="text" disabled={query.ProviderId == ""} bind:value={query.TechIdB} list="tech-b-datalist" />
                    {#if query.ProviderId}
                        {#key query.ProviderId}
                            <Loader endpoint="provider/techprefix/list/{query.ProviderId}" let:data={techsB}>
                                <datalist id="tech-b-datalist">
                                    {#each techsB as tech}
                                        <option value={tech.Id.toString()}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                                    {/each}
                                </datalist>
                            </Loader>
                        {/key}
                    {/if}
                </label>
                <label>
                    IP
                    <input type="text" disabled={query.OnlyProviderErrors} bind:value={query.IpB} />
                </label>
            </row>
        </Loader>
    </column>
    {@const hangupCauses = Object.entries(i18n.dict()).filter((it) => it[0].includes("telephony.hangup-cause."))}
    <double-space />
    <column class="form-grid box">
        <b>More</b>
        <row>
            <label>
                Carrier Filter
                <select bind:value={carrierMode} on:change={() => (query.Carrier = "")}>
                    <option value="all">All carriers</option>
                    <option value="custom">Custom</option>
                    <option value="abr">ABR Telecom (pesquisa por base do CNPJ)</option>
                </select>
            </label>
            {#if carrierMode == "custom"}
                <label>
                    Custom Carrier*
                    <select bind:value={query.Carrier} required>
                        {#each carriers as it}
                            <option value={it.Id.toString()}>{it.Name}</option>
                        {/each}
                    </select>
                </label>
            {:else if carrierMode == "abr"}
                <label>
                    Base CNPJ*
                    <input type="text" value={query.Carrier.split(":")[1] || ""} on:change={(e) => (query.Carrier = "abr:" + e.currentTarget.value)} required />
                </label>
            {/if}
        </row>

        <row>
            <label>
                Hangup Cause
                <select bind:value={query.HangupCause}>
                    <option value="">{i18n.translate("dict.all")}</option>
                    {#each hangupCauses as hc, i}
                        <option value={i.toString()}>{hc[1]}</option>
                    {/each}
                </select>
            </label>
            <label>
                Call-ID
                <input type="text" bind:value={query.CallId} />
            </label>
        </row>
    </column>
{:else if login.Type == "client"}
    <row>
        <label>
            Client
            <input type="text" bind:value={login.Data.Name} readonly />
        </label>
        <label>
            Status
            <row>
                <select
                    value={query.StatusA != "" && query.StatusA != "200" && query.StatusA != "^[^2]\\d+$" ? "custom" : query.StatusA}
                    on:change={(e) => {
                        if (e.currentTarget.value == "custom") query.StatusA = "503"
                        else query.StatusA = e.currentTarget.value
                    }}
                >
                    <option value="">{i18n.translate("dict.all")}</option>
                    <option value="200">{i18n.translate("dashboard.answered")}</option>
                    <option value="^[^2]\d+$">{i18n.translate("dashboard.no-answered")}</option>
                    <option value="custom">{i18n.translate("dict.custom")}</option>
                </select>
                <input type="text" bind:value={query.StatusA} />
            </row>
        </label>
    </row>
    <row>
        <label>
            Techprefix ID
            <input type="text" bind:value={query.TechIdA} list="tech-a-datalist" />
            <Loader endpoint="client/techprefixes" let:data={techsA}>
                <datalist id="tech-a-datalist">
                    {#each techsA as tech}
                        <option value={tech.Id.toString()}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                    {/each}
                </datalist>
            </Loader>
        </label>
        <label>
            IP
            <input type="text" bind:value={query.IpA} />
        </label>
    </row>
{/if}

<style>
    .form-grid.box {
        padding: 10px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        box-shadow: 3px 4px 5px #00000018;
        background: #f7f7f7;
    }
</style>
