<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { Classifier } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Thumb from "../../../widgets/Thumb.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: Classifier = null
</script>

<Loader endpoint="classifier/list" let:data let:reload>
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>{i18n.translate("field.active")} </th>
            </tr>

            {#each data as record}
                <tr>
                    <td>{record.Id}</td>
                    <td
                        ><a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}>{record.Name}</a
                        ></td
                    >
                    <td><Thumb value={record.IsEnabled} /></td>
                    <td>
                        <a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}><i class="fa-solid fa-pencil" /></a
                        >
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("classifier/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new Classifier()
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("dict.classifier")}</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={() =>
                modal.post(`classifier/save`, formRecord).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)} {i18n.translate("dict.classifier")}</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}

            <label>
                {i18n.translate("dict.name")}*
                <input type="text" bind:value={formRecord.Name} required />
            </label>

            <label class="checkbox">
                <input type="checkbox" autocomplete="off" bind:checked={formRecord.CheckBeforeAnswer} />
                Pre attendance analysis
            </label>

            <label class="checkbox">
                <input type="checkbox" autocomplete="off" disabled={!formRecord.CheckBeforeAnswer} bind:checked={formRecord.PreMessageHangup} />
                Hang up when detect message (before attendance)
            </label>

            <label class="checkbox">
                <input type="checkbox" autocomplete="off" bind:checked={formRecord.CheckAfterAnswer} />
                Post attendance analysis
            </label>

            <label class="checkbox">
                <input type="checkbox" autocomplete="off" disabled={!formRecord.CheckAfterAnswer} bind:checked={formRecord.NoVoiceDetectedHangup} />
                Hang up when no voice is detected (after attendance)
            </label>

            <label>
                Minimum greeting duration (milliseconds)
                <input type="number" min="10" max="5000" disabled={!formRecord.CheckAfterAnswer} bind:value={formRecord.GreetingDuration} required />
            </label>

            <label>
                Greeting timeout (milliseconds)
                <input type="number" min={formRecord.GreetingDuration} max="6000" disabled={!formRecord.CheckAfterAnswer} bind:value={formRecord.GreetingTimeout} required />
            </label>

            <label>
                Fade of detection (milliseconds)
                <input type="number" min="10" max="1000" bind:value={formRecord.SpeechMarginTime} required />
            </label>

            <label>
                Minimum sound duration for smart detection (milliseconds)
                <input type="number" min="100" max="3000" bind:value={formRecord.ConfirmSpeechTime} required />
            </label>

            <label>
                Hang up Status
                <input type="number" min="400" max="699" bind:value={formRecord.HangupStatus} required />
            </label>

            <label class="checkbox">
                <input type="checkbox" autocomplete="off" bind:checked={formRecord.IsEnabled} />
                {i18n.translate("field.active")}
            </label>

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
