<script>
    import { getContext } from "svelte"
    import Loader from "../../lib/Loader.svelte"
    import Table from "../../widgets/Table.svelte"

    import Page from "../elements/Page.svelte"
    import PageFooter from "../elements/PageFooter.svelte"

    const i18n = getContext("i18n")
</script>

<Page>
    <Loader endpoint="activity-log/list" let:data let:reload>
        <Table>
            <tr slot="head">
                <th>Date</th>
                <th>Client ID</th>
                <th>User ID</th>
                <th>URL</th>
                <th>Description</th>
            </tr>

            {#each data as row}
                <tr>
                    <td>{new Date(row.Created * 1000).toLocaleDateString(i18n.translate("date-format"), { second: "2-digit", hour: "2-digit", minute: "2-digit" })}</td>
                    <td>{row.ClientId.Int64 || ""}</td>
                    <td>{row.UserId.Int64 || ""}</td>
                    <td>{row.URL}</td>
                    <td>{row.Description}</td>
                </tr>
            {/each}
        </Table>
        <PageFooter>
            <!-- TODO: input de data e traduzir tabela -->
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
        </PageFooter>
    </Loader>
</Page>
