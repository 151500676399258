<script lang="ts">
    import { getContext } from "svelte"
    import Context from "../../lib/Context.svelte"
    import Loader from "../../lib/Loader.svelte"
    import { createToast } from "../../lib/Toast"
    import { dateFormatDMYTime, dateTimeFormat } from "../../lib/Utils"
    import { SearchQuery, SearchRequest } from "../../lib/golang-models/cdr"
    import Form from "../../widgets/Form.svelte"
    import Table from "../../widgets/Table.svelte"
    import TableSorter from "../../widgets/TableSorter.svelte"
    import Thumb from "../../widgets/Thumb.svelte"
    import DateTimeRange from "../../widgets/inputs/DateTimeRange.svelte"
    import Dialog from "../../widgets/modals/Dialog.svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import PageFooter from "../elements/PageFooter.svelte"
    import Query from "./calls-extract/Query.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal"),
        login: any = getContext("login")
    var query = new SearchQuery(),
        tab = "new",
        dateMode = "today",
        listLoader,
        downloadDialog = {
            info: null,
        }
    var request = new SearchRequest({
        Queries: [query],
        StartDate: dateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 1)),
        EndDate: dateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)),
    })

    var checkboxFields = {
        client: "Client",
        signaledProvider: "Signaled provider",
        othersB: "Unsignaled Providers",

        carrier: "Carrier",
        startTime: "Start time",
        callId: "Call-ID",
        destinationFormatId: "Destination format",
        destination: "Destination number",
        fromUser: "From",
        cc: "Country code",
        ac: "Area code",
        cid: "Caller-ID",

        hangupCause: "Hangup Cause",
        hangupAgent: "Hangup Agent",

        totalDuration: "Total Duration",

        //carrierId: "Carrier", TODO: carrier
        //"flags",
        //"siptraceOffset",
    }

    var fieldsPresets = [
            {
                label: "Full",
                fields: new Set(Object.keys(checkboxFields)),
            },
            {
                label: "Client",
                fields: new Set(Object.keys(checkboxFields).filter((k) => k != "signaledProvider" && k != "othersB" && k != "callId" && k != "hangupCause")),
            },
            {
                label: "Provider",
                fields: new Set(Object.keys(checkboxFields).filter((k) => k != "client" && k != "callId" && k != "hangupCause")),
            },
        ],
        selectedFieldsPreset = 0
    var includeFields = fieldsPresets[selectedFieldsPreset].fields
</script>

{#if downloadDialog.info}
    <Dialog bodyPadding="0px" onClose={() => (downloadDialog.info = null)}>
        <svelte:fragment slot="title">Download Files</svelte:fragment>
        <column style="margin: 10px auto; gap: 10px;">
            {#each new Array(downloadDialog.info.Files).fill(0) as _, i}
                <a title="Download" href="cdr/export/download/{downloadDialog.info.Dir}/{i + 1}.csv" target="_blank"><i class="fa-solid fa-download" /> CDR Part {i + 1}</a>
            {/each}
        </column>
    </Dialog>
{/if}

<Page>
    <Tabs bind:selected={tab}>
        <svelte:fragment slot="tabs">
            <a href="#new">New Export</a>
            <a href="#list">Export List</a>
        </svelte:fragment>

        <TabContent name="new">
            <Loader endpoint={["phone-number-format/list", "carrier/custom/list"]} let:data={deps}>
                <Context name="phone-formats" value={deps[0]}>
                    <Context name="custom-carriers" value={deps[1]}>
                        <Form
                            expanded
                            box={false}
                            submit={() => {
                                if (new Date(request.EndDate).getTime() - new Date(request.StartDate).getTime() > 1440 * 60000 * 31) {
                                    createToast("error", i18n.translate("field.date-range-over", { days: 31 }))
                                    return
                                }
                                if (new Date(request.EndDate).getTime() < new Date(request.StartDate).getTime()) {
                                    createToast("error", i18n.translate("field.date-range-error"))
                                    return
                                }

                                request.ExportFields = Array.from(includeFields)
                                modal.post(`cdr/export/new`, request).then((r) => {
                                    if (r.status == 200) {
                                        tab = "list"
                                        setTimeout(() => {
                                            if (listLoader) listLoader.reload()
                                        }, 1000)
                                    }
                                })
                            }}
                        >
                            <column class="form-grid expanded">
                                <row>
                                    <label>
                                        Search Name*
                                        <input type="text" bind:value={request.SearchName} required />
                                    </label>
                                    <label>
                                        Visibility
                                        <select bind:value={request.UserId}>
                                            <option value={login.Data.Id}>Private</option>
                                            <option value={0}>Other users can see</option>
                                        </select>
                                    </label>
                                </row>
                                <DateTimeRange bind:mode={dateMode} bind:start={request.StartDate} bind:end={request.EndDate} />

                                <Tabs selected="query">
                                    <svelte:fragment slot="tabs">
                                        <a href="#query">Filters</a>
                                        <a href="#fields">Fields</a>
                                    </svelte:fragment>
                                    <TabContent name="query">
                                        <Query {query} />
                                    </TabContent>
                                    <TabContent name="fields">
                                        <column class="form-grid expanded" style="margin: 10px;">
                                            <label class="checkbox">
                                                <input type="checkbox" autocomplete="off" bind:checked={request.ExportNames} />
                                                Export names (high processing consumption)
                                            </label>

                                            <row>
                                                <label>
                                                    Preset
                                                    <select
                                                        bind:value={selectedFieldsPreset}
                                                        on:change={(e) => {
                                                            includeFields = fieldsPresets[e.currentTarget.value].fields
                                                        }}
                                                    >
                                                        <option disabled value={-1}>Custom</option>
                                                        {#each fieldsPresets as p, i}
                                                            <option value={i}>{p.label}</option>
                                                        {/each}
                                                    </select>
                                                </label>
                                            </row>

                                            {#key selectedFieldsPreset}
                                                {#each Object.keys(checkboxFields) as k (k)}
                                                    <label class="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            autocomplete="off"
                                                            checked={includeFields.has(k)}
                                                            on:change={(e) => {
                                                                if (e.currentTarget.checked) includeFields = new Set([...Array.from(includeFields), k])
                                                                else includeFields = new Set(Array.from(includeFields).filter((it) => it != k))
                                                                selectedFieldsPreset = -1
                                                            }}
                                                        />
                                                        {checkboxFields[k]}
                                                    </label>
                                                {/each}
                                            {/key}
                                        </column>
                                    </TabContent>
                                </Tabs>
                            </column>

                            <svelte:fragment slot="footer">
                                <PageFooter>
                                    <expander />
                                    <button class="primary"><i class="fa-solid fa-search" /> {i18n.translate("action.export-search")}</button>
                                </PageFooter>
                            </svelte:fragment>
                        </Form>
                    </Context>
                </Context>
            </Loader>
        </TabContent>

        <TabContent name="list">
            <Loader endpoint="cdr/export/list" let:data let:reload bind:this={listLoader}>
                <column class="expanded">
                    <Table>
                        <tr slot="head">
                            <th>{i18n.translate("dict.date")} <TableSorter /></th>
                            <th>{i18n.translate("dict.name")}</th>
                            <th>{i18n.translate("dict.files")}</th>
                            <th>Status</th>
                            <th>{i18n.translate("dict.actions")}</th>
                        </tr>

                        {#each data.sort((a, b) => (new Date(a.Created).getTime() < new Date(b.Created).getTime() ? 1 : -1)) as inf (inf.Dir)}
                            <tr>
                                <td data-sort={new Date(inf.Created).getTime()}>{dateFormatDMYTime(new Date(inf.Created))}</td>
                                <td>{inf.Name}</td>
                                <td>{inf.Files}</td>
                                <td class:error-color={inf.IsCanceled}>
                                    {#if inf.IsCanceled}
                                        {i18n.translate("dict.canceled")}
                                    {:else if inf.IsCompleted}
                                        <Thumb value={true} />
                                    {:else}
                                        {i18n.translate("dict.running").toLowerCase()}...
                                    {/if}
                                </td>
                                <td>
                                    {#if !inf.IsCompleted}
                                        <a
                                            title="Cancel"
                                            href="#cancel"
                                            class="error-color"
                                            on:click|preventDefault={() => {
                                                modal.post("cdr/export/cancel").then(() => {
                                                    setTimeout(() => reload(), 1000)
                                                })
                                            }}><i class="fa-solid fa-circle-xmark" /></a
                                        >
                                    {/if}
                                    {#if inf.Files == 1}
                                        <a title="Download" href="cdr/export/download/{inf.Dir}/1.csv" target="_blank"><i class="fa-solid fa-download" /></a>
                                    {:else if inf.Files > 1}
                                        <a
                                            title="Download"
                                            href="#download"
                                            on:click|preventDefault={() => {
                                                downloadDialog.info = inf
                                            }}><i class="fa-solid fa-download" /></a
                                        >
                                    {/if}
                                </td>
                            </tr>
                        {/each}
                    </Table>
                </column>

                <PageFooter>
                    <expander />
                    <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
                </PageFooter>
            </Loader>
        </TabContent>
    </Tabs>
</Page>
