<script lang="ts">
    export var title: string,
        margin: string = "0px"
</script>

<row style="margin: {margin};">
    <slot name="left" />
    <b>{title}</b>
    <expander style="margin:auto;" />
    <slot name="right" />
</row>

<style>
    row {
        margin: auto 0px;
        padding: 8px 10px;
        /* background: linear-gradient(#d7d7d7 0%, #fff 10%, #ececec 55%, #eaeaea 100%); */
        background: linear-gradient(#d7d7d7 0%, #fff 2%, #f4f4f4 55%, #eaeaea 100%);
        border-bottom: 1px solid var(--theme-color-a);
        position: relative;
        min-height: auto;
        padding-bottom: 5px;
        min-height: 35px;
    }

    row > :global(*) {
        margin: auto 0px;
    }

    b {
        top: 50%;
        margin-left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem;
        position: absolute;
    }
</style>
