<script lang="ts">
    import { afterUpdate, getContext } from "svelte"
    import { calcProfit, millisFormat, moneyFormat, prettyNumber, safeDivideFloat } from "../../../../lib/Utils"
    import type { Login } from "../../../../lib/models"
    import Chart from "../../../../widgets/Chart.svelte"
    import DashboardCard from "../../../../widgets/DashboardCard.svelte"
    import CcAcModal from "../../../elements/call-stats/CcAcModal.svelte"
    import CustomCarriersModal from "../../../elements/call-stats/CustomCarriersModal.svelte"
    import HangupCauseModal from "../../../elements/call-stats/HangupCauseModal.svelte"
    import PhoneFormatModal from "../../../elements/call-stats/PhoneFormatModal.svelte"
    import StatusModal from "../../../elements/call-stats/StatusModal.svelte"

    export var stats,
        period: number,
        showFinancial,
        showHangupCause: boolean = false

    const i18n: any = getContext("i18n"),
        login: Login = getContext("login")

    var deps = null,
        billingBars = null,
        billsecBars = null,
        qualityBars = null,
        chanChart = null

    var viewStatus = false,
        viewHangupCause = false,
        viewAreaCode = false,
        viewPhoneFormat = false,
        viewCarrier = false,
        qualityDenominators = null

    afterUpdate(function () {
        qualityDenominators = [stats.Calls[period], stats.Calls[period] - stats.Errors[period], stats.Calls[period], stats.Calls[period]]
        let now = new Date()
        if (stats) {
            let timeLabel = `${now.getHours().toString().padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}:${now.getSeconds().toString().padStart(2, "0")}`
            if (billingBars && login.Type == "user") billingBars.setData([[Math.floor(stats.BillingA[period])], [Math.floor(stats.BillingB[period])]])
            if (billsecBars && login.Type == "user") billsecBars.setData([[Math.floor(stats.BillsecA[period] / 60)], [Math.floor(stats.BillsecB[period] / 60)]])
            if (chanChart) chanChart.addData([timeLabel], [[stats.Channels], [stats.Cps.Value]], 50)
            if (qualityBars) qualityBars.setData([[stats.Calls[period] - stats.Errors[period], stats.PositiveVoice[period], stats.Status[period]["200"] || 0, stats.Calls[period] - (stats.Status[period]["200"] || 0) - stats.Errors[period]]])
        }
    })
</script>

<!-- Modals -->
{#if viewAreaCode}
    <CcAcModal {period} stat={stats} name="" onClose={() => (viewAreaCode = false)} />
{/if}
{#if viewPhoneFormat}
    <PhoneFormatModal {period} stat={stats} name="" onClose={() => (viewPhoneFormat = false)} />
{/if}
{#if viewCarrier}
    <CustomCarriersModal {period} stat={stats} name="" onClose={() => (viewCarrier = false)} />
{/if}
{#if viewStatus}
    <StatusModal {period} stat={stats} name="" onClose={() => (viewStatus = false)} />
{/if}
{#if viewHangupCause}
    <HangupCauseModal {period} stat={stats} name="" onClose={() => (viewHangupCause = false)} />
{/if}

<DashboardCard>
    <tr>
        <td>{prettyNumber(stats.Cps.Value)}</td>
        <td>{prettyNumber(stats.Channels)}</td>
        <td>{prettyNumber(stats.Calls[period])}</td>
        <td>{(stats.Calls[period] ? ((stats.Calls[period] - stats.Errors[period]) / stats.Calls[period]) * 100 : 0).toFixed(1)}%</td>
        <td>{(stats.Calls[period] ? ((stats.Status[period]["200"] || 0) / stats.Calls[period]) * 100 : 0).toFixed(1) + "%"}</td>
        <td>{(stats.Calls[period] ? safeDivideFloat(stats.PositiveVoice[period], stats.Calls[period] - stats.Errors[period]) * 100 : 0).toFixed(1) + "%"}</td>
        <td>{millisFormat(stats.Status[period]["200"] ? stats.AnswerDuration[period] / stats.Status[period]["200"] : 0)}</td>
        {#if showFinancial}
            <!-- <td>{"$" + moneyFormat(stats.BillingA)} ({(stats.BillsecA / 60).toFixed(1)} min)</td> -->
            <td>{"$" + moneyFormat(stats.BillingA[period])}</td>
            {#if login.Type == "user"}
                <!-- <td>{"$" + moneyFormat(stats.BillingB)} ({(stats.BillsecB / 60).toFixed(1)} min)</td> -->
                <td>{"$" + moneyFormat(stats.BillingB[period])}</td>
                <td>{calcProfit(stats.BillingA[period], stats.BillingB[period]).toFixed(1)}%</td>
            {/if}
        {/if}
        <td>
            <a
                href="#ac"
                title="Country and area codes"
                on:click|preventDefault={() => {
                    viewAreaCode = true
                }}>AC</a
            >
            <a
                href="#phone-format-formats"
                title="Phone number formats"
                on:click|preventDefault={() => {
                    viewPhoneFormat = true
                }}><i class="fa-solid fa-mobile-screen" /></a
            >
            <a
                href="#carrier"
                title="Carriers"
                on:click|preventDefault={() => {
                    viewCarrier = true
                }}><i class="fa-solid fa-sim-card" /></a
            >
            <a
                href="#status"
                title="SIP Status"
                on:click|preventDefault={() => {
                    viewStatus = true
                }}><i class="fa-solid fa-traffic-light" /></a
            >
            {#if showHangupCause}
                <a
                    href="#hcause"
                    title="Hangup cause"
                    on:click|preventDefault={() => {
                        viewHangupCause = true
                    }}><i class="fa-solid fa-phone-slash" /></a
                >
            {/if}
        </td>
    </tr>
    <tr>
        <td>CPS</td>
        <td>Channels</td>
        <td>Calls</td>
        <td>Signaled</td>
        <td>ASR</td>
        <td>Long Calls</td>
        <td>ACD</td>
        {#if showFinancial}
            {#if login.Type == "user"}
                <td>{i18n.translate("dashboard.sold")}</td>
            {/if}
            <td>{i18n.translate("dashboard.cost")}</td>
            {#if login.Type == "user"}
                <td>{i18n.translate("dashboard.profit")}</td>
            {/if}
        {/if}
        <td>Details</td>
    </tr>
</DashboardCard>

<double-space />
<row style="max-height: 150px; overflow:auto;">
    <div class="card" style="position:relative; flex:1;">
        <Chart
            bind:this={chanChart}
            setup={{
                type: "line",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: i18n.translate("dashboard.channels"),
                            data: [],
                            fill: false,
                            borderColor: "rgb(75, 192, 192)",
                            tension: 0.1,
                            pointStyle: false,
                        },
                        {
                            label: "CPS",
                            data: [],
                            fill: false,
                            borderColor: "rgb(54, 162, 235)",
                            tension: 0.1,
                            pointStyle: false,
                        },
                    ],
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        xAxes: {
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                            },
                        },
                        x: { display: false },
                        y: { beginAtZero: true, ticks: { stepSize: 1 } },
                    },
                    plugins: {
                        tooltip: {
                            mode: "index",
                            intersect: false,
                        },
                    },
                },
            }}
        />
    </div>

    <double-space />
    <div class="card" style="position:relative; flex:1;">
        <Chart
            bind:this={qualityBars}
            setup={{
                type: "bar",
                data: {
                    labels: [i18n.translate("dashboard.signaled"), i18n.translate("dashboard.long-calls"), i18n.translate("dashboard.answered"), i18n.translate("dashboard.no-answered")],
                    datasets: [
                        {
                            data: [0, 0, 0, 0],
                            backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
                            borderColor: ["rgba(75, 192, 192)", "rgb(153, 102, 255)", "rgba(54, 162, 235)", "rgba(255, 99, 132)"],
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        xAxes: {
                            ticks: {
                                maxRotation: 0,
                            },
                        },
                        x: { display: false },
                        y: {
                            beginAtZero: true,
                        },
                    },
                    plugins: {
                        legend: { display: false },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    let label = context.dataset.label || ""
                                    if (label) {
                                        label += ": "
                                    }
                                    if (context.parsed.y !== null) {
                                        label += `${context.parsed.y} (${(qualityDenominators && qualityDenominators[context.dataIndex] > 0 ? (context.parsed.y / qualityDenominators[context.dataIndex]) * 100 : 0).toFixed(1)}%)`
                                    }
                                    return label
                                },
                            },
                        },
                    },
                },
            }}
        />
    </div>

    {#if showFinancial}
        {#if login.Type == "user"}
            <double-space />
            <row class="card" style="position:relative; flex:1;">
                <div style="position:relative; flex:1;">
                    <Chart
                        bind:this={billingBars}
                        setup={{
                            type: "bar",
                            data: {
                                labels: [i18n.translate("dashboard.amount")],
                                datasets: [
                                    {
                                        label: i18n.translate("dashboard.sold"),
                                        data: [0],
                                        backgroundColor: ["rgba(75, 192, 192, 0.2)"],
                                        borderColor: ["rgb(75, 192, 192)"],
                                        borderWidth: 1,
                                    },
                                    {
                                        label: i18n.translate("dashboard.cost"),
                                        data: [0],
                                        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                                        borderColor: ["rgb(255, 99, 132)"],
                                        borderWidth: 1,
                                    },
                                ],
                            },
                            options: {
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                    },
                                },
                            },
                        }}
                    />
                </div>
                <div style="position:relative; flex:1;">
                    <Chart
                        bind:this={billsecBars}
                        setup={{
                            type: "bar",
                            data: {
                                labels: [i18n.translate("dashboard.minutes")],
                                datasets: [
                                    {
                                        label: i18n.translate("dashboard.sold"),
                                        data: [0],
                                        backgroundColor: ["rgba(75, 192, 192, 0.2)"],
                                        borderColor: ["rgb(75, 192, 192)"],
                                        borderWidth: 1,
                                    },
                                    {
                                        label: i18n.translate("dashboard.cost"),
                                        data: [0],
                                        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                                        borderColor: ["rgb(255, 99, 132)"],
                                        borderWidth: 1,
                                    },
                                ],
                            },
                            options: {
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                    },
                                },
                            },
                        }}
                    />
                </div>
            </row>
        {/if}
    {/if}
</row>
