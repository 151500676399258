<script lang="ts">
    export var submit: (form?: any) => void = null,
        readonly: boolean = false,
        box: boolean = true,
        expanded: boolean = false,
        form: any = null
</script>

<form bind:this={form} on:submit|preventDefault={() => submit && submit(form)} class="expanded">
    <column class="expanded form" class:box class:readonly>
        <double-space />
        <row style="min-height: auto;">
            <b style="margin:auto;"><slot name="title" /></b>
        </row>
        <scroll class:expanded>
            <scroll-content class:expanded class="children-spacing" style="padding: 10px 20px;">
                <slot />
            </scroll-content>
        </scroll>
    </column>
    <slot name="footer" />
</form>

<style>
    .form.box:not(.readonly) {
        margin: 10px 20px;
        border-radius: 5px;
        background-color: whitesmoke;
        border: var(--standard-border);
    }
</style>
