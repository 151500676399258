<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { createToast } from "../../../lib/Toast"
    import { abbreviateBytes, prettyNumber } from "../../../lib/Utils"
    import { CidList } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import NonIdealState from "../../../widgets/NonIdealState.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: CidList = null,
        deps = null,
        inputFile = null,
        inputFileValue = null,
        cc: string = "",
        ccInFile: boolean = false

    function reset() {
        inputFile = null
        inputFileValue = null
    }
</script>

<!-- translate -->
<Loader endpoint={["cid-list/list", "phone-number-format/list"]} let:data={deps} let:reload>
    {@const list = deps[0]}
    {@const numberFormatsById = Object.fromEntries(deps[1].map((v) => [v.Id, v]))}
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>{i18n.translate("dict.phone-numbers")}</th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each list as record}
                {@const lines = record.Info && record.Info.split("\n")}
                <tr>
                    <td>{record.Id}</td>
                    <td>{record.Name}</td>
                    <td>
                        {#if lines}
                            {prettyNumber(lines.reduce((p, c) => p + parseInt(c.split(";")[3]), 0))}
                        {:else}
                            <i class="fa-solid fa-triangle-exclamation error-color" />
                        {/if}
                    </td>
                    <td>
                        <a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}><i class="fa-solid fa-pencil" /></a
                        >
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("cid-list/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new CidList()
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")}</button
            >
        </PageFooter>
    {:else}
        <Form
            box={false}
            submit={() => {
                var data = new FormData()
                data.append("record", JSON.stringify(formRecord))
                if (inputFileValue) {
                    if (inputFile.files[0].size > 10 << 20) {
                        createToast("error", "File size limit: 10MB")
                        return
                    }
                    data.append("cc", cc)
                    data.append("ccInFile", ccInFile.toString())
                    data.append("file", inputFile.files[0])
                }

                modal.upload(`cid-list/save`, data).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reset()
                    reload()
                })
            }}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)}</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}

            <label>
                {i18n.translate("dict.name")}
                <input type="text" bind:value={formRecord.Name} />
            </label>

            <label>
                {i18n.translate("field.upload-cid-list-file")}
                <input type="file" accept=".csv,.txt" bind:this={inputFile} bind:value={inputFileValue} />
                {#if inputFileValue && inputFile.files}
                    <small>{abbreviateBytes(inputFile.files[0].size)}</small>
                {/if}
            </label>

            {#if inputFileValue}
                <label class="checkbox">
                    <input type="checkbox" autocomplete="off" bind:checked={ccInFile} />
                    {i18n.translate("field.country-code-in-cid-file")}
                </label>
                {#if !ccInFile}
                    <label>
                        {i18n.translate("field.country-code")}
                        <input type="text" bind:value={cc} maxlength="10" required />
                    </label>
                {/if}
            {:else if formMode == "edit" && formRecord.Info.length > 2}
                {@const lines = formRecord.Info.split("\n")}
                {#if lines.length > 0}
                    <Table scroll={false} margin="10px 0px">
                        <tr slot="head">
                            <th>{i18n.translate("dict.phone-number-format")}</th>
                            <th>{i18n.translate("dict.country-codes")}</th>
                            <th>{i18n.translate("dict.area-codes")}</th>
                            <th>{i18n.translate("dict.phone-numbers")}</th>
                        </tr>
                        {#each lines as line}
                            {@const cells = line.split(";")}
                            <tr>
                                {#each cells as cell, i}
                                    {#if i == 0}
                                        <td>{i == 0 && cell in numberFormatsById ? numberFormatsById[cell].Name : "ID: " + cell}</td>
                                    {:else}
                                        <td>{prettyNumber(cell)}</td>
                                    {/if}
                                {/each}
                            </tr>
                        {/each}
                    </Table>
                {/if}
            {/if}

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button
                        type="button"
                        class="cancel"
                        on:click={() => {
                            reset()
                            formMode = "none"
                        }}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button
                    >
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
