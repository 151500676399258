<script lang="ts">
    import { getContext, onMount } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import Chart from "../../../widgets/Chart.svelte"
    import Form from "../../../widgets/Form.svelte"
    import NonIdealState from "../../../widgets/NonIdealState.svelte"
    import Spinner from "../../../widgets/Spinner.svelte"
    import Title from "../../../widgets/Title.svelte"
    import TabContent from "../../../widgets/tab/TabContent.svelte"
    import Tabs from "../../../widgets/tab/Tabs.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var data = null
    function load() {
        data = null
        fetch("configs/softswitch/router")
            .then((r) => r.json())
            .then((r) => (data = r))
    }

    var stats = {
        query: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
    }

    onMount(function () {
        load()
    })
</script>

{#if data}
    <!-- <column style="max-width: 500px;">
        <Form
            submit={() => {
                modal.post("configs/softswitch/router/save", data).then(() => load())
            }}
            box={false}
        >
            <label>
                Global Channel Limit*
                <input type="number" min="1" bind:value={data.ChannelLimit} required />
            </label>

            <label class="checkbox">
                <input type="checkbox" bind:checked={data.AllowClassifier} />
                Allow Classifiers
            </label>

            <row>
                <expander />
                <double-space />
                <button class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
            </row>
        </Form>
    </column> -->

    <Tabs selected="settings">
        <svelte:fragment slot="tabs">
            <a href="#settings">Settings</a>
            <a href="#stats">Stats</a>
        </svelte:fragment>

        <TabContent name="settings">
            <column style="max-width: 500px;">
                <Form
                    submit={() => {
                        modal.post("configs/softswitch/router/save", data).then(() => load())
                    }}
                    box={false}
                >
                    <label>
                        Channel Limit*
                        <input type="number" min="1" bind:value={data.ChannelLimit} required />
                    </label>

                    <label class="checkbox">
                        <input type="checkbox" bind:checked={data.AllowClassifier} />
                        Allow Classifiers
                    </label>

                    <row>
                        <expander />
                        <double-space />
                        <button class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                    </row>
                </Form>
            </column>
        </TabContent>

        <TabContent name="stats">
            <column class="expanded">
                <Title title="">
                    <svelte:fragment slot="right">
                        <label>
                            <row>
                                <input type="number" placeholder="Year" required bind:value={stats.query.year} />
                                <input type="number" placeholder="Month" required min="1" max="12" bind:value={stats.query.month} />
                            </row>
                        </label>
                    </svelte:fragment>
                </Title>
                {#key stats.query.year + "/" + stats.query.month}
                    <Loader endpoint="server/stats/{stats.query.year}/{stats.query.month.toString().padStart(2, '0')}" let:data let:reload>
                        {#if data}
                            {@const lines = (function () {
                                var r = data.split("\n")
                                return r.slice(1).map((it) => it.split(";"))
                            })()}
                            <column>
                                <column style="height: 200px; margin-top: 20px;">
                                    <Chart
                                        maxTicks={1000}
                                        setup={{
                                            type: "line",
                                            data: {
                                                labels: lines.map((line) => line[0]),
                                                datasets: [
                                                    {
                                                        label: "CPS",
                                                        data: lines.map((it) => parseInt(it[2])),
                                                        fill: false,
                                                        borderWidth: 2,
                                                        pointHitRadius: 5,
                                                        pointRadius: 0,
                                                        borderColor: "rgb(75, 192, 192)",
                                                        tension: 0.1,
                                                    },
                                                    {
                                                        label: "Channels",
                                                        data: lines.map((it) => parseInt(it[1])),
                                                        fill: false,
                                                        borderWidth: 2,
                                                        pointHitRadius: 5,
                                                        pointRadius: 0,
                                                        borderColor: "rgb(153, 102, 255)",
                                                        tension: 0.1,
                                                    },
                                                ],
                                            },
                                            options: {
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                scales: {
                                                    xAxes: {
                                                        ticks: {
                                                            autoSkip: true,
                                                            maxTicksLimit: 5,
                                                        },
                                                    },
                                                    x: { display: false },
                                                    y: { beginAtZero: true, ticks: { stepSize: 1 } },
                                                },
                                                plugins: {
                                                    tooltip: {
                                                        // para mostrar todos os valores
                                                        mode: "index",
                                                        intersect: false,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </column>
                                <column style="height: 200px; margin-top: 20px;">
                                    <Chart
                                        maxTicks={1000}
                                        setup={{
                                            type: "line",
                                            data: {
                                                labels: lines.map((line) => line[0]),
                                                datasets: [
                                                    {
                                                        label: "CPU (%)",
                                                        data: lines.map((it) => parseInt(it[3])),
                                                        fill: false,
                                                        borderWidth: 2,
                                                        pointHitRadius: 5,
                                                        pointRadius: 0,
                                                        borderColor: "rgb(255, 99, 132)",
                                                        tension: 0.1,
                                                    },
                                                    {
                                                        label: "RAM (%)",
                                                        data: lines.map((it) => parseInt(it[4])),
                                                        fill: false,
                                                        borderWidth: 2,
                                                        pointHitRadius: 5,
                                                        pointRadius: 0,
                                                        borderColor: "rgb(201, 203, 207)",
                                                        tension: 0.1,
                                                    },
                                                ],
                                            },
                                            options: {
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                scales: {
                                                    xAxes: {
                                                        ticks: {
                                                            autoSkip: true,
                                                            maxTicksLimit: 5,
                                                        },
                                                    },
                                                    x: { display: false },
                                                    y: { beginAtZero: true, grace: true, max: 100, ticks: { stepSize: 1 } },
                                                },
                                                plugins: {
                                                    tooltip: {
                                                        // para mostrar todos os valores
                                                        mode: "index",
                                                        intersect: false,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </column>
                            </column>
                        {:else}
                            <NonIdealState icon="fa-solid fa-triangle-exclamation">Data Not Found</NonIdealState>
                        {/if}
                        <expander />
                        <PageFooter>
                            <expander />
                            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
                        </PageFooter>
                    </Loader>
                {/key}
            </column>
        </TabContent>
    </Tabs>
{:else}
    <Spinner />
{/if}
