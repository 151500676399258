<script lang="ts">
    import I18N from "./lib/I18N.svelte"
    import { isMobile } from "./lib/Utils"
    import Layout from "./protected/Layout.svelte"
    import Login from "./protected/elements/Login.svelte"
    import FetchModal from "./widgets/modals/Fetch.svelte"
</script>

<I18N let:translate>
    <FetchModal>
        <column class="expand">
            <expander />
            <Login>
                <Layout />
            </Login>

            <expander />
            <row style="font-size: 11px; padding: 3px; background-color: rgb(0, 57, 99); color: rgb(167, 199, 230);">
                <div style="margin: 0px auto;">
                    {#if isMobile}
                        ONSWITCH ® - ONMAI INOVATIONS
                    {:else}
                        {translate("footer.brand")}
                    {/if}
                </div>
            </row>
        </column>
    </FetchModal>
</I18N>
