<script>
    import { isMobile } from "../../lib/Utils"
</script>

<column style="min-height: auto; padding: 10px; padding: 10px 20px; background-color: rgb(250 250 250); border-top: var(--light-border);">
    {#if isMobile}
        <column><slot /></column>
    {:else}
        <row><slot /></row>
    {/if}
</column>

<style>
    column > row > :global(button) {
        margin-left: 10px;
    }
    column > column > :global(button) {
        margin-top: 10px;
    }
</style>
