<script lang="ts">
    import { getContext } from "svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import ClientWallets from "./financial/ClientWallets.svelte"

    const i18n: any = getContext("i18n")
</script>

<Page>
    <Tabs selected="client-wallets">
        <svelte:fragment slot="tabs">
            <a href="#client-wallets">{i18n.translate("financial.wallets")}</a>
        </svelte:fragment>

        <TabContent name="client-wallets">
            <ClientWallets />
        </TabContent>
    </Tabs>
</Page>
