<script lang="ts">
    // https://www.chartjs.org/docs/latest/getting-started/
    import { onMount } from "svelte"

    export var setup: any,
        maxTicks: number | null = null

    var canvas: any = null,
        chart: any = null

    export function setData(data: any[]) {
        if (!chart) return
        for (let i = 0; i < data.length; i++) {
            chart.data.datasets[i].data = data[i]
        }
        originalData.datasets = chart.data.datasets.map((it) => it.data)
        chart.update("none")
    }

    export function addData(labels: string[], data: any[], max: number) {
        if (!chart) return
        chart.data.labels.push(...labels)
        for (let i = 0; i < data.length; i++) {
            chart.data.datasets[i].data.push(...data[i])
        }
        let overflow = chart.data.labels.length - max
        if (overflow > 0) {
            chart.data.labels = chart.data.labels.slice(overflow)
            for (let i = 0; i < data.length; i++) chart.data.datasets[i].data = chart.data.datasets[i].data.slice(overflow)
        }
        chart.update("none")
    }

    /** @property {Array<any>} labels */
    var originalData = { labels: setup.data.labels, datasets: setup.data.datasets.map((it) => it.data) }
    onMount(function () {
        var zoomRealPosition
        function reset() {
            var maxTicksMod = maxTicks && Math.ceil(originalData.labels.length / maxTicks)
            chart.data.labels = originalData.labels
            if (maxTicks) chart.data.labels = setup.data.labels.filter((_, i) => i % maxTicksMod == 0)
            zoomRealPosition = { div: maxTicksMod || 1, start: 0, end: originalData.labels.length }
            for (var i in chart.data.datasets) {
                chart.data.datasets[i].data = originalData.datasets[i]
                if (maxTicks) chart.data.datasets[i].data = setup.data.datasets[i].data.filter((_, i) => i % maxTicksMod == 0)
            }
        }

        chart = new window["Chart"](canvas, setup)
        if (chart.options && chart.options.plugins && !chart.options.plugins.crosshair) chart.options.plugins.crosshair = {}
        reset()

        chart.options.plugins.crosshair = {
            zoom: {
                enabled: true,
                zoomButtonClass: "reset-zoom",
            },
            callbacks: {
                beforeZoom: () =>
                    function (start, end) {
                        // called before zoom, return false to prevent zoom
                        return true
                    },
                afterZoom: () =>
                    function (start, end) {
                        var last = zoomRealPosition
                        var lastTicks = (last.end - last.start) / last.div
                        var curr = {
                            start: last.start + (last.end - last.start) * (start / lastTicks),
                            end: last.start + (last.end - last.start) * (end / lastTicks),
                            div: 1,
                        }
                        var maxTicksMod = maxTicks && Math.ceil((curr.end - curr.start) / maxTicks)
                        curr.div = maxTicksMod || 1
                        zoomRealPosition = curr

                        document.querySelector(".reset-zoom")["onclick"] = function () {
                            reset()
                            chart.update("none")
                        }

                        chart.data.labels = originalData.labels.slice(curr.start, curr.end)
                        if (maxTicks) chart.data.labels = chart.data.labels.filter((_, i) => i % maxTicksMod == 0)
                        for (var j in chart.data.datasets) {
                            chart.data.datasets[j].data = originalData.datasets[j].slice(curr.start, curr.end)
                            if (maxTicks) chart.data.datasets[j].data = chart.data.datasets[j].data.filter((_, i) => i % maxTicksMod == 0)
                        }
                        chart.update("none")
                    },
            },
        }
    })
</script>

<canvas bind:this={canvas} />

<style>
    :global(.reset-zoom) {
        position: absolute;
        right: 10px;
        margin-top: 20px;
    }
</style>
