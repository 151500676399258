<script lang="ts">
    import { dateFormatDMYTime, millisFormat } from "../../../lib/Utils"
    import type { SearchRequest, SearchResult } from "../../../lib/golang-models/cdr"
    import Chart from "../../../widgets/Chart.svelte"

    export var result: SearchResult

    var colors = ["rgb(75, 192, 192)", "rgb(153, 102, 255)", "rgb(201, 203, 207)", "rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 159, 64)", "rgb(255, 205, 86)"]
    var ratioMode = "sum"

    var request: SearchRequest = result["request"]
    var endDate = new Date(request.EndDate).getTime() / 1000
    var startDate = new Date(request.StartDate).getTime() / 1000
    var interval = Math.round((endDate - startDate) / result.ChartData[0].length)

    // trim
    {
        let trimStart = 0,
            trimEnd = 0
        for (let i = 0; i < result.ChartData[0].length; i++) {
            if (result.ChartData[0][i] <= 0) trimStart++
            else break
        }
        for (let i = result.ChartData[0].length - 1; i >= 0; i--) {
            if (result.ChartData[0][i] <= 0) trimEnd++
            else break
        }
        if (trimStart > 0) {
            for (let queryData of result.ChartData) queryData.splice(0, trimStart)
            startDate += interval * trimStart
        }
        if (trimEnd > 0) {
            for (let queryData of result.ChartData) queryData.splice(queryData.length - trimEnd, trimEnd)
            endDate -= interval * trimEnd
        }
        interval = Math.round((endDate - startDate) / result.ChartData[0].length)
    }
</script>

<column class="expanded">
    <row style="padding: 0px 20px; margin-top: 10px;">
        <span title="Search duration time">
            <i class="fa-solid fa-clock" style="font-size: 0.8rem;" />
            {millisFormat(result.Time, "h:m:s.l")}
        </span>
        <expander />
        <select bind:value={ratioMode}>
            <option value="sum">Ratio by sum of all rows</option>
            <option value="higher">Ratio by higher value</option>
        </select>
    </row>

    <column style="max-height: 500px;">
        <Chart
            setup={{
                type: "line",
                data: {
                    labels: result.ChartData[0].map((_, i) => dateFormatDMYTime(new Date((startDate + i * interval) * 1000))),
                    datasets: result.ChartData.map((d, i) => ({
                        label: request.Queries[i].Name,
                        data: d,
                        fill: false,
                        borderWidth: 2,
                        pointHitRadius: 5,
                        pointRadius: 0,
                        borderColor: colors[i % colors.length],
                        tension: 0.1,
                    })),
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        xAxes: {
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                            },
                        },
                        x: { display: false },
                        y: { beginAtZero: true, ticks: { stepSize: 1 } },
                    },
                    plugins: {
                        tooltip: {
                            // para mostrar todos os valores
                            mode: "index",
                            intersect: false,
                            // ...

                            callbacks: {
                                label: function (context) {
                                    let label = context.dataset.label || ""
                                    if (label) {
                                        label += ": "
                                    }
                                    if (context.parsed.y !== null) {
                                        let sum = 0
                                        for (let i = 0; i < result.ChartData.length; i++) {
                                            if (this.chart.isDatasetVisible(i)) {
                                                if (ratioMode == "sum") sum += result.ChartData[i][context.dataIndex]
                                                else if (ratioMode == "higher") sum = Math.max(sum, result.ChartData[i][context.dataIndex])
                                            }
                                        }
                                        label += `${context.parsed.y} (${(sum > 0 ? (context.parsed.y / sum) * 100 : 0).toFixed(1)}%)`
                                    }
                                    return label
                                },
                            },
                        },
                    },
                },
            }}
        />
    </column>
</column>
