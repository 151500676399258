<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { Carrier } from "../../../lib/golang-models/maindb"
    import { telephonySystems } from "../../../lib/models"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Thumb from "../../../widgets/Thumb.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: Carrier = null
</script>

<Loader endpoint="carrier/custom/list" let:data let:reload>
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>{i18n.translate("dict.telephony-system")} <TableSorter /></th>
                <th>{i18n.translate("field.allow-calls")} </th>
            </tr>

            {#each data as record}
                <tr>
                    <td>{record.Id}</td>
                    <td
                        ><a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}>{record.Name}</a
                        ></td
                    >
                    <td>{record.TelephonySystemNamespace in telephonySystems ? telephonySystems[record.TelephonySystemNamespace].label : ""}</td>
                    <td><Thumb value={record.AllowCalls} /></td>
                    <td>
                        <a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}><i class="fa-solid fa-pencil" /></a
                        >
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("carrier/custom/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new Carrier()
                    formRecord.TelephonySystemNamespace = "abr"
                    formRecord.TelephonySystemReference = telephonySystems.abr.defaultReference()
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("dict.carrier")}</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={() =>
                modal.post(`carrier/custom/save`, formRecord).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)} {i18n.translate("dict.carrier")}</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}

            <label>
                {i18n.translate("dict.name")}*
                <input type="text" bind:value={formRecord.Name} required />
            </label>

            <label>
                {i18n.translate("dict.telephony-system")}
                <select bind:value={formRecord.TelephonySystemNamespace} disabled={formMode == "edit"}>
                    <option value="abr">{telephonySystems.abr.label}</option>
                </select>
            </label>

            {#if formRecord.TelephonySystemNamespace == "abr"}
                <label for="">
                    Números base de CNPJ da operadora para móvel (SMP)
                    <input value={formRecord.TelephonySystemReference.Smp.join(", ")} on:change={(e) => (formRecord.TelephonySystemReference.Smp = e.currentTarget.value.split(",").map((it) => it.trim()))} />
                    <small>Separe por vírgula</small>
                </label>
                <label for="">
                    Números base de CNPJ da operadora para fixo (STFC)
                    <input value={formRecord.TelephonySystemReference.Stfc.join(", ")} on:change={(e) => (formRecord.TelephonySystemReference.Stfc = e.currentTarget.value.split(",").map((it) => it.trim()))} />
                    <small>Separe por vírgula</small>
                </label>
            {/if}

            <label class="checkbox">
                <input type="checkbox" bind:checked={formRecord.AllowCalls} />
                {i18n.translate("field.allow-calls")}
            </label>

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
