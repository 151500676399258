<script lang="ts">
    import { getContext } from "svelte"
    import { UserRole, type Client } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Password from "../../../widgets/inputs/Password.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    export var original: Client = null,
        mode: "view" | "edit" = "view",
        title = "",
        onSave: (id: number) => void

    const i18n: any = getContext("i18n"),
        login: any = getContext("login"),
        modal: any = getContext("modal")
    var copy: Client = JSON.parse(JSON.stringify(original))
</script>

{#if mode == "view"}
    <Form readonly>
        <label>
            ID
            <input type="text" value={original.Id} readonly />
        </label>
        <label>
            {i18n.translate("dict.name")}
            <input type="text" value={original.Name} readonly />
        </label>
        <label>
            {i18n.translate("dict.description")}
            <input type="text" value={original.Description || "-"} readonly />
        </label>
        <label class="checkbox readonly">
            <input type="checkbox" checked={original.AllowLogin} readonly />
            {i18n.translate("field.allow-login")}
        </label>
        <label class="checkbox readonly">
            <input type="checkbox" checked={original.AllowCalls} readonly />
            {i18n.translate("field.allow-calls")}
        </label>
        {#if original.AllowCalls}
            <label class="checkbox readonly">
                <input type="checkbox" checked={original.CdrStore503} readonly />
                {i18n.translate("field.cdr-store-503")}
            </label>

            {#if original.ChannelLimit}
                <label>
                    {i18n.translate("field.channel-limit")}
                    <input type="text" value={original.ChannelLimit || "-"} readonly />
                </label>
            {:else}
                <label class="checkbox readonly">
                    <input type="checkbox" checked={false} readonly />
                    {i18n.translate("field.channel-limit")}
                </label>
            {/if}
        {/if}
    </Form>
    <PageFooter>
        <slot />
        <expander />
        {#if login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT)}
            {#if original.Id != 0}
                <button
                    type="button"
                    on:click={() => {
                        mode = "edit"
                        copy = JSON.parse(JSON.stringify(original))
                    }}><i class="fa-solid fa-pencil" /> {i18n.translate("action.edit")}</button
                >
            {/if}
        {/if}
    </PageFooter>
{:else}
    <Form
        submit={() =>
            modal.post("client/save", copy).then((res) => {
                if (res.status != 200) return
                mode = "view"
                onSave(res.data)
            })}
    >
        <svelte:fragment slot="title">{title}</svelte:fragment>
        {#if copy.Id > 0}
            <label>
                ID
                <input disabled value={copy.Id} />
            </label>
        {/if}
        <label>
            {i18n.translate("dict.name")}*
            <input autocomplete="off" bind:value={copy.Name} required />
        </label>
        <label>
            {i18n.translate("dict.username")}*
            <input autocomplete="off" bind:value={copy.Username} required />
        </label>
        <Password name={!copy.Id ? i18n.translate("field.password") + "*" : i18n.translate("field.new-password")} bind:value={copy.Password} required={!copy.Id} />

        <label>
            {i18n.translate("dict.description")}
            <textarea autocomplete="off" bind:value={copy.Description} />
        </label>

        <label class="checkbox">
            <input type="checkbox" autocomplete="off" bind:checked={copy.AllowLogin} />
            {i18n.translate("field.allow-login")}
        </label>

        <label class="checkbox">
            <input type="checkbox" autocomplete="off" bind:checked={copy.AllowCalls} />
            {i18n.translate("field.allow-calls")}
        </label>

        <label class="checkbox">
            <input type="checkbox" autocomplete="off" bind:checked={copy.CdrStore503} />
            {i18n.translate("field.cdr-store-503")}
        </label>

        <label class="checkbox">
            <input
                type="checkbox"
                autocomplete="off"
                checked={copy.ChannelLimit > 0}
                on:change={(e) => {
                    if (e.currentTarget.checked) copy.ChannelLimit = 100
                    else copy.ChannelLimit = 0
                }}
            />
            {i18n.translate("field.channel-limit")}
        </label>
        {#if copy.ChannelLimit > 0}
            <input type="number" min="1" bind:value={copy.ChannelLimit} required />
        {/if}

        <svelte:fragment slot="footer">
            <PageFooter>
                <expander />
                <slot />
                {#if original.Id}
                    <button
                        type="button"
                        class="cancel"
                        on:click={() => {
                            mode = mode == "view" ? "edit" : "view"
                            copy = JSON.parse(JSON.stringify(original))
                        }}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button
                    >
                {/if}
                <button class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
            </PageFooter>
        </svelte:fragment>
    </Form>
{/if}
