<script lang="ts">
    import { getContext } from "svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import CallSimulator from "./utils/CallSimulator.svelte"

    const i18n: any = getContext("i18n"),
        login: any = getContext("login")
</script>

<Page>
    <Tabs selected="call-simulator">
        <svelte:fragment slot="tabs">
            <a href="#call-simulator">{i18n.translate("financial.call-simulator")}</a>
        </svelte:fragment>

        <TabContent name="call-simulator">
            <CallSimulator />
        </TabContent>
    </Tabs>
</Page>
