<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { componentLoop } from "../../../lib/Utils"
    import CallStatsTable from "../../elements/call-stats/CallStatsTable.svelte"
    import Charts from "./commons/Charts.svelte"
    import Wallets from "./commons/Wallets.svelte"

    var stats,
        period = 0,
        statFocus: null | string = null

    const i18n: any = getContext("i18n"),
        pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance")

    function load() {
        fetch("softswitch/dashboard/main" + (statFocus != null ? "?focus=" + statFocus : ""))
            .then((r) => r.json())
            .then((r) => (stats = r))
    }

    componentLoop(onMount, onDestroy, 6000, function () {
        if ($pageManager.current == pageInstance) load()
    })

    var deps = null
</script>

<Loader endpoint={["client/dashboard/deps"]} let:data={deps}>
    {@const techsById = Object.fromEntries(deps[0].Techprefixes.map((v) => [v.Id, v]))}
    <column style="padding: 10px; flex:1;">
        {#if stats}
            {@const techStats = Object.keys(stats.Techprefixes).map((k) => ({ key: k, id: k.substring(k.lastIndexOf("_") + 1), ...stats.Techprefixes[k] }))}
            <Charts {period} showFinancial={true} stats={stats.Client} />
            <column class="expanded">
                <!-- Tables -->
                <column style="flex: 1.5;">
                    <column style="flex: 1.5;">
                        <CallStatsTable
                            onStatFocus={(k) => {
                                statFocus = k
                                if (k) load()
                            }}
                            {period}
                            header="Techprefix"
                            data={techStats}
                            dict={techsById}
                            showFinancial={true}
                            showHangupCause={false}
                        />
                    </column>
                    <column style="flex: 1;">
                        <Wallets endpoint="client/dashboard/wallets" />
                    </column>
                </column>
            </column>
        {/if}
    </column>
</Loader>
