<script lang="ts">
    import { getContext } from "svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import Carriers from "./advanced/Carriers.svelte"
    import PhoneNumberFormats from "./advanced/PhoneNumberFormats.svelte"
    import RemoteProxies from "./advanced/RemoteProxies.svelte"
    import Router from "./advanced/Router.svelte"
    import ServerProxy from "./advanced/ServerProxy.svelte"
    import TelephonySystems from "./advanced/TelephonySystems.svelte"

    const i18n: any = getContext("i18n")
</script>

<Page>
    <Tabs selected="router">
        <svelte:fragment slot="tabs">
            <a href="#router">{i18n.translate("dict.router")}</a>
            <a href="#carriers">{i18n.translate("dict.carriers")}</a>
            <a href="#telephony-systems">{i18n.translate("dict.telephony-systems")}</a>
            <a href="#phone-number-formats">{i18n.translate("dict.phone-number-formats")}</a>
            <a href="#server-proxy">RTP Server Proxy</a>
            <a href="#remote-proxies">RTP Remote Proxies</a>
        </svelte:fragment>
        <TabContent name="router">
            <Router />
        </TabContent>
        <TabContent name="carriers">
            <Carriers />
        </TabContent>
        <TabContent name="telephony-systems">
            <TelephonySystems />
        </TabContent>
        <TabContent name="phone-number-formats">
            <PhoneNumberFormats />
        </TabContent>
        <TabContent name="server-proxy">
            <ServerProxy />
        </TabContent>
        <TabContent name="remote-proxies">
            <RemoteProxies />
        </TabContent>
    </Tabs>
</Page>
