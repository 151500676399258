<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { abbreviateBytes } from "../../../lib/Utils"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var month = new Date().getMonth() + 1,
        year = new Date().getFullYear()
</script>

<Form expanded box={false}>
    <label>
        Month
        <row>
            <input type="number" bind:value={year} min="2020" placeholder="Year" />
            <input type="number" bind:value={month} min="1" max="12" placeholder="Month" />
        </row>
    </label>

    {#key year + "-" + month}
        <Loader endpoint="tool/minutes-report/files/{year}/{month}" let:data let:reload>
            <Table margin="10px 0px 0px 0px">
                <tr slot="head">
                    <th>{i18n.translate("dict.date")}</th>
                    <th>CDR</th>
                    <th>Consolidation</th>
                    <th>{i18n.translate("dict.actions")}</th>
                </tr>

                {#each data.Cdrs as it}
                    {@const stat = data.Stats.find((v) => v.Date == it.Date)}
                    <tr>
                        <td>{it.Date}</td>
                        <td>{abbreviateBytes(it.Size)}</td>
                        <td>
                            {#if stat}
                                {abbreviateBytes(stat.Size)}
                            {:else}
                                <i class="fa-solid fa-triangle-exclamation warning-color" />
                            {/if}
                        </td>
                        <td>
                            {#if data.ConsolidationDate == it.Date}
                                consolidating...
                            {:else}
                                <a
                                    title="Consolidate"
                                    href="#consolidate"
                                    on:click|preventDefault={() => {
                                        modal.post(`tool/minutes-report/consolidate/${it.Date}`).then(() => {
                                            reload()
                                            setTimeout(() => reload(), 3000)
                                        })
                                    }}
                                >
                                    <i class="fa-solid fa-bolt-lightning" />
                                </a>
                            {/if}
                        </td>
                    </tr>
                {/each}
            </Table>

            <row>
                <expander />
                <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            </row>
        </Loader>
    {/key}
</Form>
