<script>
    import { onDestroy, onMount } from "svelte"
    import { componentLoop } from "../lib/Utils"

    /** @type {boolean|"up"|"down"} */
    export var presort = false
    var element

    function sort(toggle) {
        if (!element.parentNode.parentNode.parentNode.parentNode.classList.contains("header")) return
        let icon = element.querySelector("i")
        let down = icon.classList.contains("fa-caret-down")

        const ths = [...element.parentNode.parentNode.children]
        const index = ths.indexOf(element.parentNode)
        const tbody = element.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector("tbody")

        if (toggle) {
            let last = element.parentNode.parentNode.querySelector("i:not(.fa-sort)")
            icon.className = down ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
            if (last && last != icon) {
                last.className = "fa-solid fa-sort"
            }
        }

        function evalText(s) {
            let a = s.split(" ")[0]
            if (!a.match(/[^0-9.,%]/g)) return a
            let f = parseFloat(a.replace(/\,/g, "."))
            return !isNaN(f) ? f : s
        }

        let list = new Array(tbody.rows.length).fill(null)
        for (let i = 0; i < tbody.rows.length; i++) {
            let td = tbody.rows[i].children[index]
            list[i] = { value: (td.getAttribute("data-sort") && parseFloat(td.getAttribute("data-sort"))) || evalText(td.innerText), tr: tbody.rows[i], i }
        }

        list.sort((a, b) => ((!down == !toggle) == (a.value > b.value || (a.value == b.value && a.i < b.i == !down)) ? 1 : -1))
        for (let i = list.length - 1; i >= 0; i--) {
            tbody.removeChild(list[i].tr)
        }
        for (let i = list.length - 1; i >= 0; i--) {
            tbody.appendChild(list[i].tr)
        }
    }

    onMount(function () {
        if (presort) {
            setTimeout(() => element.click(), 100)
        }
    })

    componentLoop(onMount, onDestroy, 5000, function () {
        if (!element) return
        let icon = element.querySelector("i")
        if (!icon.classList.contains("fa-sort")) {
            sort(false)
        }
    })
</script>

<a href="#btn" class="_sorter-btn" on:click|preventDefault={() => sort(true)} bind:this={element}>
    <i class="fa-solid fa-{typeof presort === 'string' ? 'caret-' + (presort == 'up' ? 'down' : 'up') : 'sort'}" />
</a>

<style>
    ._sorter-btn {
        margin-left: 5px;
        cursor: pointer;
    }

    ._sorter-btn:hover {
        color: var(--bs-info);
    }
</style>
