import type { Client, User, UserRole } from "./golang-models/maindb"

export interface Login {
    Type: "" | "user" | "client"
    Data: User | Client
    isMaster(): boolean
    containsAnyUserRoles(...roles: UserRole[]): boolean
}

export var telephonySystems = {
    abr: {
        label: "ABR Telecom",
        defaultReference() { return JSON.parse(`{"Smp":[],"Stfc":[]}`) }
    }
}