<script lang="ts">
    import { getContext } from "svelte"
    import { calcProfit, millisFormat, moneyFormat, prettyNumber, safeDivideFloat } from "../../../lib/Utils"
    import type { SearchResult, StatisticalSearchData } from "../../../lib/golang-models/cdr"
    import DashboardCard from "../../../widgets/DashboardCard.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Login from "../../elements/Login.svelte"

    const i18n: any = getContext("i18n"),
        login: Login = getContext("login"),
        phoneFormats = getContext("phone-formats"),
        customCarriers = getContext("custom-carriers")
    const carriersById = Object.fromEntries(Object.values(customCarriers).map((v) => [v.Id, v]))

    const phoneFormatsById = Object.fromEntries(Object.values(phoneFormats).map((it) => [it.Id, it]))
    export var stats: StatisticalSearchData, result: SearchResult, showFinancial: boolean
</script>

<!-- translate -->
<column style="flex:1; padding: 10px;">
    <DashboardCard>
        <tr>
            <td>{prettyNumber(stats.Calls)}</td>
            <td>{(stats.Calls ? ((stats.Calls - stats.Errors) / stats.Calls) * 100 : 0).toFixed(1)}%</td>
            <td>{(stats.Calls ? ((stats.StatusA["200"] || 0) / stats.Calls) * 100 : 0).toFixed(1) + "%"}</td>
            <td>{(stats.Calls ? safeDivideFloat(stats.PositiveVoice, stats.Calls - stats.Errors) * 100 : 0).toFixed(1) + "%"}</td>
            <td>{millisFormat(stats.StatusA["200"] ? stats.AnswerDurationA / stats.StatusA["200"] : 0)}</td>
            {#if showFinancial}
                <td>{"$" + moneyFormat(stats.BillingA)}</td>
                {#if login.Type == "user"}
                    <td>{"$" + moneyFormat(stats.BillingB)}</td>
                    <td>{calcProfit(stats.BillingA, stats.BillingB).toFixed(1)}%</td>
                {/if}
            {/if}
            <td><span class:error-color={result.Timeouted}>{millisFormat(result.Time, "h:m:s.l")}</span></td>
        </tr>
        <tr>
            <td>Calls</td>
            <td>Signaled</td>
            <td>ASR</td>
            <td>Long Calls</td>
            <td>ACD</td>
            {#if showFinancial}
                {#if login.Type == "user"}
                    <td>{i18n.translate("dashboard.sold")}</td>
                {/if}
                <td>{i18n.translate("dashboard.cost")}</td>
                {#if login.Type == "user"}
                    <td>{i18n.translate("dashboard.profit")}</td>
                {/if}
            {/if}
            <td
                ><span class:error-color={result.Timeouted}
                    >{#if result.Timeouted}<i class="fa-solid fa-stopwatch-20" /> Timeout{:else}Search Time{/if}</span
                ></td
            >
        </tr>
    </DashboardCard>

    <scroll>
        <scroll-content>
            <row class="_title">
                <b>Client Status</b>
            </row>
            <Table margin="0px 10px 10px 10px" scroll={false}>
                <tr slot="head">
                    <th>Status <TableSorter /></th>
                    <th>Amount <TableSorter /></th>
                    <th>Ratio <TableSorter /></th>
                </tr>
                {#each Object.keys(stats.StatusA)
                    .map((it) => parseInt(it))
                    .sort() as k}
                    <tr>
                        <td>{k}</td>
                        <td data-sort={stats.StatusA[k]}>{prettyNumber(stats.StatusA[k])}</td>
                        <td data-sort={stats.Calls > 0 ? (stats.StatusA[k] / stats.Calls) * 100 : 0}>{(stats.Calls > 0 ? (stats.StatusA[k] / stats.Calls) * 100 : 0).toFixed(1)}%</td>
                    </tr>
                {/each}
            </Table>

            <row class="_title">
                <b>Provider Status</b>
            </row>
            <Table margin="0px 10px 10px 10px" scroll={false}>
                <tr slot="head">
                    <th>Status <TableSorter /></th>
                    <th>Amount <TableSorter /></th>
                    <th>Ratio <TableSorter /></th>
                </tr>
                {#each Object.keys(stats.StatusB)
                    .map((it) => parseInt(it))
                    .sort() as k}
                    {@const den = stats.Calls + stats.DiscardB}
                    <tr>
                        <td>{k}</td>
                        <td data-sort={stats.StatusB[k]}>{prettyNumber(stats.StatusB[k])}</td>
                        <td data-sort={den > 0 ? (stats.StatusB[k] / den) * 100 : 0}>{(den > 0 ? (stats.StatusB[k] / den) * 100 : 0).toFixed(1)}%</td>
                    </tr>
                {/each}
            </Table>

            <row class="_title">
                <b>Phone Number Format</b>
            </row>
            <Table margin="0px 10px 10px 10px" scroll={false}>
                <tr slot="head">
                    <th>Format <TableSorter /></th>
                    <th>Amount <TableSorter /></th>
                    <th>Ratio <TableSorter /></th>
                </tr>
                {#each Object.keys(stats.PhoneFormats) as k}
                    <tr>
                        <td>{k in phoneFormatsById ? phoneFormatsById[k].Name : "ID: " + k}</td>
                        <td data-sort={stats.PhoneFormats[k]}>{prettyNumber(stats.PhoneFormats[k])}</td>
                        <td data-sort={stats.Calls > 0 ? (stats.PhoneFormats[k] / stats.Calls) * 100 : 0}>{(stats.Calls > 0 ? (stats.PhoneFormats[k] / stats.Calls) * 100 : 0).toFixed(1)}%</td>
                    </tr>
                {/each}
            </Table>

            {#if result["request"].IncludeHangupCause}
                <row class="_title">
                    <b>Hangup Cause</b>
                </row>
                <Table margin="0px 10px 10px 10px" scroll={false}>
                    <tr slot="head">
                        <th>Cause <TableSorter /></th>
                        <th>Amount <TableSorter /></th>
                        <th>Ratio <TableSorter /></th>
                    </tr>
                    {#each Object.keys(stats.HangupCause)
                        .map((it) => parseInt(it))
                        .sort() as k}
                        <tr>
                            <td>{i18n.translate("telephony.hangup-cause." + k) || k}</td>
                            <td data-sort={stats.HangupCause[k]}>{prettyNumber(stats.HangupCause[k])}</td>
                            <td data-sort={stats.Calls > 0 ? (stats.HangupCause[k] / stats.Calls) * 100 : 0}>{(stats.Calls > 0 ? (stats.HangupCause[k] / stats.Calls) * 100 : 0).toFixed(1)}%</td>
                        </tr>
                    {/each}
                </Table>
            {/if}

            {#if result["request"].IncludeCcAc}
                <row class="_title">
                    <b>Country/Area Code</b>
                </row>
                <Table margin="0px 10px 10px 10px" scroll={false}>
                    <tr slot="head">
                        <th>CC <TableSorter /></th>
                        <th>AC <TableSorter /></th>
                        <th>Amount <TableSorter /></th>
                        <th>Ratio <TableSorter /></th>
                    </tr>
                    {#each Object.keys(stats.CcAc) as k}
                        {@const a = k.split("_")}
                        <tr>
                            <td>{a[0]}</td>
                            <td>{a[1]}</td>
                            <td data-sort={stats.CcAc[k]}>{prettyNumber(stats.CcAc[k])}</td>
                            <td data-sort={stats.Calls > 0 ? (stats.CcAc[k] / stats.Calls) * 100 : 0}>{(stats.Calls > 0 ? (stats.CcAc[k] / stats.Calls) * 100 : 0).toFixed(1)}%</td>
                        </tr>
                    {/each}
                    <!-- TODO: região -->
                </Table>
            {/if}

            {#if result["request"].IncludeCarriers}
                <row class="_title">
                    <b>Carriers</b>
                </row>
                <Table margin="0px 10px 10px 10px" scroll={false}>
                    <tr slot="head">
                        <th>Carrier <TableSorter /></th>
                        <th>Amount <TableSorter /></th>
                        <th>Ratio <TableSorter /></th>
                    </tr>
                    {#each Object.keys(stats.Carriers) as c}
                        <tr>
                            <td>{c in carriersById ? carriersById[c].Name : "ID: " + c}</td>
                            <td data-sort={stats.Carriers[c]}>{prettyNumber(stats.Carriers[c])}</td>
                            <td data-sort={stats.Calls > 0 ? (stats.Carriers[c] / stats.Calls) * 100 : 0}>{(stats.Calls > 0 ? (stats.Carriers[c] / stats.Calls) * 100 : 0).toFixed(1)}%</td>
                        </tr>
                    {/each}
                </Table>
            {/if}
        </scroll-content>
    </scroll>
</column>

<style>
    ._title {
        margin: 10px;
        text-align: center;
        align-self: center;
        min-height: auto;
    }
</style>
