<script lang="ts">
    export var value: boolean
</script>

<i class="fa-solid fa-{value ? 'check' : 'xmark'}" />

<style>
    .fa-check {
        color: rgb(108, 194, 144);
    }
    .fa-xmark {
        color: #e36363;
    }
</style>
