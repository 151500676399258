<script lang="ts">
    import { getContext, onMount } from "svelte"
    import NonIdealState from "../widgets/NonIdealState.svelte"
    import Spinner from "../widgets/Spinner.svelte"

    export var endpoint: string | string[]
    const i18n: any = getContext("i18n")

    async function load() {
        let eps = Array.isArray(endpoint) ? endpoint : [endpoint]
        let proms = []
        let _status
        for (let ep of eps) proms.push(fetch(ep))
        let res = await Promise.all(
            (
                await Promise.all(proms)
            ).map((r) => {
                _status = r.status
                return r.json()
            })
        )
        if (res.length == 1 && !Array.isArray(endpoint)) data = res[0]
        else data = res
        status = _status
    }

    export function reload(discrete = false) {
        if (!discrete) {
            status = 0
            data = null
        }

        load()
        // fetch(endpoint)
        //     .then((r) => {
        //         status = r.status
        //         return r.json()
        //     })
        //     .then((r) => (data = r))
    }

    onMount(function () {
        load()
        // fetch(endpoint)
        //     .then((r) => {
        //         status = r.status
        //         return r.json()
        //     })
        //     .then((r) => (data = r))
    })

    var status = 0,
        data = null
</script>

{#if status == 0}
    <Spinner />
{:else if status == 200}
    {#if data}
        <slot {data} {reload} />
    {/if}
{:else}
    <NonIdealState icon="fa-solid fa-triangle-exclamation" color="#e57979">
        {i18n.translate("loader.error", { status })}
    </NonIdealState>
{/if}
