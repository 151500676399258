<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { RemoteRtpProxy } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Thumb from "../../../widgets/Thumb.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: RemoteRtpProxy = null
</script>

<Loader endpoint="rtp/remote-proxy/list" let:data let:reload>
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>IP <TableSorter /></th>
                <th>{i18n.translate("dict.port")}</th>
                <th>{i18n.translate("field.active")} </th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each data as record}
                <tr>
                    <td>{record.Id}</td>
                    <td>{record.Name}</td>
                    <td>{record.Ip}</td>
                    <td>{record.Port}</td>
                    <td><Thumb value={record.IsEnabled} /></td>
                    <td>
                        <a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}><i class="fa-solid fa-pencil" /></a
                        >
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("rtp/remote-proxy/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new RemoteRtpProxy()
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("dict.connection")}</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={() =>
                modal.post(`rtp/remote-proxy/save`, formRecord).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)}</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}

            <label>
                {i18n.translate("dict.name")}*
                <input type="text" bind:value={formRecord.Name} required />
            </label>

            <label>
                IP*
                <input type="text" pattern={`\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}`} bind:value={formRecord.Ip} required />
            </label>

            <label>
                {i18n.translate("dict.port")}*
                <input type="number" min="10" max="65535" bind:value={formRecord.Port} required />
            </label>

            <label class="checkbox">
                <input type="checkbox" autocomplete="off" bind:checked={formRecord.IsEnabled} />
                {i18n.translate("field.active")}
            </label>

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
