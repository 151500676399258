import { writable } from "svelte/store"
import { arrayRemoveItem } from "./Utils"

interface Setup {
    path: string
    component: any
    //allows: string[]
}

interface Instance {
    hash: string
    setup: Setup
    version: number
}


export default class {
    setups: Record<string, Setup>
    instances: Instance[]
    current: Instance = null
    homeHash: string
    store: any
    constructor(setups: Record<string/*hash*/, Setup>, homeHash: string = "") {
        this.setups = setups
        this.instances = []
        this.homeHash = homeHash

        addEventListener("hashchange", () => this.onHashChange())
    }

    close(instance: Instance) {
        arrayRemoveItem(this.instances, instance)
        if (instance == this.current) {
            if (this.instances.length > 0) {
                this.current = this.instances[0]
                setTimeout(() => location.hash = "#" + this.current.hash)
            } else {
                this.current = null
                setTimeout(() => location.hash = "#" + this.homeHash)
            }
        }
        this.store.set(this)
    }

    reload(instance: Instance) {
        instance.version++
        this.store.set(this)
    }

    clear() {
        /*this.instances = []
        setTimeout(() => location.hash = "#" + this.homeHash)*/
        this.instances = [this.current]
        this.store.set(this)
    }

    writable() {
        if (!this.store)
            this.store = writable(this)
        setTimeout(() => this.onHashChange())
        return this.store
    }

    onHashChange() {
        // tenta redirecionar para a home
        if (!window.location.hash || window.location.hash == "#") {
            if (this.homeHash) {
                window.location.hash = this.homeHash
            } else {
                window.location.hash = Object.keys(this.setups)[0]
            }
            return
        }
        const hash = window.location.hash.substring(1)
        const setup = this.setups[hash]
        if (!setup) {
            return
        }

        const alreadyPage = this.instances.find(it => it.hash == hash)
        if (alreadyPage) {
            if (alreadyPage != this.current) {
                this.current = alreadyPage
                this.store.set(this)
            }
            return
        } else {
            let newInstance = { setup, hash, id: hash, version: 0 }
            this.current = newInstance
            this.instances.push(newInstance)
            this.store.set(this)
        }
    }
}