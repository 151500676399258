<script lang="ts" context="module">
    import { writable } from "svelte/store"

    var phoneFormats = writable(null)
</script>

<script lang="ts">
    import { onMount } from "svelte"
    import { prettyNumber } from "../../../lib/Utils"
    import Spinner from "../../../widgets/Spinner.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Dialog from "../../../widgets/modals/Dialog.svelte"

    export var onClose: () => void, stat: any, name: string, period: number

    onMount(function () {
        if ($phoneFormats == null) {
            fetch("phone-number-format/list")
                .then((r) => r.json())
                .then((r) => {
                    phoneFormats.set(Object.fromEntries(r.map((v) => [v.Id, v])))
                })
        }
    })
</script>

<Dialog bodyPadding="0px" onClose={() => onClose()}>
    <svelte:fragment slot="title">Destination Format</svelte:fragment>
    {#if $phoneFormats}
        {#if stat}
            {@const _stat = Array.isArray(stat.Calls) ? Object.fromEntries(Object.keys(stat).map((k) => [k, Array.isArray(stat[k]) ? stat[k][period] : stat[k]])) : stat}
            <b style="margin: 10px; text-align:center;">{name}</b>
            <Table margin="0px 10px 10px 10px">
                <tr slot="head">
                    <th>Format <TableSorter /></th>
                    <th>Amount <TableSorter /></th>
                    <th>Ratio <TableSorter /></th>
                </tr>
                {#each Object.keys(_stat.PhoneFormats) as k}
                    <tr>
                        <td>{k in $phoneFormats ? $phoneFormats[k].Name : "ID: " + k}</td>
                        <td data-sort={_stat.PhoneFormats[k]}>{prettyNumber(_stat.PhoneFormats[k])}</td>
                        <td data-sort={_stat.Calls > 0 ? (_stat.PhoneFormats[k] / _stat.Calls) * 100 : 0}>{(_stat.Calls > 0 ? (_stat.PhoneFormats[k] / _stat.Calls) * 100 : 0).toFixed(1)}%</td>
                    </tr>
                {/each}
            </Table>
        {/if}
    {:else}
        <Spinner />
    {/if}
</Dialog>
