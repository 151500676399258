<script>
    import { getContext, onDestroy, onMount } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { componentLoop, dateFormatDMYTime, moneyFormat } from "../../../lib/Utils"
    import Chart from "../../../widgets/Chart.svelte"
    import DashboardCard from "../../../widgets/DashboardCard.svelte"
    import Form from "../../../widgets/Form.svelte"
    import NonIdealState from "../../../widgets/NonIdealState.svelte"
    import Spinner from "../../../widgets/Spinner.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Thumb from "../../../widgets/Thumb.svelte"
    import Title from "../../../widgets/Title.svelte"
    import Dialog from "../../../widgets/modals/Dialog.svelte"
    import TabContent from "../../../widgets/tab/TabContent.svelte"
    import Tabs from "../../../widgets/tab/Tabs.svelte"

    var data,
        clients,
        query = { version: 0, year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
        transactions = null,
        addBalanceDialog = {
            client: null,
            wallet: null,
            value: 0,
            description: "",
        },
        chartToggle = new Set(),
        _history = null,
        tab = "transactions",
        colors = ["rgb(75, 192, 192)", "rgb(153, 102, 255)", "rgb(201, 203, 207)", "rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 159, 64)", "rgb(255, 205, 86)"]

    function load() {
        fetch("tool/financial/client-wallets")
            .then((r) => r.json())
            .then((r) => (data = r))
    }

    const pageManager = getContext("page-manager"),
        pageInstance = getContext("page-instance"),
        i18n = getContext("i18n"),
        modal = getContext("modal")

    componentLoop(onMount, onDestroy, 10000, function () {
        if ($pageManager.current == pageInstance) load()
    })
</script>

<!-- TODO: translate -->
{#if data}
    <!-- Modal: Add Balance -->
    {#if addBalanceDialog.wallet}
        <Dialog
            onClose={() => {
                addBalanceDialog.wallet = null
            }}
        >
            <span slot="title">{i18n.translate("financial.add-balance")}</span>

            <Form
                box={false}
                submit={() => {
                    modal.post("client/wallet/add-balance", { Id: addBalanceDialog.wallet.Id, Value: addBalanceDialog.value, Description: addBalanceDialog.description }).then((r) => {
                        if (r.status == 200) {
                            addBalanceDialog.value = 0
                            addBalanceDialog.description = ""
                            addBalanceDialog.wallet = null
                            query.version++
                        }
                    })
                }}
            >
                {#if addBalanceDialog.client}
                    <label>
                        {i18n.translate("dict.client")}
                        <input type="text" bind:value={addBalanceDialog.client.Name} readonly />
                    </label>
                {/if}

                <label>
                    {i18n.translate("financial.quantity")}
                    <input type="number" bind:value={addBalanceDialog.value} step="0.1" />
                </label>

                <label>
                    {i18n.translate("dict.description")}
                    <textarea bind:value={addBalanceDialog.description} />
                </label>

                <button type="submit" class="primary" disabled={!addBalanceDialog.value}>{i18n.translate("action.add")}</button>
            </Form>
        </Dialog>
    {/if}
    <Loader endpoint="client/list" let:data={clients}>
        {@const wallets = Object.values(data.Wallets).flat()}
        {@const walletsById = Object.fromEntries(wallets.map((v) => [v.Id, v]))}
        {@const clientsById = Object.fromEntries(clients.map((v) => [v.Id, v]))}

        <!-- Cards -->
        <column style="padding: 10px 10px 0px 10px;">
            <DashboardCard>
                <tr>
                    <td>{wallets.length}</td>
                    <td>${moneyFormat(wallets.reduce((p, c) => p + c["Balance"], 0))}</td>
                    <td>{wallets.reduce((p, c) => p + (c["Balance"] < 0 ? 1 : 0), 0)}</td>
                    <td>{wallets.reduce((p, c) => p + (c["Mode"] == "pre" ? 1 : 0), 0)}</td>
                    <td>{wallets.reduce((p, c) => p + (c["Mode"] == "post" ? 1 : 0), 0)}</td>
                </tr>
                <tr>
                    <td>{i18n.translate("financial.wallets")}</td>
                    <td>Total Balance</td>
                    <td>Debtors</td>
                    <td>Prepaid</td>
                    <td>Postpaid</td>
                </tr>
            </DashboardCard>
        </column>

        <!-- List -->
        <Table>
            <tr slot="head">
                <th>{i18n.translate("dict.client")}</th>
                <th>{i18n.translate("financial.wallet")}</th>
                <th>{i18n.translate("dict.type")}</th>
                <th>{i18n.translate("financial.balance")}</th>
                <th title={i18n.translate("financial.pre-max-debt")}>NBL</th>
                <th>{i18n.translate("field.active")}</th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each Object.keys(data.Wallets) as clientId (clientId)}
                {#each data.Wallets[clientId] as record, j (record.Id)}
                    <tr class:wallets-group-border={j == data.Wallets[clientId].length - 1}>
                        {#if j == 0}
                            <td style="border-bottom-width: 3px !important;" rowspan={data.Wallets[clientId].length}>{clientId in clientsById ? clientsById[clientId].Name : "ID: " + clientId}</td>
                        {/if}
                        <td>{record.Description}</td>
                        <td>{i18n.translate("financial.pay-mode." + record.Mode)}</td>
                        <td class:error-color={record.Balance < 0}>{moneyFormat(record.Balance)}</td>
                        <td>{record.Mode == "pre" ? record.PreMaxDebt : ""}</td>
                        <td><Thumb value={record.IsEnabled} /></td>
                        <td>
                            <a
                                href="#add-balance"
                                title={i18n.translate("financial.add-balance")}
                                on:click|preventDefault={() => {
                                    addBalanceDialog.client = clientsById[clientId]
                                    addBalanceDialog.wallet = record
                                }}
                            >
                                <i class="fa-solid fa-money-bill-transfer" />
                            </a>
                            <a
                                href="#add-chart"
                                on:click|preventDefault={() => {
                                    if (chartToggle.has(record.Id)) {
                                        chartToggle.delete(record.Id)
                                    } else {
                                        chartToggle.add(record.Id)
                                    }
                                    chartToggle = (() => chartToggle)()
                                    if (tab != "chart") tab = "chart"
                                }}
                                style={chartToggle.has(record.Id) ? "" : "color: inherit;"}
                            >
                                <i class="fa-solid fa-chart-line" />
                            </a>
                        </td>
                    </tr>
                {/each}
            {/each}
        </Table>

        <Tabs bind:selected={tab}>
            <svelte:fragment slot="tabs">
                <a href="#transactions">Transactions</a>
                <a href="#chart">Chart</a>
            </svelte:fragment>
            <TabContent name="transactions">
                <Title title="">
                    <svelte:fragment slot="right">
                        <label>
                            <row>
                                <input type="number" placeholder="Year" required bind:value={query.year} on:change={() => query.version++} />
                                <input type="number" placeholder="Month" required min="1" max="12" bind:value={query.month} on:change={() => query.version++} />
                            </row>
                        </label>
                    </svelte:fragment>
                </Title>

                {#key query.version}
                    <Loader endpoint="tool/financial/client-wallet-transactions/{query.year}/{query.month}" let:data={transactions}>
                        <Table>
                            <tr slot="head">
                                <th>{i18n.translate("dict.date")} <TableSorter /></th>
                                <th>{i18n.translate("financial.wallet")} <TableSorter /></th>
                                <th>{i18n.translate("financial.quantity")} ({i18n.translate("dict.total")}: {moneyFormat(transactions.reduce((p, c) => p + c.Amount, 0))}) <TableSorter /></th>
                                <th>{i18n.translate("dict.description")}</th>
                            </tr>

                            {#each transactions.sort((a, b) => (a.Created < b.Created ? 1 : -1)) as t}
                                <tr>
                                    <td data-sort={t.Created}>{dateFormatDMYTime(new Date(t.Created * 1000))}</td>
                                    <td>{t.ClientWalletId in walletsById ? walletsById[t.ClientWalletId].Description : "ID: " + t.ClientWalletId}</td>
                                    <td class:error-color={t.Amount < 0} class:success-color={t.Amount > 0}>{t.Amount > 0 ? "+" + t.Amount : t.Amount}</td>
                                    <td>{t.Description}</td>
                                </tr>
                            {/each}
                        </Table>
                    </Loader>
                {/key}
            </TabContent>

            <TabContent name="chart">
                <Title title="">
                    <svelte:fragment slot="right">
                        <label>
                            <row>
                                <input type="number" placeholder="Year" required bind:value={query.year} on:change={() => query.version++} />
                                <input type="number" placeholder="Month" required min="1" max="12" bind:value={query.month} on:change={() => query.version++} />
                            </row>
                        </label>
                    </svelte:fragment>
                </Title>
                {#if chartToggle.size > 0}
                    {#key query.version + "-" + Array.from(chartToggle).join("-")}
                        <Loader endpoint="tool/financial/client-wallets/history/{query.year}/{query.month}" let:data={_history}>
                            <row style="padding: 10px; flex: 1;">
                                <Chart
                                    setup={{
                                        type: "line",
                                        data: {
                                            labels: _history.Ticks.map((t) => dateFormatDMYTime(new Date(t * 1000))),
                                            datasets: Array.from(new Set(_history.Data.map((v) => Object.keys(v)).flat()))
                                                .filter((id) => chartToggle.has(parseInt(id)))
                                                .map((walletId, i) => ({
                                                    label: walletId in walletsById ? walletsById[walletId].Description : "ID: " + walletId,
                                                    data: _history.Ticks.map((_, j) => _history.Data[j][walletId] || 0),
                                                    fill: false,
                                                    borderWidth: 2,
                                                    pointHitRadius: 5,
                                                    pointRadius: 0,
                                                    borderColor: colors[i % colors.length],
                                                    tension: 0.1,
                                                })),
                                        },
                                        options: {
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            scales: {
                                                xAxes: {
                                                    ticks: {
                                                        autoSkip: true,
                                                        maxTicksLimit: 5,
                                                    },
                                                },
                                                x: { display: false },
                                                y: { beginAtZero: true, ticks: { stepSize: 1 } },
                                            },
                                        },
                                    }}
                                />
                            </row>
                        </Loader>
                    {/key}
                {:else}
                    <NonIdealState icon="fa-solid fa-wallet">Choose a wallet</NonIdealState>
                {/if}
            </TabContent>
        </Tabs>
    </Loader>
{:else}
    <Spinner />
{/if}

<style>
    .wallets-group-border td {
        border-bottom-width: 3px !important;
    }
</style>
