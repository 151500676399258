<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../lib/Loader.svelte"
    import { dateFormatDMYTime } from "../../lib/Utils"
    import { Client, UserRole } from "../../lib/golang-models/maindb"
    import Table from "../../widgets/Table.svelte"
    import TableSorter from "../../widgets/TableSorter.svelte"
    import Thumb from "../../widgets/Thumb.svelte"
    import Title from "../../widgets/Title.svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import PageFooter from "../elements/PageFooter.svelte"
    import Basic from "./client/Basic.svelte"
    import Techprefixes from "./client/Techprefixes.svelte"
    import Wallets from "./client/Wallets.svelte"
    import Tariffs from "./commons/Tariffs.svelte"
    import Trunks from "./commons/Trunks.svelte"

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal"),
        login: any = getContext("login")
    var loadKey = 0,
        viewRecord: Client = null
</script>

<Page>
    {#if viewRecord == null}
        <!-- LIST -->
        <Loader endpoint="client/list" let:data let:reload>
            <Table>
                <tr slot="head">
                    <th>ID <TableSorter /></th>
                    <th style="width: 100%;">{i18n.translate("dict.name")} <TableSorter /></th>
                    <th>{i18n.translate("dict.username")} </th>
                    <th>{i18n.translate("field.allow-login")} </th>
                    <th>{i18n.translate("field.allow-calls")} </th>
                    <th>{i18n.translate("field.channel-limit")} </th>
                    <th>{i18n.translate("dict.access")}</th>
                    <th>{i18n.translate("dict.actions")}</th>
                </tr>

                {#each data as record}
                    <tr>
                        <td>{record.Id}</td>
                        <td
                            ><a
                                href="#edit"
                                on:click|preventDefault={() => {
                                    viewRecord = record
                                }}>{record.Name}</a
                            ></td
                        >
                        <td>
                            {#if login.isMaster()}
                                <a href="login/redirect/client/{record.Id}" target="_self"><i class="fa-solid fa-right-to-bracket" /> {record.Username}</a>
                            {:else}
                                {record.Username}
                            {/if}
                        </td>
                        <td><Thumb value={record.AllowLogin} /></td>
                        <td><Thumb value={record.AllowCalls} /></td>
                        <td>{record.ChannelLimit || ""}</td>
                        <td>{(record.Accessed && dateFormatDMYTime(new Date(record.Accessed * 1000))) || ""}</td>
                        <td>
                            <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("client/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                        </td>
                    </tr>
                {/each}
            </Table>

            <PageFooter>
                <expander />
                <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>

                {#if login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT)}
                    <button
                        on:click={() => {
                            viewRecord = new Client()
                        }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("dict.client")}</button
                    >
                {/if}
            </PageFooter>
        </Loader>
    {:else if !viewRecord.Id}
        <!-- ADD -->
        <Basic
            title={i18n.translate("action.add") + " " + i18n.translate("dict.client")}
            original={viewRecord}
            mode="edit"
            onSave={(id) => {
                if (id) {
                    fetch("client/get/" + id)
                        .then((r) => r.json())
                        .then((r) => (viewRecord = r))
                } else {
                    viewRecord = null
                }
            }}
        >
            <button class="cancel" on:click={() => (viewRecord = null)}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
        </Basic>
    {:else}
        <!-- VIEW -->
        {#key viewRecord && viewRecord.Id}
            <Title title={viewRecord.Name}>
                <svelte:fragment slot="left">
                    <a href="#back" on:click|preventDefault={() => (viewRecord = null)}><i class="fa-solid fa-angles-left" /> {i18n.translate("action.back")}</a>
                </svelte:fragment>
            </Title>
            <Tabs selected="basic">
                <svelte:fragment slot="tabs">
                    <a href="#basic">{i18n.translate("dict.basic")}</a>
                    {#if login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT)}
                        <a href="#wallets">{i18n.translate("financial.wallets")}</a>
                        <a href="#tariffs">{i18n.translate("financial.tariffs")}</a>
                    {/if}
                    {#if login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT)}
                        <a href="#trunks">{i18n.translate("dict.trunks")}</a>
                        <a href="#techs">{i18n.translate("dict.techs")}</a>
                    {/if}
                </svelte:fragment>

                {#key loadKey}
                    <TabContent name="basic">
                        <Basic
                            original={viewRecord}
                            mode="view"
                            onSave={() => {
                                fetch("client/get/" + viewRecord.Id)
                                    .then((r) => r.json())
                                    .catch(() => (viewRecord = null))
                                    .then((r) => (viewRecord = r))
                            }}
                        />
                    </TabContent>
                    <TabContent name="wallets">
                        <Wallets client={viewRecord} />
                    </TabContent>
                    <TabContent name="tariffs">
                        <Tariffs context="Client" owner={viewRecord} />
                    </TabContent>
                    <TabContent name="trunks">
                        <Trunks context="Client" owner={viewRecord} />
                    </TabContent>
                    <TabContent name="techs">
                        <Techprefixes client={viewRecord} />
                    </TabContent>
                {/key}
            </Tabs>
        {/key}
    {/if}
</Page>
