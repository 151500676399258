<script lang="ts">
    import { onMount } from "svelte"
    import { AbrDynamicCidOptions, type DynamicCidOptions } from "../../../lib/golang-models/maindb"

    export var dynamicCidOptions: DynamicCidOptions

    onMount(function () {
        if (!dynamicCidOptions.Abr || !dynamicCidOptions.Abr.Mode) dynamicCidOptions.Abr = new AbrDynamicCidOptions()
    })
</script>

<!-- <Loader endpoint="phone-number-format/list" let:data={phoneFormats}>
    {@const systems = new Set(
        Object.keys(prioritiesByPhoneFormatId)
            .map((id) => phoneFormats.find((pf) => pf.Id == id))
            .filter((it) => it && it.TelephonySystem)
            .map((it) => it.TelephonySystem)
    )} -->
<!-- TODO: esconder sistemas que não estão nas prioridades do LCR -->
<!-- TODO: translate -->
<label>
    Dynamic caller-ID mode for ABR Telecom system*
    <select bind:value={dynamicCidOptions.Abr.Mode} required>
        <option value="mixed">Switch between landline and mobile caller-ID</option>
        <option value="mobile">Only mobile caller-ID</option>
        <option value="landline">Only landline caller-ID</option>
    </select>
</label>
