<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte"
    import Loader from "../../lib/Loader.svelte"
    import { componentLoop } from "../../lib/Utils"
    import Spinner from "../../widgets/Spinner.svelte"
    import Table from "../../widgets/Table.svelte"
    import TableSorter from "../../widgets/TableSorter.svelte"
    import Thumb from "../../widgets/Thumb.svelte"
    import Page from "../elements/Page.svelte"
    import PageFooter from "../elements/PageFooter.svelte"

    const pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance"),
        i18n: any = getContext("i18n")

    var allStatus: any = null
    componentLoop(onMount, onDestroy, 5000, function () {
        if ($pageManager.current == pageInstance) {
            fetch("softswitch/rtp/remote-proxies/status")
                .then((r) => r.json())
                .then((r) => (allStatus = r))
        }
    })
</script>

<Page>
    {#if allStatus && allStatus.Setup}
        <Loader endpoint="rtp/remote-proxy/list" let:data let:reload>
            <column class="expanded">
                <Table>
                    <tr slot="head">
                        <th>ID <TableSorter /></th>
                        <th>{i18n.translate("dict.name")} <TableSorter /></th>
                        <th>IP <TableSorter /></th>
                        <th>{i18n.translate("dict.port")}</th>
                        <th>{i18n.translate("field.allow-calls")}</th>
                        <th>Active</th>
                        <th>Sessions Capacity</th>
                        <th>Audio Port Range</th>
                        <th>Usage</th>
                        <th>Ping</th>
                    </tr>

                    {#each data as record}
                        {@const status = allStatus.Remotes[record.Id]}
                        <tr>
                            <td>{record.Id}</td>
                            <td>{record.Name}</td>
                            <td>{record.Ip}</td>
                            <td>{record.Port}</td>
                            {#if status}
                                {@const ping = new Date(status.Received).getTime() - new Date(status.Request.Ping).getTime()}
                                <td><Thumb value={status.Config.IsServerEnabled} /></td>
                                <td><Thumb value={status.IsServerEnabled} /></td>
                                <td>{status.Config.Sessions}</td>
                                <td>{status.Config.StartAudioPort} <i class="fas fa-arrow-right" /> {status.Config.StartAudioPort + status.Config.Sessions * 2}</td>
                                <td><b class:primary-color={status.Usage < 0.75} class:error-color={status.Usage > 0.75}>{(status.Usage * 100).toFixed(1) + "%"}</b></td>
                                <td>
                                    <b class:primary-color={ping <= 100} class:error-color={ping > 100}>{ping} ms</b>
                                </td>
                            {:else}
                                <td>loading...</td>
                                <td>loading...</td>
                                <td>loading...</td>
                                <td>loading...</td>
                                <td>loading...</td>
                                <td>loading...</td>
                            {/if}
                        </tr>
                    {/each}

                    {#each allStatus.Servers as status}
                        {@const ping = new Date(status.Received).getTime() - new Date(status.Request.Ping).getTime()}
                        <tr>
                            <td>-</td>
                            <td>[SERVER]</td>
                            <td>-</td>
                            <td>{status.Config.RequestPort}</td>
                            <td><Thumb value={allStatus.Setup.IsEnabled} /></td>
                            <td><Thumb value={allStatus.Setup.IsEnabled} /></td>
                            <td>{status.Config.Sessions}</td>
                            <td>{status.Config.StartAudioPort} <i class="fas fa-arrow-right" /> {status.Config.StartAudioPort + status.Config.Sessions * 2}</td>
                            <td><b class:primary-color={status.Usage < 0.75} class:error-color={status.Usage > 0.75}>{(status.Usage * 100).toFixed(1) + "%"}</b></td>
                            <td>
                                <b class:primary-color={ping <= 100} class:error-color={ping > 100}>{ping} ms</b>
                            </td>
                        </tr>
                    {/each}
                </Table>
            </column>
            <PageFooter>
                <expander />
                <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            </PageFooter>
        </Loader>
    {:else}
        <Spinner />
    {/if}
</Page>
