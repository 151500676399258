<script lang="ts">
    import { getContext } from "svelte"
    import type { Login } from "../../lib/models"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import Consolidation from "./minutes-report/Consolidation.svelte"
    import Reports from "./minutes-report/Reports.svelte"

    const i18n: any = getContext("i18n"),
        login: Login = getContext("login")
</script>

<Page>
    {#if login.isMaster()}
        <Tabs selected="reports">
            <svelte:fragment slot="tabs">
                <a href="#reports">Reports</a>
                <a href="#consolidation">Consolidation</a>
            </svelte:fragment>
            <TabContent name="consolidation">
                <Consolidation />
            </TabContent>
            <TabContent name="reports">
                <Reports />
            </TabContent>
        </Tabs>
    {:else}
        <Reports />
    {/if}
</Page>
