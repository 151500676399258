<script lang="ts">
    import { afterUpdate, setContext } from "svelte"
    import { writable } from "svelte/store"

    export var selected: string,
        close: (name: string) => void = null,
        add: () => void = null

    var tab = writable(selected),
        tabsElement

    setContext("tab", tab)

    function update() {
        if (!tabsElement) return
        function bind(el) {
            if (!el.getAttribute("href")) {
                return
            }
            el._name = el.getAttribute("href").substring(1)
            el.removeAttribute("href")

            el.onmousedown = function (e) {
                if (e.which == 2) {
                    close && close(el._name)
                    e.returnValue = false
                    e.cancelBubble = false
                    e.stopPropagation()
                    e.preventDefault()
                    return false
                }
            }
            el.onclick = function (e) {
                for (var u = 0; u < tabsElement.children.length; u++) {
                    tabsElement.children[u].classList.remove("selected")
                }
                //tab.set(el.getAttribute("href").substring(1))
                tab.set(el._name)
                el.classList.add("selected")
                selected = el._name
                e.preventDefault()
            }
        }
        for (var i = 0; i < tabsElement.children.length; i++) {
            let el = tabsElement.children[i]
            if (el.tagName == "A") {
                bind(el)
                //if (el.getAttribute("href").substring(1) == init) el.classList.add("selected")
                if (el._name == selected) el.classList.add("selected")
                else el.classList.remove("selected")
            }
        }
        tab.set(selected)
    }

    afterUpdate(function () {
        update()
    })
</script>

<column class="expanded">
    <row style="border-bottom: var(--standard-border); padding: 10px 10px; padding-bottom: 0px; background-image: linear-gradient(transparent 85%, #1d3d6424 100%);">
        <row class="tabs" bind:this={tabsElement}>
            <slot name="tabs" />
        </row>
        {#if add}
            <a href="#__add__" on:click|preventDefault={(e) => add()} style="margin:auto 10px;"><i class="fa-solid fa-plus" /></a>
        {/if}
        <expander />
        <slot name="menu" />
    </row>

    <column class="expanded">
        <slot />
    </column>
</column>

<style>
    .tabs :global(a) {
        cursor: pointer;
    }
    .tabs :global(a),
    .tabs :global(a:visited) {
        color: #5e5e5e;
        text-decoration: none;
        padding: 5px 15px;
        font-size: 0.95rem;
        font-weight: bold;
        /* font-family: MainBlack; */

        border: var(--standard-border);
        border-bottom: none;
    }

    .tabs :global(a):not(:first-child) {
        border-left: none;
    }

    .tabs :global(a:hover) {
        color: #b3b3b3;
    }
    .tabs :global(a:active) {
        color: var(--theme-color-b);
    }
    .tabs :global(a.selected) {
        color: var(--theme-color-a);
        background-color: white;
        /* border-bottom: 2px solid var(--theme-color-a); */
    }
</style>
