<!-- <script lang="ts">
    export var value: string,
        label: string,
        color = "var(--theme-color-a)"
</script>

<column class="card expanded">
    <div><b style="color: {color}; font-size: 1.4rem;">{value}</b></div>

    <div>{label}</div>
</column> -->

<table style="overflow:auto;">
    <tbody>
        <slot />
    </tbody>
</table>

<style>
    table {
        background-image: linear-gradient(white, #f0f0f0);
        padding: 8px 0px;
        border: var(--standard-border);
        border-radius: 5px;
    }

    table :global(tr:first-child td) {
        width: 9.090909090909092%;
        font-weight: 700;
        color: var(--theme-color-a);
    }
    table :global(tr:last-child td) {
        padding: 0px 10px;
    }
    table :global(td:not(:last-child)) {
        border-right: 1px solid var(--theme-color-a);
    }
    table :global(td) {
        padding: 0px 10px;
        font-size: 0.9rem;
        white-space: nowrap;
    }
</style>
