const toastDetails = {
    timer: 5000,
    success: {
        icon: "fa-circle-check",
    },
    error: {
        icon: "fa-circle-xmark",
    },
    warning: {
        icon: "fa-triangle-exclamation",
    },
    info: {
        icon: "fa-circle-info",
    },
    random: {
        icon: "fa-solid fa-star",
    },
};

(window as any).removeToast = function (toast) {
    toast.classList.add("hide")
    if (toast.timeoutId) clearTimeout(toast.timeoutId)
    setTimeout(() => toast.remove(), 500)
}

export function createToast(intent: "success" | "error" | "warning" | "info" | "random", text: string) {
    const { icon } = toastDetails[intent]
    const toast: any = document.createElement("li")
    toast.className = `toast ${intent}`
    toast.innerHTML = `<div class="column">
                           <i class="fa-solid ${icon}"></i>
                           <span>${text}</span>
                        </div>
                        <i class="fa-solid fa-xmark" onclick="removeToast(this.parentElement)"></i>`
    document.querySelector("#notifications").appendChild(toast)
    toast.timeoutId = setTimeout(() => (window as any).removeToast(toast), toastDetails.timer)
}