<script>
    import { getContext, setContext } from "svelte"
    import { writable } from "svelte/store"
    import Context from "../lib/Context.svelte"
    import PageManager from "../lib/PageManager"
    import { isMobile } from "../lib/Utils"
    import { UserRole } from "../lib/golang-models/maindb"
    import ActivityLogs from "./admin/ActivityLogs.svelte"
    import Users from "./admin/Users.svelte"
    import DashboardCalls from "./dashboards/Calls.svelte"
    import DashboardRtp from "./dashboards/RTP.svelte"
    import DashboardSeller from "./dashboards/Seller.svelte"
    import Sidebar from "./elements/Sidebar.svelte"
    import Toptabs from "./elements/Toptabs.svelte"
    import Advanced from "./softswitch/Advanced.svelte"
    import Clients from "./softswitch/Clients.svelte"
    import LCRs from "./softswitch/LCRs.svelte"
    import Providers from "./softswitch/Providers.svelte"
    import Resources from "./softswitch/Resources.svelte"
    import Treatments from "./softswitch/Treatments.svelte"
    import CallsExport from "./tools/CallsExport.svelte"
    import CallExtract from "./tools/CallsExtract.svelte"
    import ClientCdrDownloader from "./tools/ClientCdrDownloader.svelte"
    import Debug from "./tools/Debug.svelte"
    import Financial from "./tools/Financial.svelte"
    import MinutesReport from "./tools/MinutesReport.svelte"
    import Utils from "./tools/Utils.svelte"

    const i18n = getContext("i18n"),
        login = getContext("login")

    var showSidebar = writable(false)
    setContext("show-sidebar", showSidebar)

    const pageManager = new PageManager({
        // Dashboards
        ...((login.Type == "client" || login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT)) && {
            "dashboards.calls": { path: i18n.translate("dashboards.calls.path"), component: DashboardCalls /*, allows: ["user", "client"]*/ },
        }),
        ...(login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && {
            "dashboards.seller": { path: i18n.translate("dashboards.seller.path"), component: DashboardSeller /*, allows: ["user"]*/ },
            "dashboards.rtp": { path: i18n.translate("dashboards.rtp.path"), component: DashboardRtp /*, allows: ["user"]*/ },
        }),

        // Tools
        ...((login.Type == "client" || (login.Type == "user" && login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT))) && {
            "tools.calls-extract": { path: i18n.translate("tools.calls-extract.path"), component: CallExtract },
            "tools.calls-export": { path: i18n.translate("tools.calls-export.path"), component: CallsExport },
        }),
        ...(login.Type == "user" &&
            login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && {
                "tools.minutes-report": { path: i18n.translate("tools.minutes-report.path"), component: MinutesReport /*, allows: ["user"]*/ },
            }),
        ...(login.Type == "user" &&
            login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT) && {
                "tools.financial": { path: i18n.translate("tools.financial.path"), component: Financial },
            }),
        ...((login.Type == "user" || login.Type == "client") && {
            "tools.utils": { path: i18n.translate("tools.utils.path"), component: Utils },
        }),
        ...(login.Type == "client" && {
            "tools.client-cdr-downloader": { path: i18n.translate("tools.client-cdr-downloader.path"), component: ClientCdrDownloader /*, allows: ["client"] */ },
        }),
        ...(login.isMaster() && {
            "tools.debug": { path: i18n.translate("tools.debug.path"), component: Debug },
        }),

        // Softswitch
        ...(login.Type == "user" &&
            login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && {
                "softswitch.clients": { path: i18n.translate("softswitch.clients.path"), component: Clients /*, allows: ["user"] */ },
                "softswitch.providers": { path: i18n.translate("softswitch.providers.path"), component: Providers /*, allows: ["user"]*/ },
                "softswitch.lcrs": { path: i18n.translate("softswitch.lcrs.path"), component: LCRs /*, allows: ["user"]*/ },
                "softswitch.resources": { path: i18n.translate("softswitch.resources.path"), component: Resources /*, allows: ["user"]*/ },
                "softswitch.treatments": { path: i18n.translate("softswitch.treatments.path"), component: Treatments /*, allows: ["user"]*/ },
            }),
        ...(login.isMaster() && {
            "softswitch.advanced": { path: i18n.translate("softswitch.advanced.path"), component: Advanced },
        }),
        // Admin
        ...(login.Type == "user" &&
            login.containsAnyUserRoles(UserRole.USER_ROLE_ADMIN) && {
                "admin.users": { path: i18n.translate("admin.users.path"), component: Users /*, allows: ["user"]*/ },
                "admin.activity-logs": { path: i18n.translate("admin.activity-logs.path"), component: ActivityLogs /*, allows: ["user"]*/ },
            }),
    }).writable()
    setContext("page-manager", pageManager)
</script>

<row style="border: 1.5px solid #575757c9; flex: 1;">
    <Sidebar />
    <column style="flex: 1; display: {!isMobile || !$showSidebar ? 'flex' : 'none'}">
        <Toptabs />
        <column style="background-color: white; flex: 1;">
            {#each $pageManager.instances as instance (instance.id)}
                <column style="flex: 1; display:{$pageManager.current == instance ? 'flex' : 'none'};">
                    {#key instance.version}
                        <Context value={instance} name="page-instance">
                            <svelte:component this={instance.setup.component} />
                        </Context>
                    {/key}
                </column>
            {/each}
        </column>
    </column>
</row>
