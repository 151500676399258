<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte"
    import Loader from "../../../../lib/Loader.svelte"
    import { componentLoop } from "../../../../lib/Utils"
    import { type Client } from "../../../../lib/golang-models/maindb"
    import Spinner from "../../../../widgets/Spinner.svelte"
    import CallStatsTable from "../../../elements/call-stats/CallStatsTable.svelte"
    import Charts from "../commons/Charts.svelte"
    import Wallets from "../commons/Wallets.svelte"

    export var client: Client, showFinancial: boolean, period: number
    var stats,
        deps,
        statFocus: null | string = null

    function load() {
        fetch("softswitch/dashboard/main/client/" + client.Id + (statFocus != null ? "?focus=" + statFocus : ""))
            .then((r) => r.json())
            .then((r) => (stats = r))
    }

    const pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance")

    componentLoop(onMount, onDestroy, 6000, function () {
        if ($pageManager.current == pageInstance) {
            load()
        }
    })

    var endpoints = ["client/techprefix/list/" + client.Id]
</script>

<column class="expanded" style="padding: 10px;">
    <Loader endpoint={endpoints} let:data={deps}>
        {@const techsById = Object.fromEntries(deps[0].map((v) => [v.Id, v]))}

        {#if stats}
            <Charts {period} {showFinancial} showHangupCause={false} stats={stats.Client} />

            <column class="expand">
                <column style="flex:1.5;">
                    <CallStatsTable
                        onStatFocus={(k) => {
                            statFocus = k
                            if (k) load()
                        }}
                        {period}
                        header="Techprefix"
                        data={Object.keys(stats.Techprefixes).map((k) => ({ key: k, id: k.substring(k.lastIndexOf("_") + 1), ...stats.Techprefixes[k] }))}
                        dict={techsById}
                        {showFinancial}
                        showHangupCause={true}
                    />
                </column>
                <double-space />
                {#if showFinancial}
                    <Wallets endpoint="/client/wallet/list/{client.Id}" />
                {/if}
            </column>
        {:else}
            <Spinner />
        {/if}
    </Loader>
</column>
