<script>
    import { getContext, onDestroy, onMount } from "svelte"
    import { createToast } from "../../lib/Toast"
    import { componentLoop, isMobile } from "../../lib/Utils"
    import { UserRole } from "../../lib/golang-models/maindb"
    import CountryFlags from "../../widgets/CountryFlags.svelte"

    const login = getContext("login"),
        i18n = getContext("i18n"),
        pageManager = getContext("page-manager"),
        showSidebar = getContext("show-sidebar")

    const menuByLoginType = {
        user: [
            login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT, UserRole.USER_ROLE_SELLER) && {
                name: "Dashboards",
                items: [
                    login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && "dashboards.calls", //
                    login.containsAnyUserRoles(UserRole.USER_ROLE_SELLER) && "dashboards.seller",
                    login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && "dashboards.rtp",
                ].filter((it) => it),
            },
            login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT, UserRole.USER_ROLE_FINANCIAL_SUPPORT) && {
                name: i18n.translate("tools.title"),
                items: [
                    login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && "tools.calls-extract", //
                    login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && "tools.calls-export",
                    login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && "tools.minutes-report",
                    login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT) && "tools.financial",
                    "tools.utils",
                    login.isMaster() && "tools.debug",
                ].filter((it) => it),
            },
            login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT) && {
                name: i18n.translate("softswitch.title"),
                items: ["softswitch.clients", "softswitch.providers", "softswitch.lcrs", "softswitch.resources", "softswitch.treatments", login.isMaster() && "softswitch.advanced"].filter((it) => it),
            },
            login.containsAnyUserRoles(UserRole.USER_ROLE_ADMIN) && {
                name: i18n.translate("admin.title"),
                items: ["admin.users", "admin.activity-logs"],
            },
        ].filter((it) => it),
        client: [
            {
                name: "Dashboards",
                items: ["dashboards.calls"],
            },
            {
                name: i18n.translate("tools.title"),
                items: ["tools.calls-extract", "tools.client-cdr-downloader", "tools.utils"],
            },
        ].filter((it) => it),
    }

    const menu = menuByLoginType[login.Type]

    for (const ul of menu) {
        ul.isOpen = ul.items.find((li) => window.location.hash == "#" + li) != null
    }

    // Softswitch
    var serverState = null
    function updateSoftswitch() {
        fetch("softswitch/update").then((r) => {
            if (r.status == 200) {
                loadSoftswitchChanges()
                createToast("success", i18n.translate("post.success"))
            } else {
                createToast("error", i18n.translate("post.error"))
            }
        })
    }
    function loadSoftswitchChanges() {
        fetch("/server/state")
            .then((r) => r.json())
            .then((r) => (serverState = r))
    }
    if (login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT)) {
        componentLoop(onMount, onDestroy, 8000, function () {
            loadSoftswitchChanges()
        })
    }

    if (isMobile) {
        onMount(function () {
            window.addEventListener("popstate", (e) => {
                if ($showSidebar) {
                    showSidebar.set(false)
                    history.go(1)
                    return false
                }
            })
        })
    }
</script>

{#if !isMobile || $showSidebar == true}
    <column style="border-right: 1.5px solid rgb(181, 181, 181); width: {isMobile ? '100%' : '220px'};">
        {#if isMobile}
            <img src="custom-platform/logo-login.png" alt="logo" style="object-fit: contain; height: 30px; background-image: linear-gradient(rgb(255, 255, 255) 95%, rgb(217, 217, 217)); border-bottom: var(--standard-border); padding: 10px;" />
        {:else}
            <img src="custom-platform/logo-menu.png" alt="logo" style="object-fit: cover; height: 140px; background-image: linear-gradient(#eee 95%, #d9d9d9); border-bottom: var(--standard-border);" />
        {/if}

        <column style="border-bottom: var(--standard-border); min-height:auto; background-image: linear-gradient(white, #ececec);">
            {#if login.Type == "user"}
                <!-- Notification Bar -->
                <row style="margin: 5px 10px;" class="notification-bar">
                    <expander />
                    {#if serverState}
                        <span title={"CPUs: " + serverState.Hardware.CpuCount} class:error-color={serverState.Hardware.CpuUsage > 0.6}><i class="fa-solid fa-microchip" /> {Math.floor(serverState.Hardware.CpuUsage * 100) + "%"}</span>

                        {#if login.containsAnyUserRoles(UserRole.USER_ROLE_SOFTSWITCH_SUPPORT)}
                            <a title={i18n.translate("update-softswitch-button")} href="#btn" on:click|preventDefault={() => updateSoftswitch()}><i class="fa-solid fa-rocket" />{serverState.Changes > 0 ? ` (${serverState.Changes})` : ""}</a>
                        {/if}
                    {/if}
                </row>
            {/if}

            <!-- User/Client Name -->
            <row style="margin: 5px 10px;">
                <row>
                    <i class="fa-solid fa-user" style="color: var(--theme-color-a);" />
                    <space />
                    {login.Data.Name}
                </row>

                <expander />
                <a href="login/exit{location.search}" style="color: inherit !important;">
                    <i class="fa-solid fa-arrow-right-from-bracket" />
                    {i18n.translate("login.exit-buttom")}
                </a>
            </row>
        </column>

        <scroll>
            <scroll-content>
                <column class="menu">
                    {#each menu as ul}
                        {@const hasSelected = ul.items.find((li) => $pageManager.current && $pageManager.current.hash == li) != null}
                        <column class:selected={hasSelected} class:open={hasSelected || ul.isOpen}>
                            <a href="#btn" on:click|preventDefault={() => (ul.isOpen = !ul.isOpen)}>
                                <i class="fa-solid fa-caret-{hasSelected || ul.isOpen ? 'down' : 'right'}" />
                                {ul.name}
                            </a>
                            <column>
                                {#each ul.items as li}
                                    {@const isSelected = $pageManager.current && $pageManager.current.hash == li}
                                    <a href="#{li}" class:selected={isSelected}>
                                        {$pageManager.setups[li].path.split("/").pop()}
                                    </a>
                                {/each}
                            </column>
                        </column>
                    {/each}
                </column>
            </scroll-content>
        </scroll>

        <expander />
        <CountryFlags />
    </column>
{/if}

<style>
    .notification-bar > * {
        margin-left: 10px;
    }
    .notification-bar > span > i {
        margin: auto 0px;
    }

    .menu {
        border: var(--standard-border);
        border-bottom-left-radius: 5px;
        border-top: none;
        margin-left: 10px;
        border-right: none;
        background-image: linear-gradient(180deg, #c7c6c6 0%, #dddddd 20%);
    }

    .menu i {
        width: 12px;
    }

    .menu a {
        text-decoration: none;
        padding: 5px;
        color: inherit;
    }

    .menu > column > a {
        border-top: var(--standard-border);
        font-family: MainBlack;
    }

    .menu > column > column > a:not(:last-child) {
        border-bottom: var(--standard-border);
    }

    .menu > column.selected {
        background: #ffffff85;
    }
    .menu > column.selected a.selected {
        color: var(--theme-color-a);
        font-weight: bold;
        /* border-bottom-color: var(--theme-color-a); */
    }

    .menu > column.open.selected > a {
        background-color: var(--theme-color-a);
        color: whitesmoke;
    }

    .menu > column.open.selected > a {
        pointer-events: none;
    }
    .menu > column:not(.open) > column {
        display: none;
    }

    .menu > column:first-child > a {
        border-top: none;
    }
    .menu > column:last-child:not(.open) > a {
        border-bottom: none;
    }

    .menu > column > column {
        padding-left: 10px;
    }
</style>
