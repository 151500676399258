<script lang="ts">
    import { getContext, onMount } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { arrayRemoveItem } from "../../../lib/Utils"
    import { Lcr, LcrPriority, ProviderTechprefix } from "../../../lib/golang-models/maindb"
    import Spinner from "../../../widgets/Spinner.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TabContent from "../../../widgets/tab/TabContent.svelte"
    import Tabs from "../../../widgets/tab/Tabs.svelte"

    export var prioritiesByPhoneFormatId: Record<any, LcrPriority[][]> = null,
        lcr: Lcr = null
    const i18n: any = getContext("i18n")
    var deps: any,
        techsByProviderId: Record<any, ProviderTechprefix[]>,
        techsById: Record<any, ProviderTechprefix>,
        selected: any = ""

    function makeTechsByProviderId(techs) {
        var dict = {}
        for (let tech of techs) {
            if (tech.ProviderId in dict) dict[tech.ProviderId].push(tech)
            else dict[tech.ProviderId] = [tech]
        }
        return dict
    }

    onMount(function () {
        fetch(`provider/techprefix/list-all`)
            .then((r) => r.json())
            .then((techs) => {
                techsByProviderId = makeTechsByProviderId(techs)
                techsById = Object.fromEntries(techs.map((it) => [it.Id, it]))
                if (lcr) {
                    fetch("lcr/priority/list/" + lcr.Id)
                        .then((r) => r.json())
                        .then((r) => {
                            let o = {}
                            for (let priority of r) {
                                if (priority.ProviderTechprefixId in techsById) {
                                    let tech = techsById[priority.ProviderTechprefixId]
                                    if (tech.PhoneNumberFormatId in o) {
                                        if (priority.Priority in o[tech.PhoneNumberFormatId]) o[tech.PhoneNumberFormatId][priority.Priority].push(priority)
                                        else o[tech.PhoneNumberFormatId][priority.Priority] = [priority]
                                    } else {
                                        o[tech.PhoneNumberFormatId] = []
                                        o[tech.PhoneNumberFormatId][priority.Priority] = [priority]
                                    }
                                } else {
                                    return
                                }
                            }
                            for (let k in o) {
                                o[k] = o[k].filter((it) => it)
                            }
                            prioritiesByPhoneFormatId = o
                        })
                } else {
                    prioritiesByPhoneFormatId = {}
                }
            })
    })
</script>

<column class="expanded" style="min-height: 400px;">
    {#if prioritiesByPhoneFormatId}
        <Loader endpoint={["provider/list", "phone-number-format/list"]} let:data={deps}>
            {@const providers = deps[0]}
            {@const formats = deps[1]}
            {@const formatsById = Object.fromEntries(deps[1].map((it) => [it.Id, it]))}

            <row style="overflow:auto; white-space:nowrap;">
                {#each formats as it}
                    <label class="checkbox" style="min-width: auto;">
                        <input
                            type="checkbox"
                            checked={it.Id in prioritiesByPhoneFormatId}
                            on:change={(e) => {
                                if (e.currentTarget.checked) prioritiesByPhoneFormatId[it.Id] = []
                                else delete prioritiesByPhoneFormatId[it.Id]
                                prioritiesByPhoneFormatId = (() => prioritiesByPhoneFormatId)()
                            }}
                        />
                        {it.Name}
                    </label>
                    <space />
                {/each}
            </row>

            {@const formatIds = Object.keys(prioritiesByPhoneFormatId)}
            <!-- {#key formatIds.join("-")} -->
            <!-- <Tabs selected={"format-" + formatIds[0]}> -->
            <Tabs bind:selected>
                <svelte:fragment slot="tabs">
                    {#each formatIds as formatId}
                        <a href="#format-{formatId}">{formatsById[formatId].Name}</a>
                    {/each}
                </svelte:fragment>

                {#each formatIds as formatId (formatId)}
                    {@const priorities = prioritiesByPhoneFormatId[formatId]}
                    <TabContent name="format-{formatId}">
                        <double-space />
                        {#if priorities.length > 0}
                            <Table>
                                <tr slot="head">
                                    <th style="width: 20px;">{i18n.translate("dict.priority")}</th>
                                    <th>{i18n.translate("dict.provider")}</th>
                                    <th>Techprefix</th>
                                    <th>{i18n.translate("dict.actions")}</th>
                                </tr>

                                {#each priorities as priority, n (priorities.length + "-" + priority.length + "-" + n)}
                                    {#each priority as lb, i}
                                        {@const techs = lb.ProviderTechprefixId ? techsByProviderId[techsById[lb.ProviderTechprefixId].ProviderId].filter((it) => it.PhoneNumberFormatId.toString() == formatId) : []}
                                        <tr class:priority-border={i == priority.length - 1}>
                                            {#if i == 0}
                                                <td rowspan={priority.length}>
                                                    {n + 1}°&nbsp;
                                                    {#if n > 0}
                                                        <a
                                                            href="#up"
                                                            on:click|preventDefault={() => {
                                                                let a = priorities
                                                                a[n] = priorities[n - 1]
                                                                a[n - 1] = priority
                                                                prioritiesByPhoneFormatId = (() => prioritiesByPhoneFormatId)()
                                                            }}><i class="fa-solid fa-caret-up" /></a
                                                        >
                                                    {/if}
                                                    {#if n < priorities.length - 1}
                                                        <a
                                                            href="#down"
                                                            on:click|preventDefault={() => {
                                                                let a = priorities
                                                                a[i] = a[i + 1]
                                                                a[i + 1] = priority
                                                                prioritiesByPhoneFormatId = (() => prioritiesByPhoneFormatId)()
                                                            }}><i class="fa-solid fa-caret-down" /></a
                                                        >
                                                    {/if}
                                                </td>
                                            {/if}
                                            <td>
                                                <select
                                                    on:change={(e) => {
                                                        lb.ProviderTechprefixId = techsByProviderId[e.currentTarget.value] ? techsByProviderId[e.currentTarget.value].find((it) => it.PhoneNumberFormatId.toString() == formatId).Id : 0
                                                    }}
                                                    value={techs.length > 0 ? techsById[lb.ProviderTechprefixId].ProviderId : ""}
                                                    required
                                                >
                                                    <option value="" disabled>Select an item</option>
                                                    {#each providers as provider (provider.Id)}
                                                        {#if provider.Id in techsByProviderId && techsByProviderId[provider.Id].find((it) => it.PhoneNumberFormatId.toString() == formatId)}
                                                            <option value={provider.Id}>{provider.Name}</option>
                                                        {/if}
                                                    {/each}
                                                </select>
                                            </td>
                                            <td>
                                                {#if lb.ProviderTechprefixId}
                                                    <select bind:value={lb.ProviderTechprefixId} required>
                                                        {#each techs as tech (tech.Id)}
                                                            <option value={tech.Id}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                                                        {/each}
                                                    </select>
                                                {/if}
                                            </td>
                                            <td>
                                                <a
                                                    href="#remove"
                                                    on:click|preventDefault={() => {
                                                        arrayRemoveItem(priority, priority[i])
                                                        if (priority.length == 0) arrayRemoveItem(priorities, priorities[n])
                                                        prioritiesByPhoneFormatId = (() => prioritiesByPhoneFormatId)()
                                                    }}><i class="fa-solid fa-trash" /></a
                                                >
                                                <a
                                                    href="#add-lb"
                                                    title="Add load balance"
                                                    on:click|preventDefault={() => {
                                                        priority.splice(i + 1, 0, new LcrPriority())
                                                        prioritiesByPhoneFormatId = (() => prioritiesByPhoneFormatId)()
                                                    }}><i class="fa-solid fa-plus" /></a
                                                >
                                            </td>
                                        </tr>
                                    {/each}
                                {/each}
                            </Table>
                        {/if}
                        <button
                            type="button"
                            style="margin: 10px;"
                            on:click={() => {
                                priorities.push([new LcrPriority()])
                                prioritiesByPhoneFormatId = (() => prioritiesByPhoneFormatId)()
                            }}>{i18n.translate("action.add")} {i18n.translate("dict.priority")}</button
                        >
                    </TabContent>
                {/each}
            </Tabs>
            <!-- {/key} -->
        </Loader>
    {:else}
        <Spinner />
    {/if}
</column>

<style>
    .priority-border td {
        border-bottom-width: 3px !important;
    }
</style>
