<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { stringifyCadence } from "../../../lib/Utils"
    import { AbrDynamicCidOptions, CidList, ClientTechprefix, ClientWallet, DynamicCidOptions, Lcr, LcrPriority, NullInt64, Tariff, UserRole, type Client } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Thumb from "../../../widgets/Thumb.svelte"
    import TabContent from "../../../widgets/tab/TabContent.svelte"
    import Tabs from "../../../widgets/tab/Tabs.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"
    import PhoneNumberRules from "../../elements/telephony/PhoneNumberRules.svelte"
    import DynamicCidOptionsInput from "../commons/DynamicCidOptionsInput.svelte"
    import LcrPriorities from "../commons/LcrPriorities.svelte"

    export var client: Client

    var wallets: ClientWallet[],
        cidLists: CidList[],
        useExclusive: boolean = false,
        globalLcrs: Lcr[],
        tariffs: Tariff[],
        deps: any,
        lcr: Lcr

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal"),
        login: any = getContext("login"),
        rtpModes = {
            p2p: "P2P",
            "any-proxy": "Remote or local proxy",
            "local-proxy": "Local proxy",
            "remote-proxy": "Remote proxy",
        }

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: ClientTechprefix = null,
        prioritiesByPhoneFormatId: Record<any, LcrPriority[][]> = null

    const financial = login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT)
    var endpoints = [`lcr/list`, `client/techprefix/list/${client.Id}`, "classifier/list", "configs/softswitch/router"]
    if (financial) endpoints.push(`tariff/list/client/${client.Id}`, `client/wallet/list/${client.Id}`)
</script>

<Loader endpoint={endpoints} let:data={deps} let:reload>
    {@const lcrsById = Object.fromEntries(deps[0].map((v) => [v.Id, v]))}
    {@const globalLcrs = deps[0]}
    {@const techs = deps[1]}
    {@const classifiers = deps[2]}
    {@const platform = deps[3]}
    {@const tariffsById = financial && Object.fromEntries(deps[4].map((v) => [v.Id, v]))}
    {@const tariffs = financial && deps[4]}
    {@const walletsById = financial && Object.fromEntries(deps[5].map((v) => [v.Id, v]))}
    {@const wallets = financial && deps[5]}
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>Techprefix <TableSorter /></th>
                {#if financial}
                    <th>{i18n.translate("financial.tariff")}</th>
                {/if}
                <th>LCR <TableSorter /></th>
                {#if financial}
                    <th>{i18n.translate("financial.wallet")} <TableSorter /></th>
                {/if}
                <th>{i18n.translate("field.rtp-mode")} <TableSorter /></th>
                <th>{i18n.translate("field.active")} </th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each techs as record}
                {@const wallet = walletsById[record.WalletId]}
                {@const tariff = tariffsById[record.TariffId]}
                {@const lcr_ = !!record.GlobalLcrId.Int64 ? lcrsById[record.GlobalLcrId.Int64] : null}
                <tr>
                    <td>{record.Id}</td>
                    <td
                        ><a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                useExclusive = !record.GlobalLcrId.Int64
                                formMode = "edit"
                                prioritiesByPhoneFormatId = null
                            }}>{record.Name}</a
                        ></td
                    >
                    <td>{record.Techprefix}</td>
                    {#if financial}
                        <td>{tariff.Name} ({stringifyCadence(tariff.Cadence)} ${tariff.Price})</td>
                    {/if}
                    <td>{(lcr_ && lcr_.Name) || "[exclusive]"}</td>
                    {#if financial}
                        <td>{wallet && `${wallet.Description} (${i18n.translate("financial.pay-mode." + wallet.Mode)})`}</td>
                    {/if}
                    <td>{rtpModes[record.RtpMode]}</td>
                    <td><Thumb value={record.IsEnabled} /></td>
                    <td>
                        <a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                useExclusive = !record.GlobalLcrId.Int64
                                formMode = "edit"
                                prioritiesByPhoneFormatId = null
                            }}><i class="fa-solid fa-pencil" /></a
                        >
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("client/techprefix/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new ClientTechprefix()
                    formRecord.DynamicCidOptions = new DynamicCidOptions({
                        Abr: new AbrDynamicCidOptions(),
                    })
                    formRecord.ClientId = client.Id
                    formMode = "add"
                    prioritiesByPhoneFormatId = null
                    useExclusive = false
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} Techprefix</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={(form) => {
                if (useExclusive && formRecord.GlobalLcrId) {
                    formRecord.GlobalLcrId.Int64 = 0
                    formRecord.GlobalLcrId.Valid = false
                }

                var data = new FormData()
                let files = form.querySelectorAll(".rules-file")
                let names = []
                for (let i = 0; i < files.length; i++) {
                    data.append(files[i].name, files[i].files[0])
                    names.push(files[i].name)
                }
                data.append("files", JSON.stringify(names))
                data.append(
                    "data",
                    JSON.stringify({
                        Techprefix: formRecord,
                        LcrPriorities: prioritiesByPhoneFormatId,
                    })
                )
                modal.upload(`client/techprefix/save`, data).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })
            }}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)} Techprefix</svelte:fragment>

            <Tabs selected="basic">
                <svelte:fragment slot="tabs">
                    <a href="#basic">{i18n.translate("dict.basic")}</a>
                    <a href="#lcr">LCR</a>
                    <a href="#rules">{i18n.translate("dict.rules")}</a>
                </svelte:fragment>

                <TabContent name="basic" background="none" mode="pre">
                    <column class="children-spacing" style="padding: 20px 10px 10px;">
                        {#if formMode == "edit"}
                            <label>
                                ID
                                <input disabled value={formRecord.Id} />
                            </label>
                        {/if}
                        <label>
                            {i18n.translate("dict.name")}*
                            <input autocomplete="off" bind:value={formRecord.Name} required />
                        </label>

                        <label>
                            Techprefix
                            <input bind:value={formRecord.Techprefix} pattern="[#\d]+" />
                        </label>

                        {#if financial}
                            <label>
                                {i18n.translate("financial.tariff")}*
                                <select bind:value={formRecord.TariffId} required>
                                    {#each tariffs as tariff}
                                        <option value={tariff.Id}>{tariff.Name} ({stringifyCadence(tariff.Cadence)} ${tariff.Price})</option>
                                    {/each}
                                </select>
                            </label>

                            <label>
                                {i18n.translate("financial.wallet")}*
                                <select bind:value={formRecord.WalletId}>
                                    {#each wallets as wallet}
                                        <option value={wallet.Id}>{wallet.Description}</option>
                                    {/each}
                                </select>
                            </label>
                        {/if}

                        <!--
                TODO: implementar playback
            <label class="checkbox">
                <input type="checkbox" autocomplete="off" bind:checked={formRecord.UsePlayback} />
                {i18n.translate("field.use-signaling-playback")}
            </label>
            -->

                        <label>
                            {i18n.translate("field.rtp-mode")}
                            <select bind:value={formRecord.RtpMode}>
                                {#each Object.keys(rtpModes) as m}
                                    <option value={m}>{rtpModes[m]}</option>
                                {/each}
                            </select>
                        </label>

                        {#if platform.AllowClassifier}
                            <label>
                                {i18n.translate("dict.classifier")}
                                <select
                                    disabled={!formRecord.RtpMode.includes("proxy")}
                                    value={formRecord.ClassifierId && formRecord.ClassifierId.Int64}
                                    on:change={(e) => (formRecord.ClassifierId = new NullInt64({ Int64: parseInt(e.currentTarget.value), Valid: e.currentTarget.value != "0" }))}
                                >
                                    <option value={0}>{i18n.translate("dict.none")}</option>
                                    {#each classifiers as it}
                                        <option value={it.Id}>{it.Name}</option>
                                    {/each}
                                </select>
                            </label>
                        {/if}

                        <label>
                            {i18n.translate("field.cid-mode")}
                            <select bind:value={formRecord.CidMode}>
                                <option value="open">{i18n.translate("field.cid-mode.open")}</option>
                                <option value="list">{i18n.translate("field.cid-mode.list")}</option>
                                <option value="custom">{i18n.translate("field.cid-mode.custom")}</option>
                                <option value="dynamic">{i18n.translate("field.cid-mode.dynamic")}</option>
                            </select>
                        </label>

                        {#if formRecord.CidMode == "dynamic"}
                            <DynamicCidOptionsInput bind:dynamicCidOptions={formRecord.DynamicCidOptions} />
                        {:else if formRecord.CidMode == "custom"}
                            <label>
                                Caller-IDs (separate by comma)*
                                <textarea bind:value={formRecord.CustomCid} required />
                            </label>
                        {:else if formRecord.CidMode == "list"}
                            <Loader endpoint="cid-list/list" let:data={cidLists}>
                                <label>
                                    {i18n.translate("field.cid-list")}*
                                    <select
                                        value={formRecord.CidListId && formRecord.CidListId.Int64}
                                        on:change={(e) => (formRecord.CidListId = e.currentTarget.value ? new NullInt64({ Int64: parseInt(e.currentTarget.value), Valid: true }) : new NullInt64({ Int64: 0, Valid: false }))}
                                        required
                                    >
                                        {#each cidLists as list}
                                            <option value={list.Id}>{list.Name}</option>
                                        {/each}
                                    </select>
                                </label>
                            </Loader>
                        {/if}

                        <label class="checkbox">
                            <input type="checkbox" autocomplete="off" bind:checked={formRecord.IsEnabled} />
                            {i18n.translate("field.active")}
                        </label>

                        <label class="checkbox">
                            <input type="checkbox" autocomplete="off" bind:checked={formRecord.UseOptions} />
                            {i18n.translate("field.use-sip-options")}
                        </label>

                        <label class="checkbox">
                            <input
                                type="checkbox"
                                autocomplete="off"
                                checked={formRecord.ChannelLimit > 0}
                                on:change={(e) => {
                                    if (e.currentTarget.checked) formRecord.ChannelLimit = 100
                                    else formRecord.ChannelLimit = 0
                                }}
                            />
                            {i18n.translate("field.channel-limit")}
                        </label>
                        {#if formRecord.ChannelLimit > 0}
                            <input type="number" min="1" bind:value={formRecord.ChannelLimit} required />
                        {/if}
                    </column>
                </TabContent>

                <!-- LCR -->
                <TabContent name="lcr" background="none" mode="pre">
                    <column class="children-spacing" style="padding: 20px 10px 10px;">
                        <label class="checkbox">
                            <input type="checkbox" bind:checked={useExclusive} />
                            {i18n.translate("field.exclusive-lcr")}
                        </label>

                        {#if !useExclusive}
                            <label>
                                LCR*
                                <select
                                    value={formRecord.GlobalLcrId && formRecord.GlobalLcrId.Int64}
                                    on:change={(e) => (formRecord.GlobalLcrId = e.currentTarget.value ? new NullInt64({ Int64: parseInt(e.currentTarget.value), Valid: true }) : new NullInt64({ Int64: 0, Valid: false }))}
                                    required
                                >
                                    {#each globalLcrs as lcr}
                                        <option value={lcr.Id}>{lcr.Name}</option>
                                    {/each}
                                </select>
                            </label>
                        {:else if formMode == "edit"}
                            <Loader endpoint="lcr/client-techprefix/{formRecord.Id}" let:data={lcr}>
                                <LcrPriorities bind:prioritiesByPhoneFormatId {lcr} />
                            </Loader>
                        {:else}
                            <LcrPriorities bind:prioritiesByPhoneFormatId lcr={null} />
                        {/if}
                    </column>
                </TabContent>

                <TabContent name="rules" background="none" mode="pre">
                    <column class="children-spacing" style="padding: 20px 10px 10px;">
                        <PhoneNumberRules record={formRecord} target="tech-a" bind:value={formRecord.Rules} label={i18n.translate("dict.rules")} />
                    </column>
                </TabContent>
            </Tabs>

            <!-- TODO: signalingToConfirm -->

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
