<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte"
    import { componentLoop, moneyFormat } from "../../../../lib/Utils"
    import Spinner from "../../../../widgets/Spinner.svelte"
    import Table from "../../../../widgets/Table.svelte"
    import TableSorter from "../../../../widgets/TableSorter.svelte"
    import Thumb from "../../../../widgets/Thumb.svelte"

    export var endpoint: string

    const i18n: any = getContext("i18n"),
        pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance")

    var wallets

    componentLoop(onMount, onDestroy, 10000, function () {
        if ($pageManager.current == pageInstance) {
            fetch(endpoint)
                .then((r) => r.json())
                .then((r) => (wallets = r))
        }
    })
</script>

{#if wallets}
    <Table margin="10px 0px 0px 0px">
        <tr slot="head">
            <th>Wallet <TableSorter /></th>
            <th>Balance <TableSorter /></th>
            <th>{i18n.translate("field.active")} <TableSorter /></th>
            <th>Method <TableSorter /></th>
            <th>{i18n.translate("financial.pre-max-debt")}</th>
        </tr>
        {#each wallets as it}
            <tr>
                <td>{it.Description}</td>
                <td class:error-color={it.Balance <= 0}>{moneyFormat(it.Balance)}</td>
                <td><Thumb value={it.IsEnabled} /></td>
                <td>{i18n.translate("financial.pay-mode." + it.Mode)}</td>
                <td
                    >{#if it.Mode == "pre"}{moneyFormat(it.PreMaxDebt)}{/if}</td
                >
            </tr>
        {/each}
    </Table>
{:else}
    <Spinner />
{/if}
