<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../lib/Loader.svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import Classifier from "./treatments/Classifier.svelte"
    import PhoneNumberHandling from "./treatments/PhoneNumberHandling.svelte"

    const i18n: any = getContext("i18n")

    var router
</script>

<Loader endpoint="configs/softswitch/router" let:data={router}>
    <Page>
        <Tabs selected="phone-number-handling">
            <svelte:fragment slot="tabs">
                <a href="#phone-number-handling">{i18n.translate("dict.phone-number-handling")}</a>
                {#if router.AllowClassifier}
                    <a href="#classifier">{i18n.translate("dict.classifiers")}</a>
                {/if}
            </svelte:fragment>
            <TabContent name="phone-number-handling">
                <PhoneNumberHandling />
            </TabContent>
            {#if router.AllowClassifier}
                <TabContent name="classifier">
                    <Classifier />
                </TabContent>
            {/if}
        </Tabs>
    </Page>
</Loader>
