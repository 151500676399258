<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte"
    import Loader from "../../../../lib/Loader.svelte"
    import { componentLoop } from "../../../../lib/Utils"
    import { type Provider } from "../../../../lib/golang-models/maindb"
    import Spinner from "../../../../widgets/Spinner.svelte"
    import CallStatsTable from "../../../elements/call-stats/CallStatsTable.svelte"
    import Charts from "../commons/Charts.svelte"

    export var provider: Provider, showFinancial: boolean, period: number
    var stats,
        deps,
        statFocus: null | string = null

    function load() {
        fetch("softswitch/dashboard/main/provider/" + provider.Id + (statFocus != null ? "?focus=" + statFocus : ""))
            .then((r) => r.json())
            .then((r) => (stats = r))
    }

    const i18n: any = getContext("i18n"),
        login: any = getContext("login"),
        pageManager: any = getContext("page-manager"),
        pageInstance: any = getContext("page-instance")

    componentLoop(onMount, onDestroy, 6000, function () {
        if ($pageManager.current == pageInstance) {
            load()
        }
    })

    var endpoints = ["provider/techprefix/list/" + provider.Id]
</script>

<column class="expanded" style="padding: 10px;">
    <Loader endpoint={endpoints} let:data={deps}>
        {@const techsById = Object.fromEntries(deps[0].map((v) => [v.Id, v]))}

        {#if stats}
            <Charts {period} {showFinancial} stats={stats.Provider} />
            <CallStatsTable
                onStatFocus={(k) => {
                    statFocus = k
                    if (k) load()
                }}
                {period}
                header="Techprefix"
                data={Object.keys(stats.Techprefixes).map((k) => ({ key: k, id: k.substring(k.lastIndexOf("_") + 1), ...stats.Techprefixes[k] }))}
                dict={techsById}
                {showFinancial}
                showHangupCause={true}
            />
        {:else}
            <Spinner />
        {/if}
    </Loader>
</column>
