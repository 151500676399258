<script>
    import { getContext } from "svelte"
    import { isMobile } from "../../lib/Utils"

    const pageManager = getContext("page-manager"),
        i18n = getContext("i18n"),
        showSidebar = getContext("show-sidebar")
</script>

<row style="border-bottom: var(--standard-border); background-image: linear-gradient(#bbb 70%, #d9d9d9);">
    {#if isMobile}
        <a href="#menu" on:click|preventDefault={() => showSidebar.set(true)} class="tab"><i class="fa-solid fa-bars" /></a>
    {/if}
    <row style="overflow-x: auto;">
        {#each $pageManager.instances as instance (instance.id)}
            <a
                href="#{instance.hash}"
                on:mousedown|preventDefault={(e) => {
                    if (e.which == 2) {
                        $pageManager.close(instance)
                        e.stopPropagation()
                        e.preventDefault()
                        return false
                    }
                }}
                class="tab"
                class:selected={instance == $pageManager.current}>{instance.setup.path.split("/").join(" / ")}<a href="#close" style="margin-left: 15px;" on:click|preventDefault={() => $pageManager.close(instance)}><i class="fa-solid fa-close" /></a></a
            >
        {/each}
    </row>
    {#if $pageManager.instances.length > 0}
        <expander />
        <a href="#btn" class="tab" on:click|preventDefault={() => $pageManager.clear()} title={i18n.translate("toptabs.clear.tip")}><i class="fa-solid fa-broom" /></a>
    {/if}
</row>

<style>
    a.tab,
    a.tab:visited {
        color: #5e5e5e;
        text-decoration: none;
        padding: 5px 15px;
        background-color: #91919161;
        border-right: var(--standard-border);
        font-size: 15px;
    }
    a.tab:hover {
        background-color: #ffffff80;
    }
    a.tab:active {
        background-color: rgba(184, 184, 184, 0.502);
    }
    a.tab.selected {
        color: var(--theme-color-a);
        background-color: #ffffffbf;
    }
</style>
