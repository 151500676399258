<script lang="ts">
    import { getContext } from "svelte"
    import NonIdealState from "../../widgets/NonIdealState.svelte"
    import TabContent from "../../widgets/tab/TabContent.svelte"
    import Tabs from "../../widgets/tab/Tabs.svelte"
    import Page from "../elements/Page.svelte"
    import CidLists from "./resources/CidLists.svelte"

    const i18n: any = getContext("i18n")
</script>

<Page>
    <Tabs selected="cid-lists">
        <svelte:fragment slot="tabs">
            <a href="#cid-lists">{i18n.translate("dict.cid-lists")}</a>
            <a href="#signaling-playbacks">{i18n.translate("dict.signaling-playbacks")}</a>
        </svelte:fragment>
        <TabContent name="cid-lists">
            <CidLists />
        </TabContent>
        <TabContent name="signaling-playbacks">
            <NonIdealState icon="fa-solid fa-tools" />
            <!-- TODO: upload de áudios e associação de status -->
        </TabContent>
    </Tabs>
</Page>
