<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { moneyFormat } from "../../../lib/Utils"
    import { ClientWallet, type Client } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import Thumb from "../../../widgets/Thumb.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    export var client: Client

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: ClientWallet = null
</script>

<Loader endpoint="client/wallet/list/{client.Id}" let:data let:reload>
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.description")} <TableSorter /></th>
                <th>{i18n.translate("dict.type")}</th>
                <th>{i18n.translate("financial.balance")}</th>
                <th title={i18n.translate("financial.pre-max-debt")}>NBL</th>
                <th>{i18n.translate("field.active")}</th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each data as record}
                <tr>
                    <td>{record.Id}</td>
                    <td>{record.Description}</td>
                    <td>{i18n.translate("financial.pay-mode." + record.Mode)}</td>
                    <td class:error-color={record.Balance < 0}>{moneyFormat(record.Balance)}</td>
                    <td>{record.Mode == "pre" ? record.PreMaxDebt : ""}</td>
                    <td><Thumb value={record.IsEnabled} /></td>
                    <td>
                        <a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}><i class="fa-solid fa-pencil" /></a
                        >
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Description })) && modal.post("client/wallet/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new ClientWallet()
                    formRecord.ClientId = client.Id
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("financial.wallet")}</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={() =>
                modal.post(`client/wallet/save`, formRecord).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)} {i18n.translate("financial.wallet")}</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}
            <label>
                {i18n.translate("dict.description")}*
                <input autocomplete="off" bind:value={formRecord.Description} required />
            </label>

            <label>
                {i18n.translate("dict.type")}
                <select bind:value={formRecord.Mode}>
                    <option value="pre">{i18n.translate("financial.pay-mode.pre")}</option>
                    <option value="post">{i18n.translate("financial.pay-mode.post")}</option>
                </select>
            </label>

            {#if formMode == "add"}
                <label>
                    {i18n.translate("financial.balance")}
                    <input bind:value={formRecord.Balance} type="number" />
                </label>
            {/if}

            {#if formRecord.Mode == "pre"}
                <label>
                    {i18n.translate("financial.pre-max-debt")}
                    <input bind:value={formRecord.PreMaxDebt} type="number" max="0" />
                </label>
            {/if}

            <label class="checkbox">
                <input type="checkbox" bind:checked={formRecord.IsEnabled} />
                {i18n.translate("field.active")}
            </label>

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
