<script lang="ts">
    import { beforeUpdate, getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { createToast } from "../../../lib/Toast"
    import { dateTimeFormat } from "../../../lib/Utils"
    import { StatGroup } from "../../../lib/golang-models/cdr"
    import Form from "../../../widgets/Form.svelte"
    import DateTimeRange from "../../../widgets/inputs/DateTimeRange.svelte"
    import TabContent from "../../../widgets/tab/TabContent.svelte"
    import Tabs from "../../../widgets/tab/Tabs.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"
    import CallStatsTable from "../../elements/call-stats/CallStatsTable.svelte"

    const modal: any = getContext("modal"),
        i18n: any = getContext("i18n")
    var seq = 0,
        selectedId = "1"

    function makeReport() {
        seq++
        return {
            id: seq.toString(),
            name: "Report " + seq,
            showQuery: true,
            query: {
                dateRange: "yesterday",
                StartDate: dateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 0, 0, 1)),
                EndDate: dateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, 23, 59, 59)),
                SeparateByDay: false,
                Group: 0,
                Client: 0,
                Provider: 0,
                TechA: 0,
                TechB: 0,
            },
            result: {
                query: null,
                data: null,
            },
        }
    }

    var reports = [makeReport()],
        techsA,
        techsB,
        deps

    beforeUpdate(function () {
        for (let report of reports) {
            if (report.query.SeparateByDay && new Date(report.query.EndDate).getTime() - new Date(report.query.StartDate).getTime() < 24 * 60 * 60000) {
                report.query.SeparateByDay = false
            }
        }
    })
</script>

<!-- TODO: translate -->
<Loader endpoint={["client/list", "provider/list"]} let:data={deps}>
    {@const clients = deps[0]}
    {@const providers = deps[1]}
    <Tabs
        bind:selected={selectedId}
        add={() => {
            let n = makeReport()
            reports = [...reports, n]
            selectedId = n.id
        }}
        close={(id) => {
            reports = [...reports.filter((it) => it.id != id)]
        }}
    >
        <svelte:fragment slot="tabs">
            {#each reports as r}
                <a href="#{r.id}">{r.name}</a>
            {/each}
        </svelte:fragment>
        {#each reports as report}
            <TabContent name={report.id}>
                <Form
                    expanded
                    box={false}
                    submit={() => {
                        if (new Date(report.query.EndDate).getTime() - new Date(report.query.StartDate).getTime() > 1440 * 60000 * 31) {
                            createToast("error", i18n.translate("field.date-range-over", { days: 31 }))
                            return
                        }
                        if (new Date(report.query.EndDate).getTime() < new Date(report.query.StartDate).getTime()) {
                            createToast("error", i18n.translate("field.date-range-error"))
                            return
                        }
                        report.result.query = JSON.parse(JSON.stringify(report.query))
                        modal.post(`tool/minutes-report/make`, report.query).then((res) => {
                            if (res.status == 200) {
                                report.result.data = res.data
                                report.showQuery = false
                            }
                        })
                    }}
                >
                    <!-- Query -->
                    {#if report.showQuery}
                        <column class="form-grid">
                            <row>
                                <label>
                                    Report Name
                                    <input type="text" bind:value={report.name} />
                                </label>
                            </row>
                            <!-- TODO: quando o range passar de hoje, avisar que apenas até ontem os valores estão consolidados -->
                            <DateTimeRange bind:mode={report.query.dateRange} bind:start={report.query.StartDate} bind:end={report.query.EndDate} timeInput={false} />

                            <row>
                                <label>
                                    Mode
                                    <select
                                        bind:value={report.query.Group}
                                        on:change={() => {
                                            report.query.Provider = 0
                                            report.query.Client = 0
                                            report.query.TechA = 0
                                            report.query.TechB = 0
                                        }}
                                    >
                                        {#if report.query.SeparateByDay}
                                            <option value={StatGroup.Global}>Total</option>
                                            <option value={StatGroup.Client}>Client</option>
                                            <option value={StatGroup.TechA}>Client techprefix</option>
                                            <option value={StatGroup.Provider}>Provider</option>
                                            <option value={StatGroup.TechB}>Provider techprefix</option>
                                        {:else}
                                            <option value={StatGroup.Global}>Total</option>
                                            <option value={StatGroup.Client}>Clients</option>
                                            <option value={StatGroup.TechA}>Client techprefixes</option>
                                            <option value={StatGroup.Provider}>Providers</option>
                                            <option value={StatGroup.TechB}>Provider techprefixes</option>

                                            <option value={StatGroup.ClientProvider}>Clients + Providers</option>
                                            <option value={StatGroup.ClientTechB}>Clients + Provider techprefixes</option>
                                            <option value={StatGroup.TechAProvider}>Client techprefixes + Providers</option>
                                            <option value={StatGroup.TechATechB}>Client techprefixes + Provider techprefixes</option>
                                        {/if}
                                    </select>
                                </label>
                            </row>

                            {#if new Date(report.query.EndDate).getTime() - new Date(report.query.StartDate).getTime() > 24 * 60 * 60000 && report.query.Group <= StatGroup.TechB}
                                <label class="checkbox">
                                    <input
                                        type="checkbox"
                                        autocomplete="off"
                                        bind:checked={report.query.SeparateByDay}
                                        on:change={() => {
                                            report.query.Provider = 0
                                            report.query.Client = 0
                                            report.query.TechA = 0
                                            report.query.TechB = 0
                                        }}
                                    />
                                    Separate by day
                                </label>
                            {/if}

                            {#if (report.query.Group == StatGroup.Client && report.query.SeparateByDay) || report.query.Group == StatGroup.TechA || report.query.Group == StatGroup.ClientProvider || report.query.Group == StatGroup.ClientTechB || report.query.Group == StatGroup.TechAProvider || report.query.Group == StatGroup.TechATechB}
                                <row>
                                    <label>
                                        Client
                                        <select bind:value={report.query.Client} required>
                                            {#if !report.query.SeparateByDay && report.query.Group != StatGroup.TechA && ((report.query.Group == StatGroup.ClientProvider && report.query.Provider != 0) || (report.query.Group == StatGroup.ClientTechB && report.query.TechB != 0))}
                                                <option value={0}>{i18n.translate("dict.all")}</option>
                                            {/if}
                                            {#each clients as client (client.Id)}
                                                <option value={client.Id}>{client.Name}</option>
                                            {/each}
                                        </select>
                                    </label>

                                    {#if report.query.Client != 0 && ((report.query.SeparateByDay && report.query.Group == StatGroup.TechA) || report.query.Group == StatGroup.TechAProvider || report.query.Group == StatGroup.TechATechB)}
                                        {#key report.query.Client}
                                            <Loader endpoint="client/techprefix/list/{report.query.Client}" let:data={techsA}>
                                                <label>
                                                    Techprefix
                                                    <select bind:value={report.query.TechA} required>
                                                        {#if !report.query.SeparateByDay && ((report.query.Group == StatGroup.TechAProvider && report.query.Provider != 0) || (report.query.Group == StatGroup.TechATechB && report.query.TechB != 0))}
                                                            <option value={0}>{i18n.translate("dict.all")}</option>
                                                        {/if}
                                                        {#each techsA as tech (tech.Id)}
                                                            <option value={tech.Id}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                                                        {/each}
                                                    </select>
                                                </label>
                                            </Loader>
                                        {/key}
                                    {/if}
                                </row>
                            {/if}

                            {#if (report.query.Group == StatGroup.Provider && report.query.SeparateByDay) || report.query.Group == StatGroup.TechB || report.query.Group == StatGroup.ClientProvider || report.query.Group == StatGroup.ClientTechB || report.query.Group == StatGroup.TechAProvider || report.query.Group == StatGroup.TechATechB}
                                <row>
                                    <label>
                                        Provider
                                        <select bind:value={report.query.Provider} required>
                                            {#if !report.query.SeparateByDay && report.query.Group != StatGroup.TechB && ((report.query.Group == StatGroup.ClientProvider && report.query.Client != 0) || (report.query.Group == StatGroup.TechAProvider && report.query.TechA != 0))}
                                                <option value={0}>{i18n.translate("dict.all")}</option>
                                            {/if}
                                            {#each providers as provider (provider.Id)}
                                                <option value={provider.Id}>{provider.Name}</option>
                                            {/each}
                                        </select>
                                    </label>

                                    {#if report.query.Provider != 0 && ((report.query.SeparateByDay && report.query.Group == StatGroup.TechB) || report.query.Group == StatGroup.ClientTechB || report.query.Group == StatGroup.TechATechB)}
                                        {#key report.query.Provider}
                                            <Loader endpoint="provider/techprefix/list/{report.query.Provider}" let:data={techsB}>
                                                <label>
                                                    Techprefix
                                                    <select bind:value={report.query.TechB} required>
                                                        {#if !report.query.SeparateByDay && ((report.query.Group == StatGroup.ClientTechB && report.query.Client != 0) || (report.query.Group == StatGroup.TechATechB && report.query.TechA != 0))}
                                                            <option value={0}>{i18n.translate("dict.all")}</option>
                                                        {/if}
                                                        {#each techsB as tech (tech.Id)}
                                                            <option value={tech.Id}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                                                        {/each}
                                                    </select>
                                                </label>
                                            </Loader>
                                        {/key}
                                    {/if}
                                </row>
                            {/if}
                        </column>

                        <!-- Result -->
                    {:else if report.result.data}
                        {#if report.result.query.SeparateByDay}
                            <CallStatsTable data={Object.keys(report.result.data).map((k) => ({ id: k, ...report.result.data[k] }))} dict={{}} header={i18n.translate("dict.date")} showFinancial showHangupCause showRealtime={false} />
                        {:else if report.result.query.Group == StatGroup.Global}
                            <CallStatsTable data={Object.keys(report.result.data).map((k) => ({ id: k, ...report.result.data[k] }))} dict={{}} header="" showFinancial showHangupCause showRealtime={false} />
                        {:else if report.result.query.Group == StatGroup.Client}
                            <CallStatsTable data={Object.keys(report.result.data).map((k) => ({ id: k, ...report.result.data[k] }))} dict={Object.fromEntries(clients.map((v) => [v.Id, v]))} header="Client" showFinancial showHangupCause showRealtime={false} />
                        {:else if report.result.query.Group == StatGroup.TechA}
                            <Loader endpoint="client/techprefix/list/{report.result.query.Client}" let:data={techsA}>
                                <CallStatsTable
                                    data={Object.keys(report.result.data).map((k) => ({ id: k.substring(k.lastIndexOf("_") + 1), ...report.result.data[k] }))}
                                    dict={Object.fromEntries(techsA.map((v) => [v.Id, v]))}
                                    header="Techprefix"
                                    showFinancial
                                    showHangupCause
                                    showRealtime={false}
                                />
                            </Loader>
                        {:else if report.result.query.Group == StatGroup.TechB}
                            <Loader endpoint="provider/techprefix/list/{report.result.query.Provider}" let:data={techsB}>
                                <CallStatsTable
                                    data={Object.keys(report.result.data).map((k) => ({ id: k.substring(k.lastIndexOf("_") + 1), ...report.result.data[k] }))}
                                    dict={Object.fromEntries(techsB.map((v) => [v.Id, v]))}
                                    header="Techprefix"
                                    showFinancial
                                    showHangupCause
                                    showRealtime={false}
                                />
                            </Loader>
                        {:else if report.result.query.Group == StatGroup.Provider}
                            <CallStatsTable data={Object.keys(report.result.data).map((k) => ({ id: k, ...report.result.data[k] }))} dict={Object.fromEntries(providers.map((v) => [v.Id, v]))} header="Provider" showFinancial showHangupCause showRealtime={false} />

                            <!-- Client X Provider -->
                        {:else if report.result.query.Group == StatGroup.ClientProvider}
                            {#if report.result.query.Provider != 0 && report.result.query.Client != 0}
                                <CallStatsTable data={Object.values(report.result.data)} dict={{}} header="" showFinancial showHangupCause showRealtime={false} />
                            {:else if report.result.query.Client != 0}
                                <CallStatsTable
                                    data={Object.keys(report.result.data).map((k) => ({ id: k.substring(k.lastIndexOf("_") + 1), ...report.result.data[k] }))}
                                    dict={Object.fromEntries(providers.map((v) => [v.Id, v]))}
                                    header="Provider"
                                    showFinancial
                                    showHangupCause
                                    showRealtime={false}
                                />
                            {:else}
                                <CallStatsTable data={Object.keys(report.result.data).map((k) => ({ id: k.split("_")[0], ...report.result.data[k] }))} dict={Object.fromEntries(clients.map((v) => [v.Id, v]))} header="Client" showFinancial showHangupCause showRealtime={false} />
                            {/if}
                            <!-- Client X Provider Tech -->
                        {:else if report.result.query.Group == StatGroup.ClientTechB}
                            {#if report.result.query.TechB != 0 && report.result.query.Client != 0}
                                <CallStatsTable data={Object.values(report.result.data)} dict={{}} header="" showFinancial showHangupCause showRealtime={false} />
                            {:else if report.result.query.Client != 0}
                                <Loader endpoint="provider/techprefix/list/{report.result.query.Provider}" let:data={techsB}>
                                    <CallStatsTable
                                        data={Object.keys(report.result.data).map((k) => ({ id: k.substring(k.lastIndexOf("_") + 1), ...report.result.data[k] }))}
                                        dict={Object.fromEntries(techsB.map((v) => [v.Id, v]))}
                                        header="Provider Techprefix"
                                        showFinancial
                                        showHangupCause
                                        showRealtime={false}
                                    />
                                </Loader>
                            {:else}
                                <CallStatsTable data={Object.keys(report.result.data).map((k) => ({ id: k.split("_")[0], ...report.result.data[k] }))} dict={Object.fromEntries(clients.map((v) => [v.Id, v]))} header="Client" showFinancial showHangupCause showRealtime={false} />
                            {/if}
                            <!-- Client techprefix X Provider -->
                        {:else if report.result.query.Group == StatGroup.TechAProvider}
                            {#if report.result.query.TechA != 0 && report.result.query.Provider != 0}
                                <CallStatsTable data={Object.values(report.result.data)} dict={{}} header="" showFinancial showHangupCause showRealtime={false} />
                            {:else if report.result.query.Provider != 0}
                                <Loader endpoint="client/techprefix/list/{report.result.query.Client}" let:data={techsA}>
                                    <CallStatsTable
                                        data={Object.keys(report.result.data).map((k) => ({ id: k.split("_")[0], ...report.result.data[k] }))}
                                        dict={Object.fromEntries(techsA.map((v) => [v.Id, v]))}
                                        header="Client Techprefix"
                                        showFinancial
                                        showHangupCause
                                        showRealtime={false}
                                    />
                                </Loader>
                            {:else}
                                <CallStatsTable
                                    data={Object.keys(report.result.data).map((k) => ({ id: k.substring(k.lastIndexOf("_") + 1), ...report.result.data[k] }))}
                                    dict={Object.fromEntries(providers.map((v) => [v.Id, v]))}
                                    header="Provider"
                                    showFinancial
                                    showHangupCause
                                    showRealtime={false}
                                />
                            {/if}

                            <!-- Client techprefix X Provider techprefix -->
                        {:else if report.result.query.Group == StatGroup.TechATechB}
                            {#if report.result.query.TechA != 0 && report.result.query.TechB != 0}
                                <CallStatsTable data={Object.values(report.result.data)} dict={{}} header="" showFinancial showHangupCause showRealtime={false} />
                            {:else if report.result.query.TechB != 0}
                                <Loader endpoint="client/techprefix/list/{report.result.query.Client}" let:data={techsA}>
                                    <CallStatsTable
                                        data={Object.keys(report.result.data).map((k) => ({ id: k.split("_")[1], ...report.result.data[k] }))}
                                        dict={Object.fromEntries(techsA.map((v) => [v.Id, v]))}
                                        header="Client Techprefix"
                                        showFinancial
                                        showHangupCause
                                        showRealtime={false}
                                    />
                                </Loader>
                            {:else}
                                <Loader endpoint="provider/techprefix/list/{report.result.query.Provider}" let:data={techsB}>
                                    <CallStatsTable
                                        data={Object.keys(report.result.data).map((k) => ({ id: k.substring(k.lastIndexOf("_") + 1), ...report.result.data[k] }))}
                                        dict={Object.fromEntries(techsB.map((v) => [v.Id, v]))}
                                        header="Provider Techprefix"
                                        showFinancial
                                        showHangupCause
                                        showRealtime={false}
                                    />
                                </Loader>
                            {/if}
                        {/if}
                    {/if}

                    <svelte:fragment slot="footer">
                        <PageFooter>
                            <expander />
                            {#if report.result.data}
                                <button type="button" on:click={() => (report.showQuery = !report.showQuery)}>{report.showQuery ? "View Data" : "Change Report"}</button>
                            {/if}
                            <button class="primary" type="submit">{report.result.data && !report.showQuery ? "Refresh Report" : "Make Report"}</button>
                        </PageFooter>
                    </svelte:fragment>
                </Form>
            </TabContent>
        {/each}
    </Tabs>
</Loader>
