<script>
    import { getContext } from "svelte"
    import Page from "../elements/Page.svelte"
    import Client from "./calls/Client.svelte"
    import User from "./calls/User.svelte"

    const login = getContext("login")
</script>

<Page>
    {#if login.Type == "user"}
        <User />
    {:else if login.Type == "client"}
        <Client />
    {/if}
</Page>
