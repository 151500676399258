<script>
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { moneyFormat, stringifyCadence } from "../../../lib/Utils"
    import { UserRole } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Thumb from "../../../widgets/Thumb.svelte"
    import Dialog from "../../../widgets/modals/Dialog.svelte"

    const login = getContext("login"),
        modal = getContext("modal"),
        i18n = getContext("i18n")
    var deps

    function newQuery() {
        return {
            ClientId: 0,
            ClientTechprefixId: 0,
            ProviderId: 0,
            ProviderTechprefixId: 0,
            Destination: "",
        }
    }

    var query = newQuery(),
        result = null,
        techsA,
        techsB
</script>

<!-- Modal -->
{#if result}
    <Dialog bodyPadding="0px" onClose={() => (result = null)}>
        <svelte:fragment slot="title">{i18n.translate("dict.call-simulation")}</svelte:fragment>
        <column style="overflow:auto;">
            <column style="padding: 10px; min-height: auto;">
                {#if result.TariffA}
                    <h2>{login.Type == "user" ? i18n.translate("dict.client") : "Techprefix"}</h2>
                    <space />
                    <label for="">{i18n.translate("question.completes-call")}</label>
                    <div>{i18n.translate("dict." + (result.CompleteA ? "yes" : "no"))} <Thumb value={result.CompleteA} /></div>
                    <double-space />
                    {#if login.Type == "client" || login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT)}
                        <label for="">{i18n.translate("financial.cadence")}</label>
                        <div>{stringifyCadence(result.TariffA.Cadence)}</div>
                        <double-space />
                        <label for="">{i18n.translate("dict.price")}</label>
                        <div>${moneyFormat(result.PriceA)}</div>
                        <double-space />
                    {/if}
                {/if}
                {#if login.Type == "user" && result.TariffB}
                    <h2>{i18n.translate("dict.provider")}</h2>
                    <space />
                    <label for="">{i18n.translate("question.completes-call")}</label>
                    <div>{i18n.translate("dict." + (result.CompleteB ? "yes" : "no"))} <Thumb value={result.CompleteB} /></div>
                    <double-space />
                    {#if login.Type == "client" || login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT)}
                        <label for="">{i18n.translate("financial.cadence")}</label>
                        <div>{stringifyCadence(result.TariffB.Cadence)}</div>
                        <double-space />
                        <label for="">{i18n.translate("dict.price")}</label>
                        <div>${moneyFormat(result.PriceB)}</div>
                        <double-space />
                    {/if}
                {/if}

                <h2>{i18n.translate("dict.phone-number")}</h2>
                <space />
                {#if result.DestinationParts}
                    <label for="">{i18n.translate("dict.format")}</label>
                    {#each Object.keys(result.DestinationParts) as k}
                        <div>{i18n.translate("dict." + k) || k.toUpperCase()}: {result.DestinationParts[k]}</div>
                    {/each}
                    <double-space />
                {/if}
                {#if result.CustomCarrier}
                    <label for="">{i18n.translate("dict.carrier")}</label>
                    <div>{result.CustomCarrier.Name}</div>
                    <double-space />
                {/if}
                {#if result.DestinationProperties}
                    <label for="">{i18n.translate("dict.properties")}</label>
                    {#each Object.keys(result.DestinationProperties) as k}
                        <div>{i18n.translate(k)}: {result.DestinationProperties[k]}</div>
                    {/each}
                    <double-space />
                {/if}
            </column>
        </column>
    </Dialog>
{/if}

<!-- Query -->
<column style="max-width: 500px;">
    <Form
        submit={() => {
            modal.post("softswitch/call-simulator/simulate", query).then((r) => {
                if (r.status == 200) result = r.data
            })
        }}
        box={false}
    >
        <label>
            {i18n.translate("dict.destination-phone")}* (CC+AC+Number)
            <input type="text" bind:value={query.Destination} required />
        </label>

        {#if login.Type == "user"}
            <Loader endpoint={["client/list", "provider/list"]} let:data={deps}>
                {@const clients = deps[0]}
                {@const providers = deps[1]}
                <label>
                    {i18n.translate("dict.client")}
                    <select bind:value={query.ClientId}>
                        {#each clients as client}
                            <option value={client.Id}>{client.Name}</option>
                        {/each}
                    </select>
                </label>
                {#if query.ClientId != 0}
                    {#key query.ClientId}
                        <Loader endpoint="client/techprefix/list/{query.ClientId}" let:data={techsA}>
                            <label>
                                {i18n.translate("dict.client")} Techprefix*
                                <select bind:value={query.ClientTechprefixId} required>
                                    {#each techsA as tech}
                                        <option value={tech.Id}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                                    {/each}
                                </select>
                            </label>
                        </Loader>
                    {/key}
                {/if}

                <label>
                    {i18n.translate("dict.provider")}
                    <select bind:value={query.ProviderId}>
                        {#each providers as provider}
                            <option value={provider.Id}>{provider.Name}</option>
                        {/each}
                    </select>
                </label>
                {#if query.ProviderId != 0}
                    {#key query.ProviderId}
                        <Loader endpoint="provider/techprefix/list/{query.ProviderId}" let:data={techsB}>
                            <label>
                                {i18n.translate("dict.provider")} techprefix*
                                <select bind:value={query.ProviderTechprefixId} required>
                                    {#each techsB as tech}
                                        <option value={tech.Id}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                                    {/each}
                                </select>
                            </label>
                        </Loader>
                    {/key}
                {/if}
            </Loader>
        {:else}
            <Loader endpoint="client/techprefixes" let:data={techsA}>
                <label>
                    Techprefix
                    <select bind:value={query.ClientTechprefixId}>
                        {#each techsA as tech}
                            <option value={tech.Id}>{tech.Techprefix ? `${tech.Name} (${tech.Techprefix})` : tech.Name}</option>
                        {/each}
                    </select>
                </label>
            </Loader>
        {/if}

        <row>
            <expander />
            <double-space />
            <button
                type="button"
                on:click={() => {
                    query = { ...newQuery(), Destination: query.Destination }
                }}>{i18n.translate("action.clear")}</button
            >
            <double-space />
            <button class="primary">{i18n.translate("action.simulate")}</button>
        </row>
    </Form>
</column>
