<script>
    import { onMount } from "svelte"
    import Form from "../../../widgets/Form.svelte"
    import Spinner from "../../../widgets/Spinner.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"

    var result,
        process = "softswitch",
        lines = 50

    function read() {
        fetch("debug/log-file/read/" + process + "?lines=" + lines)
            .then((r) => r.json())
            .then((r) => (result = r))
    }

    onMount(function () {
        read()
    })
</script>

<Form expanded box={false} submit={() => read()}>
    <label>
        Process
        <select bind:value={process}>
            <option value="softswitch">Softswitch</option>
            <option value="server">Server</option>
            <option value="installer">Installer</option>
        </select>
    </label>

    <label>
        Lines
        <input type="number" min="1" max="10000" bind:value={lines} />
    </label>

    {#if result}
        <pre>
            {result.Data}
            <!-- <scroll style="flex: 1; overflow: auto;">
                <scroll-content>
                    <div>{result.Data}</div>
                </scroll-content>
            </scroll> -->
        </pre>
    {:else}
        <Spinner />
    {/if}

    <svelte:fragment slot="footer">
        <PageFooter>
            <expander />
            <button class="primary" type="submit" on:click={() => read()}><i class="fa-solid fa-file-arrow-down" /> Read</button>
        </PageFooter>
    </svelte:fragment>
</Form>

<style>
    pre {
        overflow: scroll;
        flex-grow: 1;
        flex-basis: 0;
        height: auto;
        background-color: #324e66;
        word-break: normal !important;
        word-wrap: normal !important;
        white-space: pre !important;
        padding: 10px;
        color: #c5d6f7;
        border: var(--standard-border);
        font-family: inherit;
    }
</style>
