<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { stringifyCadence } from "../../../lib/Utils"
    import { CidList, NullInt64, Provider, ProviderTechprefix, Tariff, UserRole } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"
    import PhoneNumberRules from "../../elements/telephony/PhoneNumberRules.svelte"
    import DynamicCidOptionsInput from "../commons/DynamicCidOptionsInput.svelte"

    export var provider: Provider

    var cidLists: CidList[], tariffs: Tariff[], deps: any

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal"),
        login: any = getContext("login")

    function edit(record: ProviderTechprefix) {
        formRecord = { ...record } as ProviderTechprefix
        formMode = "edit"
    }

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: ProviderTechprefix = null

    const financial = login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT)
    var endpoints = [`trunk/list/provider/${provider.Id}`, `phone-number-format/list`, `provider/techprefix/list/${provider.Id}`]
    if (financial) endpoints.push(`tariff/list/provider/${provider.Id}`)
</script>

<Loader endpoint={endpoints} let:data={deps} let:reload>
    {@const trunks = deps[0]}
    {@const trunksById = Object.fromEntries(deps[0].map((v) => [v.Id, v]))}
    {@const phoneFormats = deps[1]}
    {@const phoneFormatsById = Object.fromEntries(deps[1].map((v) => [v.Id, v]))}
    {@const techs = deps[2]}

    <!-- {@const tariffsById = Object.fromEntries(deps[3].map((v) => [v.Id, v]))} -->
    {@const tariffs = deps[3]}
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>Techprefix <TableSorter /></th>
                <th>{i18n.translate("dict.trunk")}</th>
                <th>{i18n.translate("field.allowed-phone-number-format")}</th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each techs as record}
                {@const trunk = trunksById[record.TrunkId]}
                {@const phoneFormat = phoneFormatsById[record.PhoneNumberFormatId]}
                <tr>
                    <td>{record.Id}</td>
                    <td><a href="#edit" on:click|preventDefault={() => edit(record)}>{record.Name}</a></td>
                    <td>{record.Techprefix}</td>
                    <td>{trunk.Name}</td>
                    <td>{phoneFormat.Name}</td>
                    <td>
                        <a href="#edit" on:click|preventDefault={() => edit(record)}><i class="fa-solid fa-pencil" /></a>
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("provider/techprefix/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new ProviderTechprefix()
                    formRecord.ProviderId = provider.Id
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} Techprefix</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={(form) => {
                var data = new FormData()
                let files = form.querySelectorAll(".rules-file")
                let names = []
                for (let i = 0; i < files.length; i++) {
                    data.append(files[i].name, files[i].files[0])
                    names.push(files[i].name)
                }
                data.append("files", JSON.stringify(names))
                data.append("record", JSON.stringify(formRecord))

                modal.upload(`provider/techprefix/save`, data).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })
            }}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)} Techprefix</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}
            <label>
                {i18n.translate("dict.name")}*
                <input autocomplete="off" bind:value={formRecord.Name} required />
            </label>

            <label>
                Techprefix
                <input bind:value={formRecord.Techprefix} pattern="[#\d]+" />
            </label>

            <label>
                {i18n.translate("dict.phone-number-format")}*
                <select
                    bind:value={formRecord.PhoneNumberFormatId}
                    required
                    on:change={() => {
                        if (phoneFormatsById[formRecord.PhoneNumberFormatId] && phoneFormatsById[formRecord.PhoneNumberFormatId].Name != "Brazil Landline") formRecord.Ldn = false
                    }}
                >
                    {#each phoneFormats as it}
                        <option value={it.Id}>{it.Name}</option>
                    {/each}
                </select>
            </label>

            {#if formRecord.PhoneNumberFormatId}
                {@const phoneFormat = phoneFormatsById[formRecord.PhoneNumberFormatId]}
                <label>
                    {i18n.translate("field.phone-number-destination-transformation")}* (keywords: {Array.from(new Set(["tech"].concat(phoneFormat.Keys))).join(", ")})
                    <input bind:value={formRecord.PhoneNumberOutputFormat} required />
                </label>
            {/if}

            {#if financial}
                <label>
                    {i18n.translate("financial.tariff")}*
                    <select bind:value={formRecord.DefaultTariffId} required>
                        {#each tariffs as tariff}
                            <option value={tariff.Id}>{tariff.Name} ({stringifyCadence(tariff.Cadence)} ${tariff.Price})</option>
                        {/each}
                    </select>
                </label>
            {/if}

            <label>
                {i18n.translate("dict.trunk")}*
                <select bind:value={formRecord.TrunkId} required>
                    {#each trunks as trunk}
                        <option value={trunk.Id}>{trunk.Name}</option>
                    {/each}
                </select>
            </label>

            <label>
                {i18n.translate("field.cid-mode")}
                <select bind:value={formRecord.CidMode}>
                    <option value="bypass">{i18n.translate("field.cid-mode.bypass")}</option>
                    <option value="open">{i18n.translate("field.cid-mode.open")}</option>
                    <option value="list">{i18n.translate("field.cid-mode.list")}</option>
                    <option value="custom">{i18n.translate("field.cid-mode.custom")}</option>
                    <option value="dynamic">{i18n.translate("field.cid-mode.dynamic")}</option>
                </select>
            </label>

            {#if formRecord.CidMode == "dynamic"}
                <DynamicCidOptionsInput bind:dynamicCidOptions={formRecord.DynamicCidOptions} />
            {:else if formRecord.CidMode == "custom"}
                <label>
                    Caller-IDs (separate by comma)*
                    <textarea bind:value={formRecord.CustomCid} required />
                </label>
            {:else if formRecord.CidMode == "list"}
                <Loader endpoint="cid-list/list" let:data={cidLists}>
                    <label>
                        {i18n.translate("field.cid-mode.list")} Caller-ID*
                        <select
                            value={formRecord.CidListId && formRecord.CidListId.Int64}
                            on:change={(e) => (formRecord.CidListId = e.currentTarget.value ? new NullInt64({ Int64: parseInt(e.currentTarget.value), Valid: true }) : new NullInt64({ Int64: 0, Valid: false }))}
                            required
                        >
                            {#each cidLists as list}
                                <option value={list.Id}>{list.Name}</option>
                            {/each}
                        </select>
                    </label>
                </Loader>
            {/if}

            <label class="checkbox">
                <input type="checkbox" autocomplete="off" bind:checked={formRecord.IsEnabled} />
                {i18n.translate("field.active")}
            </label>

            {#if formRecord.PhoneNumberFormatId}
                {@const phoneFormat = phoneFormatsById[formRecord.PhoneNumberFormatId]}
                {#if phoneFormat.Name == `Brazil Landline`}
                    <label class="checkbox">
                        <input type="checkbox" autocomplete="off" bind:checked={formRecord.Ldn} />
                        LDN
                    </label>
                {/if}
            {/if}

            <label class="checkbox">
                <input
                    type="checkbox"
                    autocomplete="off"
                    checked={formRecord.ChannelLimit > 0}
                    on:change={(e) => {
                        if (e.currentTarget.checked) formRecord.ChannelLimit = 100
                        else formRecord.ChannelLimit = 0
                    }}
                />
                {i18n.translate("field.channel-limit")}
            </label>
            {#if formRecord.ChannelLimit > 0}
                <input type="number" min="1" bind:value={formRecord.ChannelLimit} required />
            {/if}

            <PhoneNumberRules record={formRecord} target="tech-b" bind:value={formRecord.Rules} label={i18n.translate("dict.rules")} />

            <!-- Footer -->
            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
