<script lang="ts">
    import { getContext } from "svelte"
    import Loader from "../../../lib/Loader.svelte"
    import { Cadence, NullInt64, Tariff, type Client, type Provider } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import Table from "../../../widgets/Table.svelte"
    import TableSorter from "../../../widgets/TableSorter.svelte"
    import CadenceInput from "../../../widgets/inputs/Cadence.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"
    import PhoneNumberRules from "../../elements/telephony/PhoneNumberRules.svelte"

    export var context: "Client" | "Provider", owner: Client | Provider

    const i18n: any = getContext("i18n"),
        modal: any = getContext("modal")

    var formMode: "none" | "edit" | "add" = "none",
        formRecord: Tariff = null,
        calcMethods = {
            truncate: i18n.translate("field.tariff-calc-method.truncate"),
            round: i18n.translate("field.tariff-calc-method.round"),
            floor: i18n.translate("field.tariff-calc-method.floor"),
            ceil: i18n.translate("field.tariff-calc-method.ceil"),
        }
</script>

<Loader endpoint="tariff/list/{context.toLowerCase()}/{owner.Id}" let:data let:reload>
    {#if formMode == "none"}
        <!-- List -->
        <Table>
            <tr slot="head">
                <th>ID <TableSorter /></th>
                <th>{i18n.translate("dict.name")} <TableSorter /></th>
                <th>{i18n.translate("financial.default-price")} <TableSorter /></th>
                <th>{i18n.translate("financial.cadence")} <TableSorter /></th>
                <th>{i18n.translate("field.tariff-calc-method")} <TableSorter /></th>
                <th>{i18n.translate("dict.actions")}</th>
            </tr>

            {#each data as record}
                <tr>
                    <td>{record.Id}</td>
                    <td
                        ><a
                            href="#edit"
                            on:click|preventDefault={() => {
                                formRecord = { ...record }
                                formMode = "edit"
                            }}>{record.Name}</a
                        ></td
                    >
                    <td>{record.Price.toString().replace(".", ",")}</td>
                    <td>{record.Cadence.Discard}/{record.Cadence.Minimum}/{record.Cadence.Increment}</td>
                    <td>{calcMethods[record.CalcMethod]}</td>
                    <td>
                        <a href="#remove" on:click|preventDefault={() => confirm(i18n.translate("question.remove-item", { item: record.Name })) && modal.post("tariff/remove/" + record.Id).then((r) => r.status == 200 && reload())}><i class="fa-solid fa-trash" /></a>
                    </td>
                </tr>
            {/each}
        </Table>

        <PageFooter>
            <expander />
            <button on:click={() => reload()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
            <button
                on:click={() => {
                    formRecord = new Tariff()
                    formRecord[context + "Id"] = new NullInt64({ Int64: owner.Id, Valid: true })
                    formRecord.CustomPrices = []
                    formRecord.Cadence = new Cadence()
                    formMode = "add"
                }}><i class="fa-solid fa-plus" /> {i18n.translate("action.add")} {i18n.translate("financial.tariff")}</button
            >
        </PageFooter>
    {:else}
        <Form
            submit={(form) => {
                var data = new FormData()
                let files = form.querySelectorAll(".rules-file")
                let names = []
                for (let i = 0; i < files.length; i++) {
                    data.append(files[i].name, files[i].files[0])
                    names.push(files[i].name)
                }
                data.append("files", JSON.stringify(names))
                data.append("record", JSON.stringify(formRecord))
                modal.upload(`tariff/save`, data).then((res) => {
                    if (res.status != 200) return
                    formMode = "none"
                    reload()
                })
            }}
        >
            <svelte:fragment slot="title">{i18n.translate("action." + formMode)} {i18n.translate("financial.tariff")}</svelte:fragment>

            {#if formMode == "edit"}
                <label>
                    ID
                    <input disabled value={formRecord.Id} />
                </label>
            {/if}
            <label>
                {i18n.translate("dict.name")}*
                <input autocomplete="off" bind:value={formRecord.Name} required />
            </label>
            <label>
                {i18n.translate("financial.default-price")}*
                <input type="number" bind:value={formRecord.Price} min="0.0" step="0.0000001" required />
            </label>

            <CadenceInput name="{i18n.translate('financial.cadence')}*" bind:value={formRecord.Cadence} />

            <label>
                {i18n.translate("field.tariff-calc-method")}
                <select bind:value={formRecord.CalcMethod}>
                    {#each Object.keys(calcMethods) as m}
                        <option value={m}>{calcMethods[m]}</option>
                    {/each}
                </select>
            </label>

            <PhoneNumberRules record={formRecord} target="tariff" bind:value={formRecord.CustomPrices} label={i18n.translate("financial.custom-prices")} />

            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" class="cancel" on:click={() => (formMode = "none")}><i class="fa-solid fa-pencil" /> {i18n.translate("action.cancel")}</button>
                    <button type="submit" class="primary"><i class="fa-solid fa-floppy-disk" /> {i18n.translate("dict.save")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    {/if}
</Loader>
