<script lang="ts">
    import { afterUpdate, onDestroy, onMount } from "svelte"

    export var margin: string = "10px",
        scroll: boolean = true,
        clickable: Boolean = false
    var theadVisible, theadInvisible, container

    function onTableScroll() {
        if (theadVisible && theadInvisible) {
            const children1 = theadVisible.rows[0].children
            const children2 = theadInvisible.rows[0].children
            for (var i = 0; i < children1.length; i++) {
                const rect = children1[i].getBoundingClientRect()
                if (rect.width < 1) return // não atualiza se estiver colapsada
            }
            for (var i = 0; i < children1.length; i++) {
                children2[i].style.minWidth = "0px"
                children2[i].style.maxWidth = "0px"
            }
            for (var i = 0; i < children1.length; i++) {
                const rect = children1[i].getBoundingClientRect()
                children2[i].style.minWidth = parseFloat(rect.width) - 17 + "px"
                children2[i].style.maxWidth = children2[i].style.minWidth
            }
        }
    }

    onMount(function () {
        container = theadVisible.parentElement.parentElement.parentElement
        container.addEventListener("scroll", onTableScroll)
        container.addEventListener("mouseover", onTableScroll)
        window.addEventListener("resize", onTableScroll)
    })

    afterUpdate(function () {
        onTableScroll()
    })

    onDestroy(function () {
        container.removeEventListener("mouseover", onTableScroll)
        container.removeEventListener("scroll", onTableScroll)
        window.removeEventListener("resize", onTableScroll)
    })
</script>

<column style="{scroll ? '' : 'min-height: auto;'} flex:1; overflow: {scroll ? 'auto hidden' : 'visible'};">
    <column style="{scroll ? '' : 'min-height: auto;'} flex:1; margin: {margin}; border: var(--light-border); background-color: rgb(236, 236, 236); border-radius: 3px; min-width: fit-content;">
        <!-- only header -->
        <table style="background-color: var(--theme-color-c);" class="header">
            <thead bind:this={theadInvisible}>
                <slot name="head" />
            </thead>
        </table>

        {#if scroll}
            <!-- only body -->
            <scroll style="min-width: fit-content; overflow-x: hidden; flex: 1; border-top: var(--light-border);">
                <scroll-content>
                    <table>
                        <thead bind:this={theadVisible} style="visibility: collapse;">
                            <slot name="head" />
                        </thead>
                        <tbody style="background-color: rgb(247, 247, 247);" class:clickable>
                            <slot />
                        </tbody>
                    </table>
                </scroll-content>
            </scroll>
        {:else}
            <table style="border-top: var(--light-border);">
                <thead bind:this={theadVisible} style="visibility: collapse;">
                    <slot name="head" />
                </thead>
                <tbody style="background-color: rgb(247, 247, 247);" class:clickable>
                    <slot />
                </tbody>
            </table>
        {/if}
    </column>
</column>

<style>
    table :global(th),
    table :global(td) {
        text-align: left;
    }

    table :global(th),
    table :global(td) {
        padding: 2px 8px;
        white-space: nowrap;
    }
    table :global(th:not(:last-child)),
    table :global(td:not(:last-child)) {
        border-right: var(--light-border);
    }
    table :global(td) {
        border-bottom: var(--light-border);
    }

    table :global(tr:hover > td) {
        border-bottom-color: var(--theme-color-a);
    }

    .header :global(tr) {
        display: table;
        table-layout: fixed;
    }

    .clickable :global(td) {
        cursor: pointer;
    }

    .clickable :global(tr:hover td) {
        background-color: rgba(0, 0, 0, 0.05);
    }
</style>
