<script lang="ts">
    import { getContext } from "svelte"
    import { createToast } from "../../../lib/Toast"
    import { SearchResult } from "../../../lib/golang-models/cdr"
    import { UserRole } from "../../../lib/golang-models/maindb"
    import Form from "../../../widgets/Form.svelte"
    import NonIdealState from "../../../widgets/NonIdealState.svelte"
    import DateTimeRange from "../../../widgets/inputs/DateTimeRange.svelte"
    import TabContent from "../../../widgets/tab/TabContent.svelte"
    import Tabs from "../../../widgets/tab/Tabs.svelte"
    import PageFooter from "../../elements/PageFooter.svelte"
    import Chart from "./Chart.svelte"
    import Query from "./Query.svelte"
    import Records from "./Records.svelte"
    import Stats from "./Stats.svelte"
    import type { ISearch } from "./interfaces"

    export var search: ISearch,
        result: SearchResult = null,
        status = null,
        view: "request" | "result" = "request"

    const i18n: any = getContext("i18n"),
        login: any = getContext("login"),
        modal: any = getContext("modal")

    function runSearch() {
        result = null
        status = null
        modal.post(`cdr/search`, search.request).then((r) => {
            if (r.data) {
                if (search.request.SearchMode == "records" && !r.data.Finished) createToast("info", i18n.translate("toast.search-continue"))
                r.data["request"] = search.request
            }
            result = r.data
            status = r.status
            if (result.Timeouted) createToast("warning", i18n.translate("toast.search-timeout"))
        })
        view = "result"
    }

    let showFinancial = login.containsAnyUserRoles(UserRole.USER_ROLE_FINANCIAL_SUPPORT)
</script>

<column class="expanded">
    <column style="display:{view == 'request' ? 'flex' : 'none'}; flex:1;">
        <!-- Request -->
        <Form
            expanded
            box={false}
            submit={() => {
                if (new Date(search.request.EndDate).getTime() - new Date(search.request.StartDate).getTime() > 1440 * 60000 * 31) {
                    createToast("error", i18n.translate("field.date-range-over", { days: 31 }))
                    return
                }
                if (new Date(search.request.EndDate).getTime() < new Date(search.request.StartDate).getTime()) {
                    createToast("error", i18n.translate("field.date-range-error"))
                    return
                }
                runSearch()
            }}
        >
            <column class="expanded">
                <column class="form-grid box">
                    <b>Settings</b>
                    <row>
                        <label>
                            Search Name
                            <input type="text" bind:value={search.request.SearchName} on:change={() => search.update()} />
                        </label>
                        <label>
                            Search Mode
                            <select
                                bind:value={search.request.SearchMode}
                                on:change={(e) => {
                                    if (e.currentTarget.value != "chart" && search.request.Queries.length > 1) search.request.Queries = [search.request.Queries[0]]
                                }}
                            >
                                <option value="records">{i18n.translate("field.records-search")}</option>
                                <option value="chart">{i18n.translate("field.chart-search")}</option>
                                <option value="stats">{i18n.translate("field.stats-search")}</option>
                            </select>
                        </label>
                    </row>

                    <row>
                        <label for="">
                            Timeout (seconds)
                            <input type="number" bind:value={search.request.SearchTimeout} min="5" max={login.Type == "user" ? "120" : "15"} />
                        </label>
                        <label>
                            Results Limit (Approximately)
                            <input type="number" bind:value={search.request.SearchLimit} disabled={search.request.SearchMode != "records"} min="1" max="200" />
                        </label>
                    </row>
                    <DateTimeRange bind:mode={search.dateMode} bind:start={search.request.StartDate} bind:end={search.request.EndDate} />
                    {#if search.request.SearchMode == "stats"}
                        <label class="checkbox">
                            <input type="checkbox" bind:checked={search.request.IncludeCcAc} />
                            Include country and area codes analysis
                        </label>
                        <label class="checkbox">
                            <input type="checkbox" bind:checked={search.request.IncludeHangupCause} />
                            Include hangup causes analysis
                        </label>
                        <label class="checkbox">
                            <input type="checkbox" bind:checked={search.request.IncludeCarriers} />
                            Include carriers analysis
                        </label>
                    {/if}
                </column>
                <space />
                {#if search.request.SearchMode == "chart"}
                    {#key search.request.Queries.length}
                        <Tabs
                            bind:selected={search.selectedQueryId}
                            add={search.request.Queries.length < 6
                                ? () => {
                                      search.addQuery()
                                      search = (() => search)()
                                  }
                                : null}
                            close={(id) => {
                                search.removeQuery(id)
                                search = (() => search)()
                            }}
                        >
                            <svelte:fragment slot="tabs">
                                {#each search.request.Queries as query, i (query["id"])}
                                    <a href="#{query['id']}">{query.Name}</a>
                                {/each}
                            </svelte:fragment>

                            {#each search.request.Queries as query (query["id"])}
                                <TabContent name={query["id"]} mode="lazy-keep">
                                    <Query bind:query {search} />
                                </TabContent>
                            {/each}
                        </Tabs>
                    {/key}
                {:else}
                    <Query bind:query={search.request.Queries[0]} {search} />
                {/if}
            </column>
            <svelte:fragment slot="footer">
                <PageFooter>
                    <expander />
                    <button type="button" on:click={() => (result ? (view = "result") : null)}>{i18n.translate("action.view-results")}</button>
                    <button class="primary"><i class="fa-solid fa-search" /> {i18n.translate("action.start-search")}</button>
                </PageFooter>
            </svelte:fragment>
        </Form>
    </column>
    {#if view == "result"}
        {#if status}
            <!-- Results -->
            {#if status == 200}
                {#if result["request"].SearchMode == "records"}
                    <Records {result} />
                {:else if result["request"].SearchMode == "chart"}
                    <Chart {result} />
                {:else if result["request"].SearchMode == "stats"}
                    <Stats stats={result.StatisticalData} {result} {showFinancial} />
                {/if}
            {:else if status}
                <NonIdealState icon="fa-solid fa-triangle-exclamation" color="#e57979">
                    {i18n.translate("loader.error", { status })}
                </NonIdealState>
            {/if}
            <PageFooter>
                <expander />
                <button on:click={() => (view = "request")}>{i18n.translate("action.remake-search")}</button>
                {#if status == 200}
                    <button class="primary" on:click={() => runSearch()}><i class="fa-solid fa-refresh" /> {i18n.translate("action.refresh")}</button>
                {/if}
            </PageFooter>
        {/if}
    {/if}
</column>

<style>
    .form-grid.box {
        padding: 10px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        box-shadow: 3px 4px 5px #00000018;
        background: #f7f7f7;
    }
</style>
