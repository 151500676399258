<script lang="ts">
    import { beforeUpdate, getContext } from "svelte"
    import type { Writable } from "svelte/store"

    export var name: string,
        mode: "pre" | "lazy-keep" | "lazy-no-keep" = "lazy-keep",
        background: string = "white"
    var active = false

    const tab: Writable<string> = getContext("tab")

    beforeUpdate(function () {
        if (!active) active = $tab == name
    })
</script>

{#if mode == "pre" || $tab == name || (mode == "lazy-keep" && active)}
    <column style="flex:1; display:{$tab == name ? 'flex' : 'none'}; background: {background};">
        <slot />
    </column>
{/if}
